import React from 'react'
import { getAuth,  OAuthProvider, signInWithPopup } from "firebase/auth";
import { Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'


function LoginByMicrosoft365Btn() {

    const [isRedirectToHome, setisRedirectToHome] = React.useState(false);

    const onClick_BtnMicrosoftLogin = (e) => {
        e.preventDefault();
        const auth = getAuth();

        const provider = new OAuthProvider('microsoft.com');

        provider.setCustomParameters({
            // Optional "tenant" parameter in case you are using an Azure AD tenant.
            // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
            // or "common" for tenant-independent tokens.
            // The default value is "common".
            tenant: '964bda13-b277-4242-90a2-bb4969d0b192'
        });

        //signInWithRedirect(auth, provider);        


        signInWithPopup(auth, provider)
            .then((result) => {
                // User is signed in.
                // IdP data available in result.additionalUserInfo.profile.                
                // Get the OAuth access token and ID Token
                const credential = OAuthProvider.credentialFromResult(result);
                const token = result._tokenResponse.idToken;
 

                Axios.post(Config.API_URL + "Auth/AddUser",
                {
                    ProviderId: result.providerId,
                    Uid: result.user.uid,
                    Email : result.user.email
                },
                { headers: { 'Authorization': 'Bearer ' + token } })
                .then(response => {
                    setisRedirectToHome(true);
                })
                .catch(error => {
        
                });


            })
            .catch((error) => {
                debugger;
                // Handle error.
            });
            

    }

    if (isRedirectToHome)
        return (<Redirect to={'/'} />)

    return (
        <div className="text-center">
            <button type="button" className="btn btn-lg bg-gradient-info btn-lg w-100 mt-4 mb-0" onClick={onClick_BtnMicrosoftLogin}>
                <i className="fab fa-microsoft fa-fw" /> Sign In with Microsoft 365
            </button>
        </div>
    );
};

export default LoginByMicrosoft365Btn

