import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../../Component/Alert'
import CalibrationTypeLkp from '../../Component/Calibration/CalibrationTypeLkp';
import UserDefaultCustomer from '../../Component/Calibration/UserDefaultCustomer';
import UnitOfMeasurmentLkp from '../../Component/Calibration/UnitOfMeasurmentLkp';
import t from '../../Lang/Index'
import ReportTemplateLkp from '../../Component/Calibration/ReportTemplateLkp';
import CalibrationProcedureLkp from '../../Component/Calibration/CalibrationProcedureLkp';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import CustomerLkp from '../../Component/CustomerLkp';
import EquipmentLkp from '../../Component/EquipmentLkp';
import UserLkp from '../../Component/UserLkp';
import moment from 'moment';
import DueDateOfCalibration from './DueDateOfCalibration';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'

const CertificateOfCalibration_Add = (props) => {

    const PAGE_TITLE = "Calibration Report";
    const ControllerName = "CertificateOfCalibration";
    const [id, setId] = React.useState(props.match.params.id);
    const isEdit = props.match.params.id > 0;

    const [title, setTitle] = React.useState('');
    const [short, setShort] = React.useState('');
    const [isRedirectCertificateOfCalibration, setIsRedirectCertificateOfCalibration] = React.useState(false);
    const [messages, setMessages] = React.useState([]);
    const [customerRef, setCustomerRef] = React.useState(-1);
    const [calibrationUserRef, setCalibrationUserRef] = React.useState(-1);
    const [reviewUserRef, setReviewUserRef] = React.useState(-1);
    const [approvedUserRef, setApprovedUserRef] = React.useState(-1);
    const [description, setDescription] = React.useState('');
    const [dateOfCalibration, setDateOfCalibration] = React.useState('');
    const [dueDateOfCalibration, setDueDateOfCalibration] = React.useState('');
    const [dateOfIssue, setDateOfIssue] = React.useState('');
    const [relativeHumidity, setRelativeHumidity] = React.useState('');
    const [temperature, setTemperature] = React.useState('');
    const [remark, setRemark] = React.useState('');
    const [asFound, setAsFound] = React.useState(false);
    const [rejected, setRejected] = React.useState(false);
    const [zeroAdjustment, setZeroAdjustment] = React.useState(false);
    const [spanAdjustment, setSpanAdjustment] = React.useState(false);
    const [caliRangeMin, setCaliRangeMin] = React.useState('');
    const [caliRangeMax, setCaliRangeMax] = React.useState('');
    const [isManual, setIsManual] = React.useState(false);
    const [vCustomerName, setVCustomerName] = React.useState('');
    const [certificateNumber, setCertificateNumber] = React.useState('');
    const [saveEnabled, setSaveEnabled] = React.useState(true);

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const onCustomerBind = (id) => setCustomerRef(id);

    const onDateOfCalibrationChange = (e) => setDateOfCalibration(e.target.value);
    const onDateOfIssueChange = (e) => setDateOfIssue(e.target.value);


    const onCalibrationUserRefChange = (e) => setCalibrationUserRef(e.target.value);
    const onReviewUserRefChange = (e) => setReviewUserRef(e.target.value);
    const onApprovedUserRefChange = (e) => setApprovedUserRef(e.target.value);
    const onTemperatureChange = (e) => setTemperature(e.target.value);
    const onRelativeHumidityChange = (e) => setRelativeHumidity(e.target.value);
    const onAsFoundChange = (e) => setAsFound(e.target.checked);
    const onRejectedChange = (e) => setRejected(e.target.checked);
    const onZeroAdjustmentChange = (e) => setZeroAdjustment(e.target.checked);
    const onIsManualChange = (e) => setIsManual(e.target.checked);
    const onSpanAdjustmentChange = (e) => setSpanAdjustment(e.target.checked);
    const onRemarkChange = (e) => setRemark(e.target.value);
    const onCaliRangeMinChange = (e) => setCaliRangeMin(e.target.value);
    const onCaliRangeMaxChange = (e) => setCaliRangeMax(e.target.value);

    //Edit Mode
    const onDueDateOfCalibrationHandler = (e) =>  setDueDateOfCalibration(e.target.value);

    //Add Mode
    const onDueDateOfCalibrationChange = (dueDate) => {
        setDueDateOfCalibration(dueDate);
    }

    const cancel = () => setIsRedirectCertificateOfCalibration(true);

    React.useEffect(() => {

        if (isEdit)
            fetchData();

    },[]);

    const fetchData = () => {

        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + ControllerName + "/GetEntityRow/?id=" + id + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    const item = response.data;
                    debugger;
                    setVCustomerName(item.vCustomerName);
                    setCertificateNumber(item.certificateNumber);
                    setDateOfCalibration(item.vDateOfCalibration);
                    setDueDateOfCalibration(item.vDueDateOfCalibration);
                    setCalibrationUserRef(item.calibrationUserRef);
                    setApprovedUserRef(item.approvedUserRef);
                    setReviewUserRef(item.reviewUserRef);
                    setDateOfIssue(item.vDateOfIssue);
                    setTemperature(item.temperature);
                    setRelativeHumidity(item.relativeHumidity);
                    setAsFound(item.asFound);
                    setRejected(item.rejected);
                    setRemark(item.remark);
                    setZeroAdjustment(item.zeroAdjustment);
                    setSpanAdjustment(item.spanAdjustment);
                    setCaliRangeMin(item.caliRangeMin);
                    setCaliRangeMax(item.caliRangeMax);                          
                });
        });
    }

    const addEdit = () => {
        setSaveEnabled(false);

        if (isEdit)
            edit();
        else
            add();
    }

    const add = () => {
        let messagesList = [];
        let self = this;
        debugger;

        getIdToken(user).then((idToken) => {
            debugger;

            Axios.post(Config.API_URL + "CertificateOfCalibration/add",
                {
                    CustomerRef: customerRef,
                    DateOfCalibration: dateOfCalibration == '' ? '1900-01-01' : dateOfCalibration,
                    DueDateOfCalibration: dueDateOfCalibration,
                    EquipmentRef: props.match.params.equipmentRef,
                    CalibrationUserRef: calibrationUserRef,
                    ApprovedUserRef: approvedUserRef,
                    ReviewUserRef: reviewUserRef,
                    DateOfIssue: dateOfIssue,
                    RelativeHumidity: relativeHumidity,
                    Temperature: temperature,
                    Remark: remark,
                    AsFound: asFound,
                    Rejected: rejected,
                    ZeroAdjustment: zeroAdjustment,
                    SpanAdjustment: spanAdjustment,
                    CaliRangeMin: caliRangeMin,
                    CaliRangeMax: caliRangeMax,
                    IsManual: isManual
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let id = response.data;
                    setId(id);
                    setIsRedirectCertificateOfCalibration(true);
                    setSaveEnabled(true);
                })
                .catch(error => {
                    debugger;
                    if (error.response && error.response.status == 400) {
                        for (var key in error.response.data) 
                            if (key.startsWith("message")) 
                                messagesList.push(error.response.data[key]);                                                   
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setMessages(messagesList);
                    setSaveEnabled(true);
                });
        });

    }

    const edit = () => {
        let messagesList = [];

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "CertificateOfCalibration/Edit",
                {
                    Id: id,
                    //CustomerRef: customerRef,
                    DateOfCalibration: dateOfCalibration == '' ? '1900-01-01' : dateOfCalibration,
                    DueDateOfCalibration: dueDateOfCalibration,
                    //EquipmentRef: props.match.params.equipmentRef,
                    CalibrationUserRef: calibrationUserRef,
                    ApprovedUserRef: approvedUserRef,
                    ReviewUserRef: reviewUserRef,
                    DateOfIssue: dateOfIssue,
                    RelativeHumidity: relativeHumidity,
                    Temperature: temperature,
                    Remark: remark,
                    AsFound: asFound,
                    Rejected: rejected,
                    ZeroAdjustment: zeroAdjustment,
                    SpanAdjustment: spanAdjustment,
                    CaliRangeMin: caliRangeMin,
                    CaliRangeMax: caliRangeMax,
                    IsManual: isManual
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {                    
                    setSaveEnabled(true);
                    setIsRedirectCertificateOfCalibration(true);

                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messagesList.push(p);
                        })
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setSaveEnabled(true);

                    setMessages(messagesList);
                });
        });
    }



    if (isRedirectCertificateOfCalibration) {
        return <Redirect to={"/CertificateOfCalibration/"}></Redirect>
    }

    return (

        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-9 col-12 mx-auto position-relative">








                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fa fa-wrench fa-fw" aria-hidden="true"></i>
                            </div>
                            {!isEdit &&
                                <h6 className="mb-0">New {PAGE_TITLE}</h6>
                            }
                            {isEdit &&
                                <>
                                    <h6 className="mb-0">Edit {PAGE_TITLE}</h6>
                                    <p className="mb-0 font-weight-normal text-sm">
                                        {certificateNumber}
                                    </p>
                                </>
                            }

                        </div>


                        <div className="card-body pt-2">


                            <div class="row">
                                <div className="form-group">
                                    <label>
                                        Customer Name
                                    </label>

                                    {!isEdit &&
                                        <UserDefaultCustomer onBind={onCustomerBind} name="CustomerRef" user={user} />
                                    }
                                    {isEdit &&
                                        <p className="form-text text-muted ms-1">
                                            {vCustomerName}
                                        </p>
                                    }
                                </div>
                            </div>


                            <div class="row">
                                <div className="col-3">
                                    <div className="input-group input-group-static  my-3">
                                        <label>
                                            Date Of Calibration
                                        </label>
                                        <input type="date" class="form-control" name="dateOfCalibration" onChange={onDateOfCalibrationChange}
                                            value={dateOfCalibration} />
                                    </div>
                                </div>
                            </div>


                            {isEdit &&
                            <div class="row">
                                <div className="col-3">
                                    <div className="input-group input-group-static  my-3">
                                        <label>
                                            Due Date Of Calibration
                                        </label>
                                        <input type="date" class="form-control" name="dueDateOfCalibration" onChange={onDueDateOfCalibrationHandler}
                                            value={dueDateOfCalibration} />
                                    </div>
                                </div>
                            </div>}
                            {!isEdit &&
                            <DueDateOfCalibration dateOfCalibration={dateOfCalibration}
                                name="dueDateOfCalibration" onDueDateOfCalibrationChange={onDueDateOfCalibrationChange}
                                value={dueDateOfCalibration} />
                            }

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Calibration User
                                    </label>
                                    <UserLkp name="CalibrationUserRef" onChange={onCalibrationUserRefChange} value={calibrationUserRef} user={user} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Review User
                                    </label>
                                    <UserLkp name="reviewUserRef" onChange={onReviewUserRefChange} value={reviewUserRef} user={user} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Approved User
                                    </label>
                                    <UserLkp name="approvedUserRef" onChange={onApprovedUserRefChange} value={approvedUserRef} user={user} />
                                </div>
                            </div>

                            <div className="col-3">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Date Of Issue
                                    </label>
                                    <input type="date" class="form-control" name="dateOfIssue" onChange={onDateOfIssueChange} value={dateOfIssue} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Temperature
                                    </label>
                                    <input type="text" class="form-control" name="Temperature" onChange={onTemperatureChange} value={temperature} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Relative Humidity
                                    </label>
                                    <input type="text" class="form-control" name="relativeHumidity" onChange={onRelativeHumidityChange} value={relativeHumidity} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        <input type="checkbox" name="AsFound" onChange={onAsFoundChange}
                                            value={asFound} checked={asFound}  /> As Found
                                    </label>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        <input type="checkbox" name="Rejected" onChange={onRejectedChange}
                                            value={rejected} checked={rejected} /> Rejected
                                    </label>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        {rejected ? "Reason Of Rejection" : "Remark"}
                                    </label>
                                    <textarea type="text" class="form-control" name="remark" onChange={onRemarkChange}
                                        value={remark} style={{"height" : "150px"}} />
                                </div>
                            </div>


                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        <input type="checkbox" name="ZeroAdjustment" onChange={onZeroAdjustmentChange}
                                            value={zeroAdjustment} checked={zeroAdjustment} /> Zero Adjustment
                                    </label>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        <input type="checkbox" name="SpanAdjustment" onChange={onSpanAdjustmentChange}
                                            value={spanAdjustment} checked={spanAdjustment} /> Span Adjustment
                                    </label>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Cali. Range Min
                                    </label>
                                    <input type="number" class="form-control" name="CaliRangeMin" onChange={onCaliRangeMinChange} value={caliRangeMin} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Cali. Range Max
                                    </label>
                                    <input type="number" class="form-control" name="CaliRangeMax" onChange={onCaliRangeMaxChange} value={caliRangeMax} />
                                </div>
                            </div>

                            {!isEdit &&
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        <input type="checkbox" name="IsManual" onChange={onIsManualChange}
                                            value={isManual}/> Manual Report
                                    </label>
                                </div>
                            </div>
                            }


                            {messages.length > 0 &&
                                <Alert messages={messages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={cancel}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={addEdit} disabled={!saveEnabled} >
                                    Save
                                </button>

                                {/* <button type="button" class="btn btn-primary m-0 ms-2" onClick={addAndGoToNewReport}>
                                    Save & Go to Results
                                </button> */}                               

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >
    )
}

export default CertificateOfCalibration_Add;