import * as React from "react";
import * as ReactDOM from "react-dom";
import Config from '../../../Constants/index';
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";
import { Link, Redirect } from 'react-router-dom'
import EquipmentDeltail from "../Components/EquipmentDetail";

import EN0096_DC_Voltage from "./EN0096/EN0096_DC_Voltage"
import F0301T_AC_Voltage from "./EN0096/EN0096_AC_Voltage_Current"
import EN0096_Resistance from "./EN0096/EN0096_Resistance"
import F0301T_DC_Current from "./EN0096/F0301T_DC_Current"
import F0301T_AC_Current from "./EN0096/EN0096_AC_Voltage_Current"
import F0301T_Frequency from "./EN0096/F0301T_Frequency"
import EN0096_Capacitance from "./EN0096/EN0096_Capacitance"
import EN0096_Temperature from "./EN0096/EN0096_Temperature"



import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import NavBar from '../../../Navigation/NavBar';
import Footer from '../../../Navigation/Footer';

const editField = "inEdit";

const CalibrationResultEN0096 = (props) => {

    const [certificateOfCalibrationRef, setCertificateOfCalibrationRef] = React.useState(props.match.params.entityRef);
    const [equipment, setEquipment] = React.useState('');
    const [unitOfMeasurment, setUnitOfMeasurment] = React.useState('');

    const auth = getAuth();
    const [user, loadingAuth, error] = useAuthState(auth);

    React.useEffect(() => {
        fetchCalibrationReport();
    }, []);

    const fetchCalibrationReport = () => {
        getIdToken(user).then((idToken) => {
            fetch(Config.API_URL + "CertificateOfCalibration/GetCertificateOfCalibration/?id=" + certificateOfCalibrationRef
                + "&timestamp=" + new Date().getTime(),
                { headers: { token: window.localStorage.getItem("apiToken") } })
                .then(response => response.json())
                .then(json => {
                    //setCalibrationReport(json)
                    fetchEquipment(json.equipmentRef)
                });
        });

    }

    const fetchEquipment = (equipmentId) => {
        getIdToken(user).then((idToken) => {
            fetch(Config.API_URL + "equipment/GetEquipment/?id=" + equipmentId
                + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    setEquipment(json)
                    fetchUnitOfMeasurment(json.unitMeasurmentRef)
                });
        });

    }

    const fetchUnitOfMeasurment = (unitOfMeasurmentId) => {
        getIdToken(user).then((idToken) => {
            fetch(Config.API_URL + "UnitOfMeasurment/GetUnitOfMeasurment/?id=" + unitOfMeasurmentId
                + "&timestamp=" + new Date().getTime(),
                { headers: { token: window.localStorage.getItem("apiToken") } })
                .then(response => response.json())
                .then(json => {
                    setUnitOfMeasurment(json)
                });
        });
    }

    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">            
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-12">
                    <div className="card mb-4">
                        <div class="card-header p-3 pt-2">
                            <div class="icon icon-lg icon-shape bg-gradient-info shadow text-center border-radius-xl mt-n4 float-start">
                                <i class="fas fa-flask" aria-hidden="true"></i>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 class="mb-0">Result of Certificate no: {certificateOfCalibrationRef}</h6>
                                </div>

                            </div>
                        </div>


                        <div className="card-body px-0 pt-0 pb-2">

                            <div>
              
                                <div className="row">
                                    <EquipmentDeltail equipment={equipment} unitOfMeasurment={unitOfMeasurment} />
                                </div>

                                <div>
                                    <EN0096_DC_Voltage
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT DC MILIVOLTAGE"
                                        tblTemplateId={9601}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={134}
                                    />

                                    <EN0096_DC_Voltage
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT DC VOLTAGE"
                                        tblTemplateId={9602}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={133}
                                    />

                                    <F0301T_AC_Voltage
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT AC MILIVOLTAGE"
                                        tblTemplateId={9603}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={1134}
                                    />

                                    <F0301T_AC_Voltage
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT AC VOLTAGE"
                                        tblTemplateId={9604}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={1133}
                                    />

                                    <EN0096_Resistance
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT RESISTANCE LOW RANGE"
                                        tblTemplateId={9605}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={144}
                                    />

                                    <EN0096_Resistance
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT RESISTANCE MEDIUM RANGE"
                                        tblTemplateId={9606}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={143}
                                    />
                                    <EN0096_Resistance
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT RESISTANCE HIGH RANGE"
                                        tblTemplateId={9607}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={142}
                                    />
                                    <EN0096_Resistance
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT RESISTANCE ULTRA HIGH RANGE"
                                        tblTemplateId={9608}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={141}
                                    />

                                    <F0301T_DC_Current
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT DC CURRENT LOW RANGE"
                                        tblTemplateId={9609}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={138}
                                    />
                                    <F0301T_DC_Current
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT DC CURRENT MEDIUM RANGE"
                                        tblTemplateId={9610}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={137}
                                    />
                                    <F0301T_DC_Current
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT DC CURRENT HIGH RANGE"
                                        tblTemplateId={9611}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={136}
                                    />


                                    <F0301T_AC_Current
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT AC CURRENT LOW RANGE"
                                        tblTemplateId={9612}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={1138}
                                    />
                                    <F0301T_AC_Current
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT AC CURRENT MEDIUM RANGE"
                                        tblTemplateId={9613}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={1137}
                                    />
                                    <F0301T_AC_Current
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT AC CURRENT HIGH RANGE"
                                        tblTemplateId={9614}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={1136}
                                    /> 
                                    <F0301T_Frequency
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT FREQUENCY"
                                        tblTemplateId={9615}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={164}
                                    /> 

                                    <EN0096_Capacitance
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT CAPACITANCE LOW RANGE"
                                        tblTemplateId={9616}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={163}
                                    /> 

                                    <EN0096_Capacitance
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT CAPACITANCE MEDIUM RANGE"
                                        tblTemplateId={9617}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={162}
                                    /> 

                                    <EN0096_Capacitance
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT CAPACITANCE HIGH RANGE"
                                        tblTemplateId={9618}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={161}
                                    /> 

                                    {/* TODO 9619   */}
                                    <EN0096_Temperature
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT TEMPERATURE °C"
                                        tblTemplateId={9620}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={72}
                                    /> 

                                    <EN0096_Temperature
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT TEMPERATURE °F"
                                        tblTemplateId={9621}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={74}
                                    /> 
{/* 
Extra Units
*/}
                                </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
        </main >
    );
};

export default CalibrationResultEN0096;





