import React from 'react'
import { Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../../Component/Alert'
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import TextInput from '../../Component/TextInput';
import DateInput from '../../Component/DateInput';

const MasterEquipment_Add = (props) => {

    const PAGE_TITLE = "New Master Equipment";
    const [masterEquipment, setMasterEquipment] = React.useState({
        description: '',
        model: '',
        manufacture: '',
        serialNo: '',
        calDueDate: '',
        certificateNo: '',
    });

    const [messages, setMessages] = React.useState([]);
    const [isRedirectToEquipment, setIsRedirectToEquipment] = React.useState(false)
    const [saveEnabled, setSaveEnabled] = React.useState(true);

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setMasterEquipment(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const cancel = () => {
        setIsRedirectToEquipment(true);
    }

    if (isRedirectToEquipment) {
        return <Redirect to={"/MasterEquipment/"} />
    }

    const addEdit = () => {
        add();
    }

    const add = () => {
        let messagesList = [];
    
        getIdToken(user).then((idToken) => {
    
            Axios.post(Config.API_URL + "MasterEquipment/add",
                {
                    Description: masterEquipment.description,
                    Model: masterEquipment.model,
                    Manufacture: masterEquipment.manufacture,
                    SerialNo: masterEquipment.serialNo,
                    CalDueDate: masterEquipment.calDueDate,
                    CertificateNo: masterEquipment.certificateNo
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    //let _id = response.data;
                    //setId(_id)
                    setIsRedirectToEquipment(true);
                })
                .catch(error => {
                    debugger;
                    if (error.response && error.response.status === 400) {
                        for (var key in error.response.data) {
                            if (key.startsWith("message")) {
                                messagesList.push(error.response.data[key]);                                                   
                            }
                        }
                    } else {
                        messagesList.push("Failed to connect to server")
                    }
                    setMessages(messagesList);
                });
        });
    }
    

    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-6 col-6 mx-auto position-relative">
                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i className="fa fa fa-wrench fa-fw" aria-hidden="true"></i>
                            </div>
                            <h6 className="mb-0">{PAGE_TITLE}</h6>
                        </div>


                        <div className="card-body pt-2">

                            <TextInput label="Description" name="description" onChange={handleChange} value={masterEquipment.description} />
                            <TextInput label="Model" name="model" onChange={handleChange} value={masterEquipment.model} />
                            <TextInput label="Manufacture" name="manufacture" onChange={handleChange} value={masterEquipment.manufacture} />
                            <TextInput label="SerialNo" name="serialNo" onChange={handleChange} value={masterEquipment.serialNo} />
                            <DateInput label="CalDueDate" name="calDueDate" onChange={handleChange} value={masterEquipment.calDueDate} />
                            <TextInput label="CertificateNo" name="certificateNo" onChange={handleChange} value={masterEquipment.certificateNo} />

                        

                        {messages.length > 0 &&
                            <Alert messages={messages}></Alert>}
                        <div className="d-flex justify-content-end mt-4">
                            <button type="button" className="btn btn-light m-0" onClick={cancel}>
                                Cancel
                            </button>
                            <button type="button" className="btn bg-gradient-dark m-0 ms-2" onClick={addEdit} disabled={!saveEnabled} >
                                Save
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </main>
    )
};

export default MasterEquipment_Add;
