import React, { Component, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../../Component/Alert'
import UserDefaultCustomer from '../../Component/Calibration/UserDefaultCustomer';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import UserLkp from '../../Component/UserLkp';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'

const RtCert_Add = (props) => {

    const PAGE_TITLE = "Rt Cert Report";
    const ControllerName = "RtCert";
    const [id, setId] = React.useState(props.match.params.id);
    const isEdit = props.match.params.id > 0;

    const [isRedirectCertificateOfCalibration, setIsRedirectCertificateOfCalibration] = React.useState(false);
    const [messages, setMessages] = React.useState([]);
    const [customerRef, setCustomerRef] = React.useState(-1);
    const [performedUserRef, setPerformedUserRef] = React.useState(-1);
    const [dateOfTest, setDateOfTest] = React.useState('');
    const [dateOfIssue, setDateOfIssue] = React.useState('');
    const [vCustomerName, setVCustomerName] = React.useState('');
    const [certificateNumber, setCertificateNumber] = React.useState('');
    const [saveEnabled, setSaveEnabled] = React.useState(true);
    const [isGoingToAddResultPageDirectly, setIsGoingToAddResultPageDirectly] = React.useState(false);
    const [certOfCalRef, setCertOfCalRef] = React.useState(-1);

    const [rtRequest, setRtRequest] = React.useState('');
    const [wps, setWps] = React.useState('');

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const onCustomerBind = (id) => setCustomerRef(id);
    const onDateOfTestChange = (e) => setDateOfTest(e.target.value);
    const onDateOfIssueChange = (e) => setDateOfIssue(e.target.value);
    const onPerformedUserRefChange = (e) => setPerformedUserRef(e.target.value);
    const cancel = () => setIsRedirectCertificateOfCalibration(true);

    React.useEffect(() => {

        if (isEdit)
            fetchData();

    }, []);

    const fetchData = () => {
        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + ControllerName + "/GetEntityRow/?id=" + id + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    const item = response.data;
                    debugger;
                    setVCustomerName(item.vCustomerName);
                    setCertificateNumber(item.certificateNumber);
                    setDateOfTest(item.vDateOfCalibration);
                    setPerformedUserRef(item.performedUserRef);
                    setDateOfIssue(item.vDateOfIssue);
                    setRtRequest(item.rtRequest);
                });
        });
    }

    const addEdit = () => {
        setSaveEnabled(false);

        if (isEdit)
            edit();
        else
            add();
    }

    const addAndGoToResultPage = () => {
        setIsGoingToAddResultPageDirectly(true);
        setSaveEnabled(false);
        if (isEdit)
            edit();
        else
            add();
    }


    const add = () => {
        let messagesList = [];
        let self = this;

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "RtCert/add",
                {
                    CustomerRef: customerRef,
                    DateOfTest: dateOfTest == '' ? '1900-01-01' : dateOfTest,
                    PerformedUserRef: performedUserRef,
                    DateOfIssue: dateOfIssue == '' ? '1900-01-01' : dateOfIssue,
                    RtRequest: rtRequest,
                    Wps: wps,
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let id = response.data.id;
                    setId(id);

                    setCertOfCalRef(response.data.certOfCalRef);

                    setIsRedirectCertificateOfCalibration(true);
                    setSaveEnabled(true);
                })
                .catch(error => {
                    debugger;
                    if (error.response && error.response.status == 400) {
                        for (var key in error.response.data)
                            if (key.startsWith("message"))
                                messagesList.push(error.response.data[key]);
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setMessages(messagesList);
                    setSaveEnabled(true);

                });
        });

    }

    const edit = () => {
        let messagesList = [];

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "RtCert/Edit",
                {
                    Id: id,
                    CustomerRef: customerRef,
                    DateOfTest: dateOfTest == '' ? '1900-01-01' : dateOfTest,
                    EquipmentRef: props.match.params.equipmentRef,
                    PerformedUserRef: performedUserRef,
                    DateOfIssue: dateOfIssue,
                    RtRequest: rtRequest,
                    Wps: wps,
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    setSaveEnabled(true);
                    setIsRedirectCertificateOfCalibration(true);

                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messagesList.push(p);
                        })
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setSaveEnabled(true);

                    setMessages(messagesList);
                });
        });
    }


    if (isRedirectCertificateOfCalibration && isGoingToAddResultPageDirectly)
        return <Redirect to={"/RtCertResult/" + certOfCalRef}></Redirect>

    if (isRedirectCertificateOfCalibration && !isGoingToAddResultPageDirectly)
        return <Redirect to={"/RtCert/"}></Redirect>

    return (

        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-9 col-12 mx-auto position-relative">
                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fa fa-wrench fa-fw" aria-hidden="true"></i>
                            </div>
                            {!isEdit &&
                                <h6 className="mb-0">New {PAGE_TITLE} </h6>
                            }
                            {isEdit &&
                                <>
                                    <h6 className="mb-0">Edit {PAGE_TITLE}</h6>
                                    <p className="mb-0 font-weight-normal text-sm">
                                        {certificateNumber}
                                    </p>
                                </>
                            }
                        </div>
                        <div className="card-body pt-2">
                            <div class="row">
                                <div className="form-group">
                                    <label>
                                        Customer Name
                                    </label>
                                    {!isEdit &&
                                        <UserDefaultCustomer onBind={onCustomerBind} name="CustomerRef" user={user} />
                                    }
                                    {isEdit &&
                                        <p className="form-text text-muted ms-1">
                                            {vCustomerName}
                                        </p>
                                    }
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-3">
                                    <div className="input-group input-group-static  my-3">
                                        <label>
                                            Date Of Test
                                        </label>
                                        <input type="date" class="form-control" name="dateOfTest" onChange={onDateOfTestChange}
                                            value={dateOfTest} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-3">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Date Of Issue
                                    </label>
                                    <input type="date" class="form-control" name="dateOfIssue" onChange={onDateOfIssueChange} value={dateOfIssue} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Performed User
                                    </label>
                                    <UserLkp name="PerformedUserRef" onChange={onPerformedUserRefChange} value={performedUserRef} user={user} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Rt Request
                                    </label>
                                    <input type="text" class="form-control" name="rtRequest" onChange={(e) => setRtRequest(e.target.value)} value={rtRequest} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Wps
                                    </label>
                                    <input type="text" class="form-control" name="wps" onChange={(e) => setWps(e.target.value)} value={wps} />
                                </div>
                            </div>

                            {messages.length > 0 &&
                                <Alert messages={messages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={cancel}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={addEdit} disabled={!saveEnabled} >
                                    Save
                                </button>

                                {/* {!isEdit &&
                                    <button type="button" class="btn btn-primary m-0 ms-2" onClick={addAndGoToResultPage}>
                                        Save & Add Results
                                    </button>
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >
    )
}

export default RtCert_Add;