import React, { Component } from 'react'
import isEmail from 'validator/lib/isEmail'
import axios from 'axios'
import { Link, Redirect } from 'react-router-dom'
import Config from '../Constants/index'
import t from '../Lang/Index'
import SignUpForm from '../Pages/SignUp/SignUpForm'

class SignUp extends Component {
    constructor() {
        super();
        document.title = t.create_an_account + " - " + Config.SITE_TITLE;
        this.state = {};
    }

    render() {
        return (

            // <div className="container">
            //     <div className="row mt-lg-n12 mt-md-n12 mt-n11 justify-content-center">
            //         <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
            //             <div className="card mt-8">
            //                 <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            //                     <div className="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1 text-center py-4">
            //                         <h4 className="font-weight-bolder text-white mt-1">Join us today</h4>
            //                         <p className="mb-1 text-white text-sm">Enter your email and password to register</p>
            //                     </div>
            //                 </div>
            //                 <div className="card-body pb-3">
            //                     <SignUpForm />

            //                 </div>
            //                 <div className="card-footer text-center pt-0 px-sm-4 px-1">
            //                     <p className="mb-4 mx-auto">
            //                         Already have an account?
            //                         <a href="../../../pages/sign-in/sign-in-cover.html" className="text-primary text-gradient font-weight-bold">Sign in</a>
            //                     </p>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>



<div>
  <div className="container position-sticky z-index-sticky top-0">
    <div className="row">
      <div className="col-12">
        {/* Navbar */}

        {/* End Navbar */}
      </div>
    </div>
  </div>
  <main className="main-content main-content-bg mt-0">
    <div className="page-header align-items-start min-height-300 m-3 border-radius-xl bg-gray-200" 
    style={{backgroundImage: 'url("../assets/img/illustrations/bg2.jpg")', backgroundSize: 'cover', backgroundPosition: 'center'}}>
      <span className="mask bg-gradient-dark opacity-4" />
    </div>
    <div className="container">
      <div className="row mt-lg-n12 mt-md-n12 mt-n11 justify-content-center">
        <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div className="card mt-8">
            <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div className="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1 text-center py-4">
                <h4 className="font-weight-bolder text-white mt-1">Join us today</h4>
                <p className="mb-1 text-white text-sm">Enter your email and password to register</p>
              </div>
            </div>
            <div className="card-body pb-3">
             <SignUpForm></SignUpForm>
            </div>
            <div className="card-footer text-center pt-0 px-sm-4 px-1">
              <p className="mb-4 mx-auto">
                {/* Already have an account?
                <a href="../../../pages/sign-in/sign-in-cover.html" className="text-primary text-gradient font-weight-bold">Sign in</a> */}

                Already have an account?
                <Link className="text-primary text-gradient font-weight-bold" to="/Login"> Sign In</Link>

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  

</div>




            // <div className="container">
            //     {/* Outer Row */}
            //     <div className="row justify-content-center">
            //         <div className="col-xl-6 col-lg-12 col-md-9">
            //             <div className="card o-hidden border-0 shadow-lg my-5">
            //                 <div className="card-body p-0">
            //                     {/* Nested Row within Card Body */}
            //                     <div className="row">
            //                         <div className="col-lg-12">
            //                             <div className="p-5">
            //                                 <div className="text-center">
            //                                     <h1 className="h4 text-gray-900 mb-4">{Config.SITE_TITLE}</h1>
            //                                     <h1 className="h4 text-gray-900 mb-4">{t.create_an_account}</h1>
            //                                 </div>                                            
            //                                 <hr />
            //                                 <div className="text-center">
            //                                 <Link className="small" to="/ForgotPassword">{t.forgot_password}</Link>
            //                                 </div>
            //                                 <div className="text-center">

            //                                 <Link className="small" to="/Login">{t.already_have_an_account}</Link>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>


            // <div className="container">
            //     <div className="row">
            //         <div className="col-md-4 col-md-offset-4">
            //             <h3 className="form-signin-heading">{Config.SITE_TITLE}</h3>
            //             <h4 className="form-signin-heading">{Config.APP_TITLE}</h4>
            //             <div className="login-panel panel panel-default">
            //                 <div className="panel-heading">
            //                     <h3 className="panel-title">Welcome Back!</h3>
            //                 </div>
            //                 <div className="panel-body">
            //                     <LoginForm></LoginForm>                            
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>



        )
    }
}

export default SignUp