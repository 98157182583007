import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import cellWithEditing from './CellWithEditing'
import reportButtons from './MatchOrderAdmin/ReportButtons'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import Config from '../Constants/index';
import axios from 'axios'
import { Redirect, Link } from 'react-router-dom'
import CellDeleteButton from '../Component/CellDeleteButton'


export default class MatchOrderAdmin extends Component {

    constructor() {
        super();

        this.state = {
            data: [],
            matchOrderInChangeStatus: undefined,
            selectedStatus: 1,
            isRedirectToMatchMemberAdmin: false,
            deleteDialog: false
        };

        this.changeStatus = this.changeStatus.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.delete = this.delete.bind(this);
        this.save = this.save.bind(this);
        this.toggleDialog = this.toggleDialog.bind(this);
        this.fetchData = this.fetchData.bind(this)
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.select = this.select.bind(this);
        this.selectReport = this.select.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    handleStatusChange(changeEvent) {
        var selectedStatus = changeEvent.target.value;
        this.setState({ selectedStatus })
    }

    fetchData() {
        let matchId = this.props.match.params.matchId;
        this.setState({ loading: true });

        axios.get(Config.API_URL + "matchorder/getmatchOrderList/?matchId=" + matchId + "&timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                this.setState({ data: response.data, loading: false });
            });
    }

    componentWillMount() {
        this.fetchData();
    }

    // state = {
    //     gridData: products
    // }

    report(dataItem) {
        //this.setState({ matchOrderInChangeStatus: this.cloneProduct(dataItem) });
        debugger;
        console.log(dataItem)
    }

    changeStatus() {

        const selected = this.state.data.filter(item => item.selected);

        if (selected.length == 0) {
            this.setState({ visible: true });
            return;
        }

        this.setState({ matchOrderInChangeStatus: true });
    }

    deleteConfirm(dataItem) {
        this.setState({
            deleteTitle: " کد " + dataItem.id + " - " + dataItem.teamTitle,
            deleteDialog: true,
            selectedRow: dataItem
        });
    }

    cancelDelete() {
        this.setState({ deleteDialog: false });
    }

    delete() {
        //TODO get Id
        this.setState({ loading: true });

        axios.post(Config.API_URL + "MatchOrder/Delete",
            {
                Id: this.state.selectedRow.id
            },

            { headers: { token: window.localStorage.getItem("apiToken") } }
        )
            .then(response => {

                if (response.status == 200) {
                    this.setState({ loading: false, deleteDialog: false });
                    this.fetchData();
                }

            }).catch(response => {
                debugger;
                if (response.status == 403)
                {

                }

            });
        //this.setState({ matchOrderInChangeStatus: false });

    }

    cancel() {
        this.setState({ matchOrderInChangeStatus: false });

    }

    save() {
        var selectedComman = this.state.data.filter(item => item.selected).map(p => p.id).join();
        var selectedStatus = this.state.selectedStatus;
        this.setState({ loading: true });

        axios.post(Config.API_URL + "MatchOrder/ChangeStatus",

            {
                SelectedIds: selectedComman,
                TargetStatus: selectedStatus
            },

            { headers: { token: window.localStorage.getItem("apiToken") } }
        )
            .then(response => {

                if (response.status == 200) {
                    this.setState({ loading: false, matchOrderInChangeStatus: false });
                    this.fetchData();
                }

            }).catch(response => {
                debugger;
                //if (response.status == 403)

            });
        //this.setState({ matchOrderInChangeStatus: false });

    }

    select(dataItem) {
        this.setState({
            matchOrderId: dataItem.id,
            isRedirectToMatchMemberAdmin: true
        });
    }

    selectReport(dataItem) {

        window.open("/identity.html?id=" + dataItem.Id, "_blank");

        //http://localhost:3000/identity.html?id=32

        //console.log("test");
        //this.context.router.history.push(`/target`)

        //return <Redirect to='/' />
    }


    selectionChange = (event) => {
        event.dataItem.selected = !event.dataItem.selected;
        this.forceUpdate();
    }

    rowClick = (event) => {
        let last = this.lastSelectedIndex;
        const current = this.state.data.findIndex(dataItem => dataItem === event.dataItem);

        if (!event.nativeEvent.shiftKey) {
            this.lastSelectedIndex = last = current;
        }

        if (!event.nativeEvent.ctrlKey) {
            this.state.data.forEach(item => item.selected = false);
        }
        const select = !event.dataItem.selected;
        for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
            this.state.data[i].selected = select;
        }
        this.forceUpdate();
    }

    headerSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        this.state.data.forEach(item => item.selected = checked);
        this.forceUpdate();
    }


    render() {

        if (this.state.isRedirectToMatchMemberAdmin) {
            return (
                <Redirect to={"/MatchMemberAdmin/" + this.state.matchOrderId} />
            )
        }
        return (

            <div>

                <div id="page-wrapper">
                    <div className="row">
                    <div className="col-lg-12">

                        <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">صفحه اصلی</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">لیست درخواست های شرکت در مسابقه</li>
                                </ol>
                            </nav>
                        {/* /.col-lg-12 */}

                        <Grid style={{ height: '400px' }} data={this.state.data}


                            selectedField="selected"
                            onSelectionChange={this.selectionChange}
                            onHeaderSelectionChange={this.headerSelectionChange}
                            onRowClick={this.rowClick}

                        >

                            <GridToolbar>
                                <Button style={{ 'margin-left': '5px', float:'right' }}
                                    onClick={this.changeStatus} >تغییر وضعیت</Button>


                                {/* <button
                                    className="k-button k-button1"
                                >
                                    گزارش تیم
                    </button>
                                <button
                                    className="k-button"
                                >
                                    گزارش مدارک
                    </button>

                                <button
                                    className="k-button"
                                >
                                    کارت مسابقه
                    </button> */}
                            </GridToolbar>



                            <Column
                                field="selected"
                                width="50px"
                                headerSelectionValue={
                                    this.state.data.length > 0
                                    &&
                                    this.state.data.findIndex(dataItem => dataItem.selected === false) === -1
                                } />
                            <Column cell={cellWithEditing(this.select)} width="100px" />
                            <Column cell={CellDeleteButton(this.deleteConfirm)} width="80px" />
                            <Column field="id" title="کد شرکت در مسابقه" width="150px" />
                            <Column field="teamTitle" title="عنوان تیم" width="250px" />
                            <Column field="matchAgeTypeTitle" title="رده ی سنی" width="120px" />
                            <Column field="matchLevelTypeTitle" title="دسته" width="120px" />
                            <Column field="matchOrderStatusTitle" title="وضعیت" width="260px" />
                            <Column
                                cell={reportButtons(this.selectReport)}
                                width="320px"
                            />

                        </Grid>
                        {this.state.deleteDialog &&
                            <Dialog
                                title="حذف"
                                onClose={this.cancelDelete}
                            >
                                <div>
                                    آیا برای حذف {this.state.deleteTitle} مطمئن هستید؟
                                </div>

                                <DialogActionsBar>
                                    <button
                                        className="k-button k-primary"
                                        onClick={this.delete}
                                    >
                                        تایید
                                </button>
                                    <button
                                        className="k-button"
                                        onClick={this.cancelDelete}
                                    >
                                        انصراف
                        </button>
                                </DialogActionsBar>
                            </Dialog>}

                        {
                            this.state.visible &&
                            <Dialog title={"توجه"} onClose={this.toggleDialog} >
                                <p style={{ margin: "25px", textAlign: "center" }}>انتخاب حداقل یک آیتم الزامی است</p>
                                <DialogActionsBar>
                                    <button className="k-button" onClick={this.toggleDialog}>بستن</button>
                                </DialogActionsBar>
                            </Dialog>}
                        {
                            this.state.loading && <Dialog>
                                <i class='fa fa-spinner fa-spin '></i> Loading...
                                </Dialog>
                        }


                        {this.state.matchOrderInChangeStatus &&
                            <Dialog
                                title="تغییر وضعیت"
                                onClose={this.cancel}
                            >
                                <div>
                                    <div className="radio">
                                        <label><input type="radio" name="statusRadio" value="1" defaultChecked
                                            onChange={this.handleStatusChange}
                                        />در انتظار پرداخت</label>
                                    </div>
                                    <div className="radio">
                                        <label><input type="radio" name="statusRadio" value="2" onChange={this.handleStatusChange} />اتمام مهلت پرداخت</label>
                                    </div>
                                    <div className="radio">
                                        <label><input type="radio" name="statusRadio" value="3" onChange={this.handleStatusChange} />پرداخت شده / درانتظار تکمیل ثبت نام</label>
                                    </div>
                                    <div className="radio">
                                        <label><input type="radio" name="statusRadio" value="4" onChange={this.handleStatusChange} />تکمیل ثبت نام</label>
                                    </div>
                                </div>

                                <DialogActionsBar>
                                    <button
                                        className="k-button k-primary"
                                        onClick={this.save}
                                    >
                                        تایید
                        </button>
                                    <button
                                        className="k-button"
                                        onClick={this.cancel}
                                    >
                                        انصراف
                        </button>
                                </DialogActionsBar>
                            </Dialog>}
</div>
                    </div>
                </div>
            </div>
        )
    }

    toggleDialog() {
        this.setState({
            visible: !this.state.visible
        });
    }

    cloneProduct(product) {
        return Object.assign({}, product);
    }

    newProduct(source) {
        const newProduct = {
            ProductID: this.generateId(),
            ProductName: '',
            UnitsInStock: 0,
            Discontinued: false
        };

        return Object.assign(newProduct, source);
    }

    generateId() {
        let id = 1;
        this.state.products.forEach(p => { id = Math.max((p.ProductID || 0) + 1, id); });
        return id;
    }



}
