import React from 'react'
import Select from 'react-select';

const PsvBodyMaterialLkp = (props) => {

  const options = [
    { value: 'Brass', label: 'b: Brass' },  
    { value: 'Stainless Steel', label: 'ss: Stainless Steel' },
    { value: 'WCB', label: 'WCB' },
    { value: 'WCC', label: 'WCC' },
    { value: 'LCC', label: 'LCC' },
    { value: 'Carbon Steel', label: 'c: Carbon Steel' },
    { value: 'CF8M', label: 'cf8: CF8M' },
    { value: 'CF3M', label: 'cf3: CF3M' }
  ]    
  const filterOptions = ({ label }, input) => label.startsWith(input);

  return (
    <Select options={options} 
      className="form-control" 
      onChange={props.onChange} 
      filterOption={filterOptions}
      value= {props.selectedValue}
      />
  )
}

export default PsvBodyMaterialLkp