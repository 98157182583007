import * as React from 'react'
import Config from '../../../Constants'

const EquipmentDeltail = (props) => {

    const equipment = props.equipment;
    const unitOfMeasurment = props.unitOfMeasurment;

    return (
        
            <div className="col-xl-6 col-md-6 mb-4">
                <div className="card border-left-info shadow h-100 py-2">
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                                    <i className="fas fa-wrench fa-2x text-gray-300" />UUC-EUC-DUC Description:{equipment.description}
                                </div>
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="mb-0 font-weight-bold text-gray-800">
                                            Manufacture:{equipment.manufacture}
                                        </div>
                                        <div className="mb-0 font-weight-bold text-gray-800">
                                            Model/Type:{equipment.model_Type}
                                        </div>                                        
                                        <div className="mb-0 font-weight-bold text-gray-800">
                                            Location:{equipment.location}
                                        </div>
                                    </div>
                                    <div className="col mr-2">
                                    <div className="mb-0 font-weight-bold text-gray-800">
                                            TagNo:{equipment.tagNo}
                                        </div>
                                        <div className="mb-0 font-weight-bold text-gray-800">
                                            SerialNo:{equipment.serialNo}
                                        </div>
                                        <div className="mb-0 font-weight-bold text-gray-800">
                                            Range {equipment.rangeMin} - {equipment.rangeMax} {unitOfMeasurment.title}
                                        </div>                                        
                                    </div>
                                    <div className="col mr-2">                                                                                
                                        <div className="mb-0 font-weight-bold text-gray-800">
                                            Resolution {equipment.resolution}
                                        </div>
                                        <div className="mb-0 font-weight-bold text-gray-800">
                                            EsResolution {equipment.esResolution}
                                        </div>
                                        <div className="mb-0 font-weight-bold text-gray-800">
                                        Tolerance {(equipment.accurancy *  (Math.abs(equipment.rangeMin) + Math.abs(equipment.rangeMax))) /100 } {unitOfMeasurment.title}
                                        </div>
                                        <div className="mb-0 font-weight-bold text-gray-800">
                                            Accurancy {equipment.accurancy}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img src={Config.API_URL + "equipment/getthumb1/?id=" + equipment.id}></img>
                        </div>
                    </div>
                </div>
                
            </div>
     
    )
}

export default EquipmentDeltail