import React from 'react'
import Select from 'react-select';

const PsvManufacurerLkp = (props) => {

  const options = [
    { value: 'Flange Type', label: 'ft: Flange Type' },  
    { value: 'Thread Type', label: 'tt: Thread Type' }

  ]    
  const filterOptions = ({ label }, input) => label.startsWith(input);

  return (
    <Select options={options} 
      className="form-control" 
      onChange={props.onChange} 
      filterOption={filterOptions}
      value= {props.selectedValue}
      />
  )
}

export default PsvManufacurerLkp