import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import Config from '../../Constants/index'

export default function reportButtons(select) {
    return class extends GridCell {
        render() {
            return (
                <td>
                    <a
                        className="k-button"
                        href={Config.API_URL + "report/team/?matchOrderId=" + this.props.dataItem.id 
                         + "&t=" + window.localStorage.getItem("apiToken")} target="_blank">
                        گزارش تیم
                    </a>
                    <a
                        className="k-button"
                        href={Config.API_URL + "report/identity/?matchOrderId=" + this.props.dataItem.id
                         + "&t=" + window.localStorage.getItem("apiToken")} target="_blank">
                        گزارش مدارک
                    </a>

                                        <a
                        className="k-button"
                        href={Config.API_URL + "report/card/?matchOrderId=" + this.props.dataItem.id
                         + "&t=" + window.localStorage.getItem("apiToken")} target="_blank">
                        کارت مسابقه
                    </a>

                </td>
            );
        }
    };
}