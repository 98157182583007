import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import { Redirect, Link } from 'react-router-dom'
import Config from '../../Constants/index'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'
import Axios from 'axios';
import CellSetDefaultUser from '../../Component/cellSetDefaultUser';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';


export default class MasterEquipment extends Component {

    constructor(props) {
        super(props);
        let id = 0;

        if (this.props.match.params.certificateOfCalibrationRef) {
            id = this.props.match.params.certificateOfCalibrationRef;
        }

        this.state = {
            data: [], loading: true, messages: [],
            skip: 0,
            take: 100,
            total: 0,
            pageSize: 100,
            sort: [
                { field: 'id', dir: 'desc' }
            ],
            filterDialog: false,
            F_description: '',
            F_serialNo: '',
            id: id
        };


        this.fetchData = this.fetchData.bind(this);
        this.insert = this.insert.bind(this);
        this.onChange = this.onChange.bind(this);
        this.hideDialogs = this.hideDialogs.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.PAGE_TITLE = "Add/Remove Master Equipments to Calibration Report #" + id;
        this.addRemoveMasterEquipment = this.addRemoveMasterEquipment.bind(this);
    }

    getFilterStr = () => {
        if (!this.state.F_description && !this.state.F_serialNo)
            return "";

        var result = "Search: ";

        if (this.state.F_description)
            result = result + "Description contains " + this.state.F_description;

        if (this.state.F_serialNo)
            result = result + " | SerialNo contains " + this.state.F_serialNo;

        return result;
    }

    addRemoveMasterEquipment = (dataItem) => {

        Axios.post(Config.API_URL + "MasterEquipment/AddRemoveMasterEquipment",
            {
                MasterEquipmentId: dataItem.id,
                CertificateOfCalibrationId: this.props.match.params.certificateOfCalibrationRef
            },
            { headers: { token: window.localStorage.getItem("apiToken") } }
        ).then(response => {
            this.fetchData();
        });
    }

    showfilterDialog = () => {
        this.setState({ filterDialog: true });
    }

    onFilter = () => {

        this.fetchData();
        this.setState({ filterDialog: false });
    }

    removeFilter = () => {
        this.setState({
            F_description: '',
            F_serialNo: ''
        }, () => { this.fetchData() });
    }

    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take,
        }, () => {
            this.fetchData();
        });

    }

    onSortChange = (e) => {
        this.setState({
            sort: e.sort,
        }, () => {
            this.fetchData();
        });
    }


    fetchData() {
        var self = this;
        this.setState({ loading: true });

        Axios.get(Config.API_URL + "MasterEquipment/GetMasterEquipmentListAdminWithMaster/?Skip=" + this.state.skip
            + "&Take=" + this.state.take
            + "&SortField=" + (this.state.sort[0] ? this.state.sort[0].field : "")
            + "&SortDir=" + (this.state.sort[0] ? this.state.sort[0].dir : "")
            + "&F_description=" + this.state.F_description
            + "&F_serialNo=" + this.state.F_serialNo
            + "&calibrationReportId=" + this.props.match.params.certificateOfCalibrationRef
            + "&time=" + new Date().getTime(),
            {
                headers: {
                    token: window.localStorage.getItem("apiToken"),
                }
            }
        ).then(response => {
            self.setState({
                data: response.data.rowModels,
                total: response.data.total,
                loading: false
            })
        }).catch(error => {
            console.log(error);
        })
    }

    componentWillMount() {
        this.fetchData()
        this.select = this.select.bind(this);
    }

    componentDidMount() {
        document.title = this.PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }

    insert = () => {
        this.setState({ isRedirecToInsert: true });
    }

    edit(dataItem) {
        this.setState({ buildingRef: dataItem.id })
        this.setState({ isRedirectToEdit: true })
    }

    select(dataItem) {
        this.setState({ buildingRef: dataItem.id })
        this.setState({ isRedirectToMatchOrderAdmin: true })
    }


    hideDialogs() {
        this.setState({ deleteDialog: false });
        this.setState({ alertDialog: false });
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {

        return (

            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

                {/* Navbar */}
                <NavBar />
                <div className="container-fluid py-4">


                    <div className="col-12">
                        <div className="card mb-4">
                            <div class="card-header p-3 pt-2">
                                <div class="icon icon-lg icon-shape bg-gradient-info shadow text-center border-radius-xl mt-n4 float-start">
                                    <i class="fa fa fa-tools fa-fw" aria-hidden="true"></i>

                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <h6 class="mb-0">
                                            {this.PAGE_TITLE}
                                        </h6>
                                    </div>

                                </div>
                            </div>


                            <div className="card-body px-0 pt-0 pb-2">





                                <Grid data={this.state.data} skip={this.state.skip} take={this.state.take}
                                    total={this.state.total} sort={this.state.sort} pageSize={this.state.pageSize}
                                    onPageChange={this.pageChange}
                                    onSortChange={this.onSortChange}
                                    selectedField="vIsDefault"
                                    sortable pageable resizable
                                >

                                    {/* <GridToolbar>
                                        <Button iconClass="fa fa-filter"
                                            style={{ 'margin-left': '5px' }}

                                            onClick={this.showfilterDialog}>Filter</Button>

                                        <Button iconClass="fa fa-times"
                                            style={{ 'margin-left': '5px' }}

                                            onClick={this.removeFilter}>Remove</Button>

                                        {this.getFilterStr()}
                                    </GridToolbar> */}

                                    <Column field="id" title="Id" width="70px" className="NoWrap" sortable />
                                    {/* <Column field="vCalibrationTypeTitle" title="Type" width="100px" className="NoWrap" sortable /> */}
                                    <Column field="description" title="Decsription" width="200px" className="NoWrap" sortable />
                                    <Column field="model" title="Model" width="100px" className="NoWrap" sortable />
                                    <Column field="manufacture" title="Manufacture" width="150px" className="NoWrap" sortable />
                                    <Column field="serialNo" title="SerialNo" width="100px" className="NoWrap" sortable />
                                    <Column field="vCalDueDate" title="CalcDueDate" width="150px" className="NoWrap" sortable />
                                    <Column field="certificateNo" title="CertificateNo" width="150px" className="NoWrap" sortable />
                                    <Column cell={CellSetDefaultUser(this.addRemoveMasterEquipment)} width="80px" className="NoWrap" />

                                </Grid>


                                {
                                    //Make Delete As Component
                                    this.state.alertDialog > 0 &&
                                    <Dialog
                                        title="Alert"
                                        onClose={this.hideDialogs}
                                    >

                                        {this.state.messages.length > 0 &&
                                            <ul>
                                                {this.state.messages.map((message) => {
                                                    return (
                                                        <li>{message}</li>
                                                    )
                                                })}
                                            </ul>
                                        }

                                        <DialogActionsBar>
                                            <button
                                                className="k-button k-primary"
                                                onClick={this.hideDialogs}
                                            >
                                                Close
                                            </button>
                                        </DialogActionsBar>
                                    </Dialog>
                                }

                                {this.state.filterDialog &&
                                    <Dialog
                                        title="Filter"
                                        onClose={() => { this.setState({ filterDialog: false }) }}
                                        width={250}
                                    >
                                        <div>
                                            <form role="form">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Description
                                                    </label>
                                                    <input type="text" class="form-control" name="F_description"
                                                        onChange={this.onChange} value={this.state.F_description} />
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">
                                                        SerialNo
                                                    </label>
                                                    <input type="text" class="form-control" name="F_serialNo"
                                                        onChange={this.onChange} value={this.state.F_serialNo} />
                                                </div>
                                            </form>
                                        </div>

                                        <DialogActionsBar>
                                            <button
                                                className="k-button k-primary"
                                                onClick={this.onFilter}
                                            >
                                                OK
                                            </button>
                                            <button
                                                className="k-button"
                                                onClick={() => { this.setState({ filterDialog: false }) }}
                                            >
                                                Cancel
                                            </button>
                                        </DialogActionsBar>
                                    </Dialog>}

                                {
                                    this.state.loading && <Dialog>
                                        <i class='fa fa-spinner fa-spin '></i> Loading...
                                    </Dialog>
                                }



                            </div>
                        </div>
                    </div>




                </div>

                <Footer />
            </main >
        )


    }
}