import * as React from "react";
import * as ReactDOM from "react-dom";
import Config from '../../../Constants/index';
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";
import { Link, Redirect } from 'react-router-dom'
import EquipmentDeltail from "../Components/EquipmentDetail";
import F0601T1 from "../06Mass/F0601/F0601T1"

const editField = "inEdit";

const CalibrationResultF0601 = (props) => {
   
    const [certificateOfCalibrationRef, setCertificateOfCalibrationRef] = React.useState(props.match.params.entityRef);
    const [equipment, setEquipment] = React.useState('');
    const [unitOfMeasurment, setUnitOfMeasurment] = React.useState('');

    React.useEffect(() => {
        fetchCalibrationReport();
    }, []);

    const fetchCalibrationReport = () => {
        fetch(Config.API_URL + "CertificateOfCalibration/GetCertificateOfCalibration/?id=" + certificateOfCalibrationRef
            + "&timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => response.json())
            .then(json => {
                //setCalibrationReport(json)
                fetchEquipment(json.equipmentRef)
            });
    }

    const fetchEquipment = (equipmentId) => {
        fetch(Config.API_URL + "equipment/GetEquipment/?id=" + equipmentId
            + "&timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => response.json())
            .then(json => {
                setEquipment(json)
                fetchUnitOfMeasurment(json.unitMeasurmentRef)
            });
    }
    
    const fetchUnitOfMeasurment = (unitOfMeasurmentId) => {

        fetch(Config.API_URL + "UnitOfMeasurment/GetUnitOfMeasurment/?id=" + unitOfMeasurmentId
            + "&timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => response.json())
            .then(json => {
                setUnitOfMeasurment(json)
            });
    }

    return (
        <div>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">{this.PAGE_TITLE}</h1>
            </div>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                    <li className="breadcrumb-item"><Link to="/CertificateOfCalibration/">Certificates</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Result of Certificate no: {certificateOfCalibrationRef}</li> 
                </ol>
            </nav>
            <div className="row">                
                <EquipmentDeltail equipment={equipment} unitOfMeasurment={unitOfMeasurment} />
            </div>
            
            <div>                
                <F0601T1 equipment={equipment}  unitOfMeasurment={unitOfMeasurment} 
                    title = "Calibration Result For Torque Wrench"
                    tblTemplateId = {601}
                    certOfCalId={certificateOfCalibrationRef} />          
            </div>

        </div>

    );
};

export default CalibrationResultF0601;





