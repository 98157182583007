import React, { Component } from 'react'
import Config from '../../Constants/index'
import { getIdToken } from "firebase/auth";

export default class CalibrationTypeLkp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            CalibrationTypeRef: -1,
            data: []
        };

        this.onChange = this.onChange.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.props.onChange(e);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.setState({ loading: true });

        getIdToken(this.props.user).then((idToken) => {
            fetch(Config.API_URL + "CalibrationType/GetCalibrationTypeList/?timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    this.setState({ data: json, loading: false });
                });
        });
    }


    render() {
        return (
            <select class="form-control" id="exampleSelect1" name={this.props.name}
                onChange={this.onChange}
            >
                <option value={0}>-</option>
                {
                    this.state.data.map(function (item) {
                        return <option value={item.id}>{item.title}</option>
                    })
                }
            </select>
        )
    }
}
