import React, { Component } from 'react'
import { Grid, GridColumn, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import axios from 'axios';
import Config from '../Constants/index';
import { Redirect, Link } from 'react-router-dom'
import { Button } from '@progress/kendo-react-buttons'
import cellButton from '../Component/cellButton'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

class ContactUs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isRedirectInAdd: false,
            isRedirectInEdit: false,
            skip: 0,
            take: 10,
            total: 0,
            pageSize: 10,
            deleteDialog: false
        };

        this.fetchData = this.fetchData.bind(this);
        this.delete = this.delete.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.deleteDialog = this.deleteDialog.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);

    }

    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        });
        this.fetchData(event.page.skip, event.page.take)
    }


    fetchData(skip, take) {
        var self = this;
        this.setState({ loading: true });

        axios.get(Config.API_URL + "contactus/GetContactUsList/?Skip=" + skip
            + "&Take=" + take + "&time=" + new Date().getTime(),
            {
                headers: {
                    token: window.localStorage.getItem("apiToken"),
                }
            }
        ).then(response => {
            self.setState({
                data: response.data.contactUsModels,
                total: response.data.total,
                loading: false
            })
        }).catch(error => {
            console.log(error);
        })
    }

    deleteDialog(dataItem) {
        this.setState({ deleteDialog: true, selectedRow: dataItem });
    }

    delete(dataItem) {
        this.setState({ loading: true });

        axios.post(Config.API_URL + "contactus/Delete",
            {
                Id: this.state.selectedRow.id
            },

            { headers: { token: window.localStorage.getItem("apiToken") } }
        )
            .then(response => {

                if (response.status == 200) {
                    this.setState({ loading: false, deleteDialog: false });
                    this.fetchData(this.state.skip, this.state.take);
                }

            }).catch(response => {
                debugger;
                if (response.status == 403) {

                }

            });
    }

    cancelDelete() {
        this.setState({ deleteDialog: false })
    }
    componentDidMount() {
        this.fetchData(0, 10);
    }

    render() {

        return (
            <div>
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">صفحه اصلی</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">فرم های تماس با ما</li>
                                </ol>
                            </nav>

                            <Grid
                                data={this.state.data}
                                skip={this.state.skip}
                                take={this.state.take}
                                total={this.state.total}
                                pageSize={10}
                                pageable={true}
                                onPageChange={this.pageChange}
                            >
                                <GridColumn
                                    cell={cellButton(this.deleteDialog, "حذف")}
                                    width="85px"
                                />
                                <GridColumn field="id" title="کد" width="80px" />
                                <GridColumn field="createDatePersian" title="تاریخ درج" width="120px" />
                                <GridColumn field="realName" title="نام و نام خانوادگی" width="220px" />
                                <GridColumn field="phone" title="تلفن" width="150px" />
                                <GridColumn field="email" title="ایمیل" width="180px" />
                                <GridColumn field="message" title="پیام" width="300px" />

                            </Grid>
                            {this.state.deleteDialog &&
                                <Dialog
                                    title="حذف"
                                    onClose={this.cancelDelete}
                                >
                                    <div>
                                        آیا برای حذف {this.state.deleteTitle} مطمئن هستید؟
                                </div>

                                    <DialogActionsBar>
                                        <button
                                            className="k-button k-primary"
                                            onClick={this.delete}
                                        >
                                            تایید
                        </button>
                                        <button
                                            className="k-button"
                                            onClick={this.cancelDelete}
                                        >
                                            انصراف
                        </button>
                                    </DialogActionsBar>
                                </Dialog>}

                            {
                                this.state.loading && <Dialog>
                                    <i class='fa fa-spinner fa-spin '></i> Loading...
                                </Dialog>
                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactUs;