import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../../Component/Alert'
import CalibrationTypeLkp from '../../Component/Calibration/CalibrationTypeLkp';
import UserDefaultCustomer from '../../Component/Calibration/UserDefaultCustomer';
import UnitOfMeasurmentLkp from '../../Component/Calibration/UnitOfMeasurmentLkp';
import t from '../../Lang/Index'
import ReportTemplateLkp from '../../Component/Calibration/ReportTemplateLkp';
import CalibrationProcedureLkp from '../../Component/Calibration/CalibrationProcedureLkp';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import PsvManufacurerLkp from '../../Component/Calibration/PsvManufacurerLkp';
import PsvValveTypeLkp from '../../Component/Calibration/PsvValveTypeLkp';
import PsvBodyMaterialLkp from '../../Component/Calibration/PsvBodyMaterialLkp';
import PsvDesingLkp from '../../Component/Calibration/PsvDesingLkp';
import PsvTestMediumLkp from '../../Component/Calibration/PsvTestMediumLkp';

const EquipmentPsvBI_Add = (props) => {

    const PAGE_TITLE = "New " + t.psvUuc;
    const DEFUALT_CONTROLLER = "EquipmentPsvBI";

    const [id, setId] = React.useState(props.match.params.id);
    const isEdit = props.match.params.id > 0;
    const [model_Type, setModel_Type] = React.useState('');
    const [serialNo, setSerialNo] = React.useState('');
    const [tagNo, setTagNo] = React.useState('');
    const [location, setLocation] = React.useState('');
    const [customerRef, setCustomerRef] = React.useState(-1);
    const [messages, setMessages] = React.useState([]);
    const [isRedirectToEquipment, setIsRedirectToEquipment] = React.useState(false)
    const [manufacture, setManufacture] = React.useState('');
    const [valveType, setValveType] = React.useState('');
    const [bodyMaterial, setBodyMaterial] = React.useState('');
    const [design, setDesign] = React.useState('');
    const [bodySize, setBodySize] = React.useState('');
    const [inletSize, setInletSize] = React.useState('');
    const [outletSize, setOutletSize] = React.useState('');
    const [testMedium, setTestMedium] = React.useState('');
    const [setPressure, setSetPressure] = React.useState('');
    const [sapCode, setSapCode] = React.useState('');
    const [dateOfTest, setDateOfTest] = React.useState('');
    const [equipmentComments, setEquipmentComments] = React.useState('');
    const [sparesAndKits, setSparesAndKits] = React.useState('');
    const [sapItemCodeForSpares, setSapItemCodeForSpares] = React.useState('');
    const [flangeRating, setFlangeRating] = React.useState('');
    const [inspectionAndTestPreformanceBy, setInspectionAndTestPreformanceBy] = React.useState('');
    const [psvDueStatus, setPsvDueStatus] = React.useState('');
    const [spareKitRequiredOrNot, setSpareKitRequiredOrNot] = React.useState('');

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const onManufactureChange = (e) => setManufacture(e.value);
    const onModel_TypeChange = (e) => setModel_Type(e.target.value);
    const onSerialNoChnage = (e) => setSerialNo(e.target.value);
    const onTagNoChnage = (e) => setTagNo(e.target.value);
    const onLocationChange = (e) => setLocation(e.target.value);
    const onValveTypeChange = (e) => setValveType(e.value);
    const onBodyMaterialChange = (e) => setBodyMaterial(e.value);
    const onDesignChange = (e) => setDesign(e.value);
    const onBodySizeChange = (e) => setBodySize(e.target.value);
    const onInletSizeChange = (e) => setInletSize(e.target.value);
    const onOutletSizeChange = (e) => setOutletSize(e.target.value);
    const onTestMediumChange = (e) => setTestMedium(e.value);
    const onSetPressureChange = (e) => setSetPressure(e.target.value);
    const onSapCodeChange = (e) => setSapCode(e.target.value);
    const onDateOfTestChange = (e) => setDateOfTest(e.target.value);
    const onEquipmentCommentsChange = (e) => setEquipmentComments(e.target.value);
    const onSparesAndKitsChange = (e) => setSparesAndKits(e.target.value);
    const onSapItemCodeForSparesChange = (e) => setSapItemCodeForSpares(e.target.value);
    const onFlangeRatingChange = (e) => setFlangeRating(e.target.value);
    const onInspectionAndTestPreformanceByChange = (e) => setInspectionAndTestPreformanceBy(e.target.value);
    const onPsvDueStatusChange = (e) => setPsvDueStatus(e.target.value);
    const onSpareKitRequiredOrNotChange = (e) => setSpareKitRequiredOrNot(e.target.value);

    React.useEffect(() => {
        if (isEdit)
            fetchData();
    }, []);

    const fetchData = () => {
        debugger;
        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + "EquipmentPsvBI/GetEntityRow/?id=" + id + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    const item = response.data;
                    setManufacture(item.manufacture);
                    setModel_Type(item.model_Type);
                    setSerialNo(item.serialNo);
                    setTagNo(item.tagNo);
                    setLocation(item.location);
                    setValveType(item.valveType);
                    setBodyMaterial(item.bodyMaterial);
                    setDesign(item.design);
                    setBodySize(item.bodySize);
                    setInletSize(item.inletsize);
                    setOutletSize(item.outletsize);
                    setTestMedium(item.testMedium);
                    setSetPressure(item.setPressure);
                    setSapCode(item.sapCode);
                    setDateOfTest(item.dateOfTest);
                    setEquipmentComments(item.equipmentComment);
                    setSparesAndKits(item.sparesAndKits);
                    setSapItemCodeForSpares(item.sapItemCodeForSpares);
                    setFlangeRating(item.flangeRating);
                    setInspectionAndTestPreformanceBy(item.inspectionAndTestPreformanceBy);
                    setPsvDueStatus(item.psvDueStatus);
                    setSpareKitRequiredOrNot(item.spareKitRequiredOrNot);
                });
        });
    }

    const cancel = () => {
        setIsRedirectToEquipment(true);
    }


    const addEdit = () => {
        debugger;
        if (isEdit)
            edit();
        else
            add();
    }

    const add = () => {
        let messagesList = [];
        let self = this;

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + DEFUALT_CONTROLLER + "/add",
                {
                    CustomerRef: customerRef,
                    Manufacture: manufacture,
                    Model_Type: model_Type,
                    SerialNo: serialNo,
                    TagNo: tagNo,
                    Location: location,
                    ValveType: valveType,
                    BodyMaterial: bodyMaterial,
                    Design: design,
                    BodySize: bodySize,
                    INLETSIZE: inletSize,
                    OUTLETSIZE: outletSize,
                    TestMedium: testMedium,
                    SetPressure: setPressure,
                    SapCode: sapCode,
                    DateOfTest: dateOfTest,
                    EquipmentComment: equipmentComments,
                    SparesAndKits: sparesAndKits,
                    SapItemCodeForSpares: sapItemCodeForSpares,
                    FlangeRating: flangeRating,
                    InspectionAndTestPreformanceBy: inspectionAndTestPreformanceBy,
                    PSVDueStatus: psvDueStatus,
                    SpareKitRequiredOrNot: spareKitRequiredOrNot
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let _id = response.data;
                    setId(_id)
                    //TO DO redirecto Calibration Report
                    setIsRedirectToEquipment(true);
                })
                .catch(error => {
                    debugger;
                    if (error.response && error.response.status == 400) {
                        for (var key in error.response.data)
                            if (key.startsWith("message"))
                                messagesList.push(error.response.data[key]);
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setMessages(messagesList);
                });
        });
    }

    const edit = () => {
        let messagesList = [];

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "EquipmentPsvBI/edit",
                {
                    id: id,
                    Manufacture: manufacture,
                    Model_Type: model_Type,
                    SerialNo: serialNo,
                    TagNo: tagNo,
                    Location: location,
                    ValveType: valveType,
                    BodyMaterial: bodyMaterial,
                    Design: design,
                    BodySize: bodySize,
                    INLETSIZE: inletSize,
                    OUTLETSIZE: outletSize,
                    TestMedium: testMedium,
                    SetPressure: setPressure,
                    SapCode: sapCode,
                    DateOfTest: dateOfTest,
                    EquipmentComment: equipmentComments,
                    SparesAndKits: sparesAndKits,
                    SapItemCodeForSpares: sapItemCodeForSpares,
                    FlangeRating: flangeRating,
                    InspectionAndTestPreformanceBy: inspectionAndTestPreformanceBy,
                    PSVDueStatus: psvDueStatus,
                    SpareKitRequiredOrNot: spareKitRequiredOrNot
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let _id = response.data;
                    setId(_id)
                    //TO DO redirecto Calibration Report
                    setIsRedirectToEquipment(true);
                })
                .catch(error => {
                    debugger;
                    if (error.response && error.response.status == 400) {
                        for (var key in error.response.data)
                            if (key.startsWith("message"))
                                messagesList.push(error.response.data[key]);
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setMessages(messagesList);
                });
        });


    }

    const onCustomerBind = (id) => setCustomerRef(id);

    if (isRedirectToEquipment) {
        return <Redirect to={"/EquipmentPsvBI/"}></Redirect>
    }

    return (

        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-9 col-12 mx-auto position-relative">
                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fa fa-wrench fa-fw" aria-hidden="true"></i>
                            </div>
                            <h6 className="mb-0">{PAGE_TITLE}</h6>
                        </div>
                        <div className="card-body pt-2">
                            <div class="row">
                                {isEdit == true &&
                                    <div className="form-group">
                                        <label>
                                            {t.cecId}
                                        </label>
                                    </div>
                                }
                            </div>
                            <div class="row">
                                <div className="form-group">
                                    <label>
                                        Customer Name
                                    </label>
                                    <UserDefaultCustomer onBind={onCustomerBind} name="CustomerRef" user={user} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Manufacture {manufacture}
                                    </label>
                                    <PsvManufacurerLkp
                                        onChange={onManufactureChange}
                                        selectedValue={{ value: manufacture, label: manufacture }}>
                                    </PsvManufacurerLkp>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Model No
                                    </label>
                                    <input type="text" class="form-control" name="model_Type" onChange={onModel_TypeChange} value={model_Type} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Serial No
                                    </label>
                                    <input type="text" class="form-control" name="serialNo" onChange={onSerialNoChnage} value={serialNo} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Tag No
                                    </label>
                                    <input type="text" class="form-control" name="tagNo" onChange={onTagNoChnage} value={tagNo} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Location
                                    </label>
                                    <input type="text" class="form-control" name="location" onChange={onLocationChange} value={location} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Valve Type
                                    </label>
                                    <PsvValveTypeLkp onChange={onValveTypeChange}
                                        selectedValue={{ value: valveType, label: valveType }}></PsvValveTypeLkp>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Body Material
                                    </label>
                                    <PsvBodyMaterialLkp onChange={onBodyMaterialChange}
                                        selectedValue={{ value: bodyMaterial, label: bodyMaterial }}></PsvBodyMaterialLkp>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Design
                                    </label>
                                    <PsvDesingLkp onChange={onDesignChange}
                                        selectedValue={{ value: design, label: design }}></PsvDesingLkp>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Body Size
                                    </label>
                                    <input type="text" class="form-control" name="bodySize" onChange={onBodySizeChange} value={bodySize} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        INLET SIZE
                                    </label>
                                    <input type="text" class="form-control" name="inletSize" onChange={onInletSizeChange} value={inletSize} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        OUTLET SIZE
                                    </label>
                                    <input type="text" class="form-control" name="outletSize" onChange={onOutletSizeChange} value={outletSize} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Test Medium
                                    </label>
                                    <PsvTestMediumLkp onChange={onTestMediumChange}
                                        selectedValue={{ value: testMedium, label: testMedium }}></PsvTestMediumLkp>

                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Set Pressure
                                    </label>
                                    <input type="text" class="form-control" name="setPressure" onChange={onSetPressureChange} value={setPressure} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Sap Code
                                    </label>
                                    <input type="text" class="form-control" name="sapCode" onChange={onSapCodeChange} value={sapCode} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Date Of Test
                                    </label>
                                    <input type="text" class="form-control" name="dateOfTest" onChange={onDateOfTestChange} value={dateOfTest} />

                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Equipment Comment
                                    </label>
                                    <textarea type="text" class="form-control" name="equipmentComments" onChange={onEquipmentCommentsChange} value={equipmentComments} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Spares And Kits
                                    </label>
                                    <textarea type="text" class="form-control" name="sparesAndKits" onChange={onSparesAndKitsChange} value={sparesAndKits} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        SapItemCode For Spares
                                    </label>
                                    <input type="text" class="form-control" name="sapItemCodeForSpares" onChange={onSapItemCodeForSparesChange} value={sapItemCodeForSpares} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Flange Rating
                                    </label>
                                    <input type="text" class="form-control" name="flangeRating" onChange={onFlangeRatingChange} value={flangeRating} />
                                </div>
                            </div>


                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Inspection And Test Preformance By
                                    </label>
                                    <input type="text" class="form-control" name="inspectionAndTestPreformanceBy" onChange={onInspectionAndTestPreformanceByChange} value={inspectionAndTestPreformanceBy} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        PSV Due Status
                                    </label>
                                    <input type="text" class="form-control" name="psvDueStatus" onChange={onPsvDueStatusChange} value={psvDueStatus} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Spare Kit Required Or Not
                                    </label>
                                    <input type="text" class="form-control" name="spareKitRequiredOrNot" onChange={onSpareKitRequiredOrNotChange} value={spareKitRequiredOrNot} />
                                </div>
                            </div>

                            {messages.length > 0 &&
                                <Alert messages={messages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={cancel}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={addEdit}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >

    )

}

export default EquipmentPsvBI_Add;