import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../../Component/Alert'

export default class CalibrationResult_Add extends Component {

    constructor(props) {
        super(props);
        let id = 0;
        let isEdit = false;
        if (this.props.match.params.Id) {
            id = this.props.match.params.Id;
            isEdit = true;
        }

        let certificateOfCalibrationRef = this.props.match.params.certificateOfCalibrationRef;
        this.state = {

            Id: id, isEdit, Title: '', Short: '', isRedirectToParent: false,
            messages: [],
            certificateOfCalibrationRef : certificateOfCalibrationRef
        };

        this.PAGE_TITLE = "Insert Calibration Result of" + certificateOfCalibrationRef ;

        this.onChange = this.onChange.bind(this);
        this.cancel = this.cancel.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.addEdit = this.addEdit.bind(this);
        this.edit = this.edit.bind(this);
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    cancel = () => {
        this.setState({ isRedirectToParent: true })
    }

    fetchData() {
        Axios.get(Config.API_URL + "Customer/GetCustomer/?id=" + this.state.Id + "&timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                //TODO
                //this.setState({ Title: response.data.title });              
            });
    }

    componentDidMount() {
        if (this.state.isEdit)
            this.fetchData();
    }

    addEdit() {
        if (this.state.isEdit)
            this.edit();
        else
            this.add();
    }

    add() {
        let messages = [];
        let self = this;
        debugger;

        Axios.post(Config.API_URL + "CalibrationResult/add",
            {
                CertificateOfCalibrationRef: this.state.certificateOfCalibrationRef,
                UUC_Reading : this.state.UUC_Reading,
                UUC_ReadingRising : this.state.UUC_ReadingRising,
                UUC_ReadingFalling : this.state.UUC_ReadingFalling,
                AveDevition : this.state.AveDevition, 
                UnCertainty : this.state.UnCertainty,
                Status : this.state.Status
            },
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                let id = response.data;
                this.setState({ Id: id, isRedirectToParent: true });
            })
            .catch(error => {
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messages.push(p);
                    })
                }
                else {
                    //messages.push("خطا در برقراری ارتباط با سرور - در صورت استفاده از پروکسی آنرا غیر فعال نمایید")
                    messages.push("Failed to connect to server")
                }
                self.setState({ messages, isInsert: true });            
            });
    }

    edit() {

        let messages = [];
        let self = this;
        debugger;

        Axios.post(Config.API_URL + "Town_Unit/edit",
            {
                Id : this.state.Id,
                Title: this.state.Title
            },
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                let id = response.data;
                this.setState({ Id: id, isRedirectUnit: true });
            })
            .catch(error => {
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messages.push(p);
                    })
                }
                else {
                    //messages.push("خطا در برقراری ارتباط با سرور - در صورت استفاده از پروکسی آنرا غیر فعال نمایید")
                    messages.push("Failed to connect to server")
                }
                self.setState({ messages, isInsert: true });            
            });
    }


    render() {
        if (this.state.isRedirectToParent) {
            
            return <Redirect to={"/CalibrationResult/" + this.state.certificateOfCalibrationRef}></Redirect>
        }

        return (

            <div>

                
                <div id="page-wrapper">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                        <li className="breadcrumb-item"><Link to="/CertificateOfCalibration/">Certificates</Link></li>
                        <li className="breadcrumb-item"><Link to={"/CalibrationResult/" + this.state.certificateOfCalibrationRef}>
                            Result of Certificate no: {this.state.certificateOfCalibrationRef}</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Insert Result
                        </li>
                    </ol>
                </nav>



                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-8">
                                <form role="form">
                                    

                                <div class="form-group">
                                        <label for="exampleInputEmail">
                                            UUC Reading
					                    </label>
                                        <input type="number" class="form-control" name="UUC_Reading" onChange={this.onChange} 
                                            value={this.state.UUC_Reading} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail">
                                            UUC ReadingRising
					                    </label>
                                        <input type="number" class="form-control" name="UUC_ReadingRising" onChange={this.onChange} 
                                            value={this.state.UUC_ReadingRising} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail">
                                            UUC ReadingFalling
					                    </label>
                                        <input type="number" class="form-control" name="UUC_ReadingFalling" onChange={this.onChange} 
                                            value={this.state.UUC_ReadingFalling} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail">
                                            AveDevition
					                    </label>
                                        <input type="number" class="form-control" name="AveDevition" onChange={this.onChange} value={this.state.AveDevition} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail">
                                            UnCertainty
					                    </label>
                                        <input type="number" class="form-control" name="UnCertainty" onChange={this.onChange} value={this.state.UnCertainty} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail">
                                            Status
					                    </label>
                                        <input type="text" class="form-control" name="Status" onChange={this.onChange} value={this.state.Status} />
                                    </div>

                                    {this.state.messages.length > 0 &&
                        <Alert messages={this.state.messages}></Alert>}

                                    <div class="from-group" style={{ 'padding-top': '10px' }}>
                                        <button type="button" class="btn btn-primary" onClick={this.addEdit}>
                                            Save
				                        </button>
                                        <button type="button" class="btn btn-secondary" style={{ 'margin-left': '5px' }} onClick={this.cancel}>
                                            Cancel
				                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        )
    }
}
