import React from 'react'
import Select from 'react-select';

const PsvStatusTemplateLkp = (props) => {

  const options = [
    { value: 'After Adjustment Calibration Pass', label: 'aacp' },
    { value: 'Calibration Pass', label: 'cp' },
    { value: 'Rejected', label: 'r' }
  ]

  const filterOptions = ({ label }, input) => label.startsWith(input);

  return (
    <Select options={options} 
      className="form-control" 
      onChange={props.onChange} 
      filterOption={filterOptions}
      />
  )
}

export default PsvStatusTemplateLkp