import React, { Component } from 'react'
import isEmail from 'validator/lib/isEmail'
import axios from 'axios'
import { Link, Redirect } from 'react-router-dom'
import Config from '../../Constants/index'
import t from '../../Lang/Index'
import Alert from '../../Component/Alert'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import Axios from 'axios';

const SignUpForm = (props) => {

    const [isInsert, setIsInsert] = React.useState(true);
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [messages, setMessages] = React.useState('');
    const [isRedirectToHome, setisRedirectToHome] = React.useState(false);

    const onClick_Btn = (e) => {
        e.preventDefault();

        if (!validate())
            return;

        var messages = [];

        setIsInsert(false);
        setMessages(messages);

        var self = this;
        var message = '';
        createUserWithEmailAndPassword(getAuth(), email, password)
            .then(result => {
                debugger;
                Axios.post(Config.API_URL + "Auth/AddUser",
                {
                    ProviderId: result.providerId,
                    Uid: result.user.uid,
                    Email : result.user.email
                },
                { headers: { 'Authorization': 'Bearer ' + result._tokenResponse.idToken  } })
                .then(response => {
                    //let id = response.data;
                    //this.setState({ Id: id, isRedirectDoc: true });
                    setisRedirectToHome(true);
                })
                .catch(error => {
                    debugger;

                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messages.push(p);
                        })
                    }
                    else {
                        //messages.push("خطا در برقراری ارتباط با سرور - در صورت استفاده از پروکسی آنرا غیر فعال نمایید")
                        messages.push("Failed to connect to server")
                    }

                    self.setState({ messages, isInsert: true });
                });
            })
            .catch(error => {
                //window.localStorage.setItem('idToken', '');
                var message = error.message.replace("Firebase: ", "");
                messages.push(message);
                setIsInsert(true);
            });
    }

    const validate = () => {
        var messages = [];

        if (email.length == 0)
            messages.push("Email is required!");

        if (email.length > 0 && !isEmail(email))
            messages.push("email address is not correct");

        if (email.includes('citadel-supply.com') || email.includes('cec.ltd')
        || email.includes('citadel-engineering.com') || email.includes('consultingmanager.com'))
            messages.push("You should login by Microsoft 365. Citadel official Emails can't register individually");
          
        if (password == 0)
            messages.push("Password is required!");

            

        setMessages(messages);

        return messages.length == 0;
    }

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const onPasswordChange = (e) => {
        setPassword(e.target.value);
    }

    if (isRedirectToHome)
        return (<Redirect to={'/'} />)
    else
        return (

            <form role="form">
                <div className="input-group input-group-outline mb-3">
                    <label className="form-label">Email</label>
                    <input type="email" className="form-control" id="Email"
                        name="email" onChange={onEmailChange} />
                </div>
                <div className="input-group input-group-outline mb-3">
                    <label className="form-label">Password</label>
                    <input type="password" onChange={onPasswordChange} className="form-control" id="Password" />
                </div>

                {/* <div className="form-check text-left">
                <input className="form-check-input bg-dark border-dark" type="checkbox" defaultValue id="flexCheckDefault" defaultChecked />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                    I agree the <a href="../../../pages/privacy.html" className="text-dark font-weight-bolder">Terms and Conditions</a>
                </label>
                </div> */}

                {messages.length > 0 && <Alert messages={messages}></Alert>}

                <div className="text-center">
                    {/* <button type="button" className="btn bg-gradient-primary w-100 mt-4 mb-0">Sign up</button> */}

                {isInsert ?
                    (  <button className="btn bg-gradient-primary w-100 mt-4 mb-0" id="btnLogin" type="button"
                            onClick={onClick_Btn}>{t.signUp}</button>
                    
                    )
                    : (
                       <button className="btn bg-gradient-primary w-100 mt-4 mb-0 disabled" type="button" >
                            <i class='fa fa-spinner fa-spin '></i> {t.loading}</button>
                      )
                    }

                </div>



            </form>



            // <form role="form" id="RegisterForm">                
            //         <div className="form-group">
            //             <label htmlFor="exampleInputEmail1">
            //                 Email
            //             </label>
            //             <input type="text" style={{ direction: 'ltr' }} className="form-control" id="Email" 
            //                 name="email" onChange={onEmailChange}/>
            //         </div>
            //         <div className="form-group">
            //             <label htmlFor="exampleInputPassword1">
            //                 Password
            //             </label>
            //             <input type="password" style={{ direction: 'ltr' }} className="form-control" id="Password"
            //                  name="password" onChange={onPasswordChange} />
            //         </div>
            //         {messages.length > 0 &&                    <Alert messages={messages}></Alert>}

            //         {isInsert ?
            //             (<div>
            //                 <button className="btn btn-lg btn-primary btn-block" id="btnLogin" type="button"
            //                     onClick={onClick_Btn}>{t.register}</button>
            //             </div>
            //             )
            //             : (
            //                 <button className="btn btn-lg btn-primary btn-block disabled" type="button"
            //                 >
            //                     <i class='fa fa-spinner fa-spin '></i> {t.loading}</button>)
            //         }

            //     </form>

        )


}

export default SignUpForm;