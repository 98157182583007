import React, { Component } from 'react'
import Axios from 'axios';
import Config from '../Constants/index'
import { Redirect, Link } from 'react-router-dom'
import Alert from '../Component/Alert'



export default class NewsAdd extends Component {

  
  constructor(props) {
    super(props);
    this.add = this.add.bind(this);

    let id = 0;
    let isEdit = false;
    if (this.props.match.params.Id) {
      id = this.props.match.params.Id;
      isEdit = true;
    }

    this.state = { Id: id, isEdit, Title: '', Short: '', isRedirectCancel: false, isRedirectNextStep: false, messages : [] };
    this.onChange = this.onChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.addEdit = this.addEdit.bind(this);



  }

  addEdit() {

    var html = window.editor.getData();    

    if (this.state.isEdit)
      this.edit(html);
    else
      this.add(html);
  }

  add(html) {

    var self = this;
    var messages = [];
    this.setState({ messages});


    Axios.post(Config.API_URL + "News/add", { title: this.state.Title, short: this.state.Short, full: html },
      { headers: { token: window.localStorage.getItem("apiToken") } })
      .then(response => {
        let id = response.data;        
        this.setState({ Id: id, isRedirectNextStep: true });
      })
      .catch(function (error) {

        if (error.response && error.response.status == 400) {
            error.response.data.message.map(p => {
                messages.push(p);
            })
        }
        else {
            messages.push("خطا در برقراری ارتباط با سرور - در صورت استفاده از پروکسی آنرا غیر فعال نمایید")
        }
        console.log(error);
        self.setState({ messages, isInsert: true });

    });

  }

  edit(html) {
    var self = this;
    var messages = [];
    this.setState({ messages});

    Axios.post(Config.API_URL + "News/edit", {
      id: this.state.Id, title: this.state.Title, short: this.state.Short,
      full: html
    },
      { headers: { token: window.localStorage.getItem("apiToken") } })
      .then(response => {
        this.setState({ isRedirectNextStep: true });
      })
      .catch(function (error) {

        if (error.response && error.response.status == 400) {
            error.response.data.message.map(p => {
                messages.push(p);
            })
        }
        else {
            messages.push("خطا در برقراری ارتباط با سرور - در صورت استفاده از پروکسی آنرا غیر فعال نمایید")
        }
        console.log(error);
        self.setState({ messages, isInsert: true });

    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  cancel = () => {
    this.setState({ isRedirectCancel: true })
  }

  fetchData() {
    Axios.get(Config.API_URL + "news/GetNewsAdmin/?id=" + this.state.Id + "&timestamp=" + new Date().getTime(),
      { headers: { token: window.localStorage.getItem("apiToken") } })
      .then(response => {
        window.console.log(response);
        this.setState({ Title: response.data.title, Short: response.data.short });
        window.editor.setData(response.data.full);
        //editor.setData(response.data.full);

      });
  }

  componentDidMount() {
    if (this.state.isEdit)
      this.fetchData();

    //window.CKEDITOR.replace( 'editor1' );

   

     
    window.ClassicEditor
      .create( document.querySelector( '#editor' ), {
          //extraPlugins: [ MyCustomUploadAdapterPlugin ],
    
          
          // ...
           ckfinder: {
             uploadUrl: 'http://localhost:7777/api/blog/UploadContentImage/?command=QuickUpload&type=Files&responseType=json&token=' 
             + window.localStorage.getItem("apiToken"),
           },
          toolbar: [  'imageUpload','link', '|', 'heading', '|', 'bold', 'italic', '|', 'undo', 'redo' ]
      } )
      .then( editor => {
        window.editor = editor;

        //handleStatusChanges( editor );
        //handleSaveButton( editor );
        //handleBeforeunload( editor );
      })
      .catch( error => {
          console.log( error );
      } );




      

  }


  render() {
    if (this.state.isRedirectCancel)
      return <Redirect to="/News/" />
    if (this.state.isRedirectNextStep)
      return <Redirect to={"/News/UploadImage1/" + this.state.Id}></Redirect>

    return (
      <div>
        <div id="page-wrapper">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">صفحه اصلی</Link></li>
                  <li className="breadcrumb-item"><Link to="/news">اخبار واطلاعیه ها</Link></li>
                  {this.state.isEdit ?
                    <li className="breadcrumb-item active" aria-current="page">ویرایش خبر</li>
                    :
                    <li className="breadcrumb-item active" aria-current="page">درج خبر جدید</li>
                  }
                </ol>
              </nav>
            </div>
          </div>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-8">
                <form role="form">
                  <div class="form-group">
                    <label for="exampleInputEmail1">
                      عنوان
					          </label>
                    <input type="text" class="form-control" id="title" name="Title" onChange={this.onChange} value={this.state.Title} />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">
                      شرح
					          </label>
                    <input type="text" class="form-control" id="short" name="Short" onChange={this.onChange} value={this.state.Short} />
                  </div>
                  <div class="from-group">

                    {/* <textarea name="editor1"></textarea> */}
                    <textarea name="content" id="editor">
                     
                    </textarea>

                    {/* <textarea name="editor1">rreerre</textarea>
		<script>
			CKEDITOR.replace( 'editor1' );
		</script> */}


                    {/* <CKEditor
                      editor={ClassicEditor}
                      data={this.state.Full}
                      onInit={editor => {
                        // You can store the "editor" and use when it is needed.
                        //console.log('Editor is ready to use!', editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        //console.log({ event, editor, data });
                        this.handleEditorChange(data);
                      }
                      }

                      config={{
                        toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
                        //removeButtons: 'Cut,Copy,Paste'
                      }}

                    /> */}
                  </div>
                  {this.state.messages.length > 0 &&
                        <Alert messages={this.state.messages}></Alert>}
                  <div class="from-group" style={{ 'padding-top': '10px' }}>
                    <button type="button" class="btn btn-primary" onClick={this.addEdit}>
                      تایید و ادامه
				          </button>
                    <button type="button" class="btn btn-secondary" style={{ 'margin-right': '5px' }} onClick={this.cancel}>
                      انصراف
				          </button>
                  </div>



                </form>
              </div>
            </div>
          </div>

        </div>

      </div >
    )
  }
}




     