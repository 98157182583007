import * as React from "react";
import * as ReactDOM from "react-dom";
import Config from '../../../Constants/index';
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";
import { Link, Redirect } from 'react-router-dom'
import EquipmentDeltail from "../Components/EquipmentDetail";

import EN0121_DC_Voltage from "./EN0121/EN0121_DC_Voltage"
import F0301T_DC_Current from "./EN0121/F0301T_DC_Current"
import F0301T_AC_Voltage from "./EN0121/EN0121_AC_Voltage_Current"
import F0301T_AC_Current from "./EN0121/EN0121_AC_Voltage_Current"
import F0301T_Frequency from "./EN0121/F0301T_Frequency"
import EN0121_Capacitance from "./EN0121/EN0121_Capacitance"
import EN0121_Temperature from "./EN0121/EN0121_Temperature"



import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import NavBar from '../../../Navigation/NavBar';
import Footer from '../../../Navigation/Footer';

const editField = "inEdit";

const CalibrationResultEN0121 = (props) => {

    const [certificateOfCalibrationRef, setCertificateOfCalibrationRef] = React.useState(props.match.params.entityRef);
    const [equipment, setEquipment] = React.useState('');
    const [unitOfMeasurment, setUnitOfMeasurment] = React.useState('');

    const auth = getAuth();
    const [user, loadingAuth, error] = useAuthState(auth);

    React.useEffect(() => {
        fetchCalibrationReport();
    }, []);

    const fetchCalibrationReport = () => {
        getIdToken(user).then((idToken) => {
            fetch(Config.API_URL + "CertificateOfCalibration/GetCertificateOfCalibration/?id=" + certificateOfCalibrationRef
                + "&timestamp=" + new Date().getTime(),
                { headers: { token: window.localStorage.getItem("apiToken") } })
                .then(response => response.json())
                .then(json => {
                    //setCalibrationReport(json)
                    fetchEquipment(json.equipmentRef)
                });
        });

    }

    const fetchEquipment = (equipmentId) => {
        getIdToken(user).then((idToken) => {
            fetch(Config.API_URL + "equipment/GetEquipment/?id=" + equipmentId
                + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    setEquipment(json)
                    fetchUnitOfMeasurment(json.unitMeasurmentRef)
                });
        });

    }

    const fetchUnitOfMeasurment = (unitOfMeasurmentId) => {
        getIdToken(user).then((idToken) => {
            fetch(Config.API_URL + "UnitOfMeasurment/GetUnitOfMeasurment/?id=" + unitOfMeasurmentId
                + "&timestamp=" + new Date().getTime(),
                { headers: { token: window.localStorage.getItem("apiToken") } })
                .then(response => response.json())
                .then(json => {
                    setUnitOfMeasurment(json)
                });
        });
    }

    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-12">
                    <div className="card mb-4">
                        <div class="card-header p-3 pt-2">
                            <div class="icon icon-lg icon-shape bg-gradient-info shadow text-center border-radius-xl mt-n4 float-start">
                                <i class="fas fa-flask" aria-hidden="true"></i>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 class="mb-0">Result of Certificate no: {certificateOfCalibrationRef}</h6>
                                </div>

                            </div>
                        </div>


                        <div className="card-body px-0 pt-0 pb-2">

                            <div>

                                <div className="row">
                                    <EquipmentDeltail equipment={equipment} unitOfMeasurment={unitOfMeasurment} />
                                </div>

                                <div>
                                    <EN0121_DC_Voltage
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT DC MILIVOLTAGE"
                                        tblTemplateId={12101}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={134}
                                    />

                                    <EN0121_DC_Voltage
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT DC VOLTAGE"
                                        tblTemplateId={12102}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={133}
                                    />

                                    <F0301T_AC_Voltage
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT AC MILIVOLTAGE"
                                        tblTemplateId={12103}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={1134}
                                    />

                                    <F0301T_AC_Voltage
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT AC VOLTAGE"
                                        tblTemplateId={12104}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={1133}
                                    />

                                    <EN0121_DC_Voltage
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT RESISTANCE LOW RANGE"
                                        tblTemplateId={12105}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={144}
                                    />
                                    <EN0121_DC_Voltage
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT RESISTANCE MEDIUM RANGE"
                                        tblTemplateId={12106}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={143}
                                    />
                                    <EN0121_DC_Voltage
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT RESISTANCE HIGH RANGE"
                                        tblTemplateId={12107}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={142}
                                    />
                                    <EN0121_DC_Voltage
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT RESISTANCE ULTRA HIGH RANGE"
                                        tblTemplateId={12108}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={141}
                                    />

                                    <EN0121_DC_Voltage
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT FIXED RESISTANCE LOW RANGE"
                                        tblTemplateId={12126}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={144}
                                    />
                                    <EN0121_DC_Voltage
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT FIXED RESISTANCE MEDIUM RANGE"
                                        tblTemplateId={12127}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={143}
                                    />
                                    <EN0121_DC_Voltage
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT FIXED RESISTANCE HIGH RANGE"
                                        tblTemplateId={12128}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={142}
                                    />
                                    <EN0121_DC_Voltage
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT FIXED RESISTANCE ULTRA HIGH RANGE"
                                        tblTemplateId={12129}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={141}
                                    />

                                    <F0301T_DC_Current
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT DC CURRENT LOW RANGE"
                                        tblTemplateId={12109}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={138}
                                    />
                                    <F0301T_DC_Current
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT DC CURRENT MEDIUM RANGE"
                                        tblTemplateId={12110}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={137}
                                    />
                                    <F0301T_DC_Current
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT DC CURRENT HIGH RANGE"
                                        tblTemplateId={12111}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={136}
                                    />


                                    <F0301T_AC_Current
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT AC CURRENT LOW RANGE"
                                        tblTemplateId={12112}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={1138}
                                    />
                                    <F0301T_AC_Current
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT AC CURRENT MEDIUM RANGE"
                                        tblTemplateId={12113}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={1137}
                                    />
                                    <F0301T_AC_Current
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT AC CURRENT HIGH RANGE"
                                        tblTemplateId={12114}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={1136}
                                    />
                                    <F0301T_Frequency
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT FREQUENCY"
                                        tblTemplateId={12115}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={164}
                                    />

                                    <EN0121_Capacitance
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT CAPACITANCE LOW RANGE"
                                        tblTemplateId={12116}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={163}
                                    />

                                    <EN0121_Capacitance
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT CAPACITANCE MEDIUM RANGE"
                                        tblTemplateId={12117}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={162}
                                    />

                                    <EN0121_Capacitance
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT CAPACITANCE HIGH RANGE"
                                        tblTemplateId={12118}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={161}
                                    />

                                    {/* TODO 9619   */}
                                    <EN0121_Temperature
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT TEMPERATURE °C"
                                        tblTemplateId={12120}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={72}
                                    />

                                    <EN0121_Temperature
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT TEMPERATURE °F"
                                        tblTemplateId={12121}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={74}
                                    />

                                    <EN0121_Temperature
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT TEMPERATURE (TC) °C"
                                        tblTemplateId={12122}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={72}
                                    />

                                    <EN0121_Temperature
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT TEMPERATURE (TC) °F"
                                        tblTemplateId={12123}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={74}
                                    />

                                    <EN0121_Temperature
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT TEMPERATURE (RTD) °C"
                                        tblTemplateId={12124}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={72}
                                    />

                                    <EN0121_Temperature
                                        equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="MEASUREMENT TEMPERATURE (RTD) °F"
                                        tblTemplateId={12125}
                                        certOfCalId={certificateOfCalibrationRef}
                                        masterEquipmentSpecUmRef={74}
                                    />


                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >
    );
};

export default CalibrationResultEN0121;





