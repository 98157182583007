import Decimal from 'decimal.js';

class PressureResultCalc {

    average = (...args) => args.reduce((a, b) => a + b) / args.length;

    countDecimals = (value) => {
        var str = Number(value).toString();

        if (Math.floor(str.valueOf()) === str.valueOf()) return 0;
    
        if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
            return str.split("-")[1] || 0;
        } else if (str.indexOf(".") !== -1) {
            return str.split(".")[1].length || 0;
        }

        return str.split("-")[1] || 0;
    }

    roundUp = (number, digits) => {       
        var factor = Math.pow(10,digits);
        let _number = new Decimal(number);
        let _factor = new Decimal(factor);               
        let result = _number.mul(_factor).ceil().div(_factor);
        return result.toNumber();
    }



    GetRow = (item,equipment) => {
        let uuC_ReadingRising = parseFloat(item.uuC_ReadingRising);
        let uuC_ReadingFalling = parseFloat(item.uuC_ReadingFalling);

        let ave = parseFloat(((uuC_ReadingRising + uuC_ReadingFalling) / 2).toFixed(4)); 
        item.meanValue =  ave;
        let deviation =  parseFloat((item.uuC_Setting - ave).toFixed(4));
        item.deviation = deviation;

        let tolerance = (equipment.accurancy *  (Math.abs(equipment.rangeMin) + Math.abs(equipment.rangeMax))) /100;

        item.status = Math.abs(deviation) <= tolerance ? "Pass" : "Fail";
        item.isChanged = true;
        item.unCertainty = 0; 
        return item;
    }    

    GetRowF0103 = (item,equipment) => {
        let ref_ReadingRising = parseFloat(item.reF_ReadingRising);
        let ref_ReadingFalling = parseFloat(item.reF_ReadingFalling);
        let ref_ReadingRising2 = parseFloat(item.reF_ReadingRising2);
        let ref_ReadingFalling2 = parseFloat(item.reF_ReadingFalling2);
        let uuC_ReadingRising = parseFloat(item.uuC_ReadingRising);
        let uuC_ReadingFalling = parseFloat(item.uuC_ReadingFalling);
        let uuC_ReadingRising2 = parseFloat(item.uuC_ReadingRising2);
        let uuC_ReadingFalling2 = parseFloat(item.uuC_ReadingFalling2);
        
        let aveRef = parseFloat(((ref_ReadingRising + ref_ReadingFalling) / 2).toFixed(4)); 
        let aveRef2 = parseFloat(((ref_ReadingRising2 + ref_ReadingFalling2) / 2).toFixed(4)); 
        let aveUuc = parseFloat(((uuC_ReadingRising + uuC_ReadingFalling) / 2).toFixed(4)); 
        let aveUuc2 = parseFloat(((uuC_ReadingRising2 + uuC_ReadingFalling2) / 2).toFixed(4)); 
        debugger;
        let deviation =  parseFloat((aveUuc - aveRef).toFixed(4));
        let deviation2 =  parseFloat((aveUuc2 - aveRef2).toFixed(4));
        item.deviation = deviation;
        item.deviation2 = deviation2;

        let tolerance = (equipment.accurancy *  (Math.abs(equipment.rangeMin) + Math.abs(equipment.rangeMax))) /100;

        item.status = Math.abs(deviation) <= tolerance ? "Pass" : "Fail";
        item.isChanged = true;
        item.unCertainty = 0; 
        return item;
    } 

    GetRowF0301T3Tolerance = (range,uucAccurancy,uucResulotion,uucConstantTolerance) => {
        debugger;
        let tolerance = parseFloat(range * (uucAccurancy / 100)) + parseFloat(uucConstantTolerance * uucResulotion);
          tolerance = this.roundUp(tolerance, this.countDecimals(uucResulotion));
          
        return tolerance;
    }


    GetRowF0301T3 = (item,equipment) => { 
        let reF_Setting = parseFloat(item.reF_Setting);
        
        debugger;
        let uuC_ReadingSending1 = parseFloat(item.uuC_ReadingSending1);
        let uuC_ReadingSending2 = parseFloat(item.uuC_ReadingSending2);
        let uuC_ReadingSending3 = parseFloat(item.uuC_ReadingSending3);

        let uuC_ReadingAve = parseFloat(((uuC_ReadingSending1 + uuC_ReadingSending2 + uuC_ReadingSending3) / 3));
        uuC_ReadingAve = this.roundUp(uuC_ReadingAve, this.countDecimals(item.uuC_Resulotion));

        item.uuC_ReadingAve = uuC_ReadingAve;

        item.meanValue =  uuC_ReadingAve;
        let deviation =  parseFloat((uuC_ReadingAve - reF_Setting).toFixed(4));
        item.deviation = deviation;
        //TODO use function

        let tolerancePerRow = parseFloat( (Math.abs(item.reF_Setting) * (item.uuC_Accurancy / 100))) + parseFloat(item.uuC_ConstantTolerance * item.uuC_Resulotion);
        tolerancePerRow = this.roundUp(tolerancePerRow, this.countDecimals(item.uuC_Resulotion));
        

        item.tolerancePerRow = tolerancePerRow;
        
        //reF_Setting * item.uuC_Accurancy + item.uuC_ConstantTolerance ;

        item.status = Math.abs(deviation) <= tolerancePerRow ? "Pass" : "Fail";
        item.isChanged = true;
        return item;
    }  

    GetRowEN0121 = (item,equipment) => { 
        let uuC_Setting = parseFloat(item.uuC_Setting);
        

        debugger;
        let reF_ReadingSending1 = parseFloat(item.reF_ReadingSending1);
        let reF_ReadingSending2 = parseFloat(item.reF_ReadingSending2);
        let reF_ReadingSending3 = parseFloat(item.reF_ReadingSending3);

        debugger;
        let reF_ReadingAve = parseFloat(((reF_ReadingSending1 + reF_ReadingSending2 + reF_ReadingSending3) / 3));

        let roundUpDigits = this.countDecimals(item.uuC_Resulotion) + 1;

        reF_ReadingAve = this.roundUp(reF_ReadingAve, roundUpDigits);

        item.reF_ReadingAve = reF_ReadingAve;

        item.meanValue =  reF_ReadingAve;
        let deviation =  parseFloat((uuC_Setting - reF_ReadingAve).toFixed(roundUpDigits));
        item.deviation = deviation;        

        //TODO use function

        let tolerancePerRow = parseFloat( (Math.abs(item.uuC_Setting) * (item.uuC_Accurancy / 100))) + parseFloat(item.uuC_ConstantTolerance * item.uuC_Resulotion);
        tolerancePerRow = this.roundUp(tolerancePerRow, this.countDecimals(item.uuC_Resulotion));
        

        item.tolerancePerRow = tolerancePerRow;
        
        //reF_Setting * item.uuC_Accurancy + item.uuC_ConstantTolerance ;

        item.status = Math.abs(deviation) <= tolerancePerRow ? "Pass" : "Fail";
        item.isChanged = true;
        item.unCertainty = 0; 
        return item;
    }  

    GetRowF0502 = (item,equipment) => {

        let uuC_Setting = parseFloat(item.uuC_Setting);

        debugger;
        let uuC_ReadingSending1 = parseFloat(item.uuC_ReadingSending1);
        let uuC_ReadingSending2 = parseFloat(item.uuC_ReadingSending2);
        let uuC_ReadingSending3 = parseFloat(item.uuC_ReadingSending3);

        let uuC_ReadingAve = parseFloat(((uuC_ReadingSending1 + uuC_ReadingSending2 + uuC_ReadingSending3) / 3));
        uuC_ReadingAve = this.roundUp(uuC_ReadingAve, this.countDecimals(item.uuC_Resulotion));

        item.uuC_ReadingAve = uuC_ReadingAve;

        item.meanValue =  uuC_ReadingAve;
        let deviation =  parseFloat((uuC_Setting - uuC_ReadingAve).toFixed(4));
        item.deviation = deviation; 

        let tolerance = equipment.accurancy;

        item.status = Math.abs(deviation) <= tolerance ? "Pass" : "Fail";
        item.isChanged = true;
        item.unCertainty = 0; 
        return item;
    }    

    GetRowF050202 = (item,equipment) => {
        let uuC_Reading = parseFloat(item.uuC_Reading);

        let ave = parseFloat(uuC_Reading).toFixed(4); 
        item.meanValue =  ave;
        //TODO uuC_Setting => ref_setting in database and everywhere
        let deviation =  parseFloat((item.uuC_Setting - ave).toFixed(4));
        item.deviation = deviation;

        let tolerance = equipment.accurancy;

        item.status = Math.abs(deviation) <= tolerance ? "Pass" : "Fail";
        item.isChanged = true;
        item.unCertainty = 0; 
        return item;
    }
    
    GetRowF060101 = (item,equipment) => {
        let uuC_ReadingCycle1 = parseFloat(item.reF_ReadingCycle1);
        let uuC_ReadingCycle2 = parseFloat(item.reF_ReadingCycle2);
        let uuC_ReadingCycle3 = parseFloat(item.reF_ReadingCycle3);
        let uuC_Setting = parseFloat(item.uuC_Setting);

        let ave = parseFloat(((uuC_ReadingCycle1 + uuC_ReadingCycle2 + uuC_ReadingCycle3) / 3).toFixed(4)); 

        item.meanValue =  ave;
        let deviation =  parseFloat((item.uuC_Setting - ave).toFixed(4));
        item.deviation = deviation;

        //let tolerance = (equipment.accurancy *  (Math.abs(equipment.rangeMin) + Math.abs(equipment.rangeMax))) /100;

        let tolerance = parseFloat((uuC_Setting *  equipment.accurancy)  /100);        

        item.status = Math.abs(deviation) <= tolerance ? "Pass" : "Fail";
        item.isChanged = true;
        item.unCertainty = 0; 
        return item; 
    }   


}





export default PressureResultCalc;