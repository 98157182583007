import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../../Constants';
import Alert from '../../../Component/Alert';
import NavBar from '../../../Navigation/NavBar';
import Footer from '../../../Navigation/Footer';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import MasterEquipmentSpekSelectLkp from '../../../Pages/CalibrationResult/Components/MasterEquipmentSpekSelectLkp';


const PsvResultForm = (props) => {

    const PAGE_TITLE = "Calibration PSV Report";
    const ControllerName = "CertOfCalPsv";

    const CRAKING_PRESSURE = "Cracking Pressure";
    const SET_PRESSURE = "Set Pressure";
    const RESET_PRESSURE = "Reset Pressure";

    const rowStyle = { backgroundColor: '#F0F0F0' };
    const inputReadingStyle = { width: 80 };

    const [certOfCalId, setCertOfCalId] = React.useState(props.certOfCalId);
    
    const [isRedirectCertificateOfCalibration, setIsRedirectCertificateOfCalibration] = React.useState(false);
    const [messages, setMessages] = React.useState([]);
    const [saveEnabled, setSaveEnabled] = React.useState(true);
    const [masterEquipmentSpecRef, setMasterEquipmentSpecRef] = React.useState(0);

    const [result1CpF, setResult1CpF] = React.useState('');
    const [result1SpF, setResult1SpF] = React.useState('');
    const [result1RpF, setResult1RpF] = React.useState('');

    const [result1CpL, setResult1CpL] = React.useState('');
    const [result1SpL, setResult1SpL] = React.useState('');
    const [result1RpL, setResult1RpL] = React.useState('');

    const [result2CpF, setResult2CpF] = React.useState('');
    const [result2SpF, setResult2SpF] = React.useState('');
    const [result2RpF, setResult2RpF] = React.useState('');

    const [result2CpL, setResult2CpL] = React.useState('');
    const [result2SpL, setResult2SpL] = React.useState('');
    const [result2RpL, setResult2RpL] = React.useState('');

    const [result3CpF, setResult3CpF] = React.useState('');
    const [result3SpF, setResult3SpF] = React.useState('');
    const [result3RpF, setResult3RpF] = React.useState('');

    const [result3CpL, setResult3CpL] = React.useState('');
    const [result3SpL, setResult3SpL] = React.useState('');
    const [result3RpL, setResult3RpL] = React.useState('');

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const [defaultValue, setDefaultValue] = React.useState('');

    const cancel = () => setIsRedirectCertificateOfCalibration(true);

    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {      

        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + ControllerName + "/GetCertOfCalPsvByCertId/?certOfCalRef=" + certOfCalId + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    const item = response.data;

                    setResult1CpF(item.result1CpF);
                    setResult1SpF(item.result1SpF);
                    setResult1RpF(item.result1RpF);
                    
                    setResult1CpL(item.result1CpL);
                    setResult1SpL(item.result1SpL);
                    setResult1RpL(item.result1RpL);

                    setResult2CpF(item.result2CpF);
                    setResult2SpF(item.result2SpF);
                    setResult2RpF(item.result2RpF);

                    setResult2CpL(item.result2CpL);
                    setResult2SpL(item.result2SpL);
                    setResult2RpL(item.result2RpL);
                    
                    setResult3CpF(item.result3CpF);
                    setResult3SpF(item.result3SpF);
                    setResult3RpF(item.result3RpF);

                    setResult3CpL(item.result3CpL);
                    setResult3SpL(item.result3SpL);
                    setResult3RpL(item.result3RpL);

                    setMasterEquipmentSpecRef(item.masterEquipmentSpecRef);
                    if (item.masterEquipmentSpecRef > 0)
                        fetchMasterEquipmentSpec(item.masterEquipmentSpecRef);
                    
                    
                                                           
                });
        });

    }

    const fetchMasterEquipmentSpec = (masterEquipmentSpecRef) => {
        debugger;
        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + "MasterEquipmentSpec/GetMasterEquipmentSpecAndMasterEquipmentList/?F_Id=" 
                + masterEquipmentSpecRef + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    debugger;

                    const item = response.data[0];
                    
                   setDefaultValue({ label:                               
                    item.v_MasterEquipmentManufacture + " (" + item.v_MasterEquipmentSerialNo + ") [" +
                    item.rangeMin + " ~ " + item.rangeMax + " " + item.vUmTitle + "] " 
                        + item.v_MasterEquipmentCertificateNo                                    
                    , value: masterEquipmentSpecRef });

                });
        });
    }

    const save = () => {
        let messagesList = [];

        setSaveEnabled(false);

        getIdToken(user).then((idToken) => {

        const item = {
            CertOfCalRef: certOfCalId,
            Result1CpF: result1CpF,
            Result1SpF: result1SpF,
            Result1RpF: result1RpF,
            Result1CpL: result1CpL,
            Result1SpL: result1SpL,
            Result1RpL: result1RpL,
            Result2CpF: result2CpF,
            Result2SpF: result2SpF,
            Result2RpF: result2RpF,
            Result2CpL: result2CpL,
            Result2SpL: result2SpL,
            Result2RpL: result2RpL,
            Result3CpF: result3CpF,
            Result3SpF: result3SpF,
            Result3RpF: result3RpF,
            Result3CpL: result3CpL,
            Result3SpL: result3SpL,
            Result3RpL: result3RpL,
            MasterEquipmentSpecRef: masterEquipmentSpecRef
        }
        Axios.post(Config.API_URL + ControllerName + "/UpdateResults/?timestamp=" + new Date().getTime(),
            item,
            {
                headers: {
                    'Authorization': 'Bearer ' + idToken
                }
            })
            .then(response => {
                setSaveEnabled(true);
                setIsRedirectCertificateOfCalibration(true);
            }
            ).catch(error => {
                setSaveEnabled(true);
           
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messagesList.push(p);
                    })
                }
                else {
                    messagesList.push("Failed to connect to server")
                }
                setMessages(messagesList);

            }
            );
        
        });

    }

    const masterEquipmentSpekSelectLkpHandler = (selectedValue) => {
        if (selectedValue) {
            //setVCourseTitle(selectedValue.label);

            setMasterEquipmentSpecRef(selectedValue.value);
        }
        else {
            //setVCourseTitle("");
            setMasterEquipmentSpecRef(0);
        }
    }


    if (isRedirectCertificateOfCalibration) {
        return <Redirect to={"/CertOfCalPsv/"}></Redirect>
    }

    return (

        <div className="col-lg-9 col-12 mx-auto position-relative">

            <div className="card">
                <div className="card-header p-3 pt-2">
                    <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                        <i class="fa fa fa-wrench fa-fw" aria-hidden="true"></i>
                    </div>

                    <h6 class="mb-0">Result of Certificate no: {certOfCalId}</h6>

                </div>

                <div className="card-body">

                    <div className="multisteps-form__panel border-radius-xl bg-white js-active" data-animation="FadeIn">
                        <h5 className="font-weight-bolder mb-0">{props.title}</h5>
                        {/* <p className="mb-0 text-sm">Mandatory informations</p> */}

                        <div class="row">
                            <div class="col-md-12">
                                Specification:
                                <MasterEquipmentSpekSelectLkp name="masterEquipmentSpecRef"
                                    masterEquipmentRef={0}
                                    defaultValue={defaultValue}
                                    user={user}
                                    calibrationTypeRef={18}
                                    onChange={masterEquipmentSpekSelectLkpHandler}
                                />
                            </div>
                        </div>

                        <table className="table text-right">
                            <thead>
                                <tr>
                                    <th scope="col" className="pe-2 text-start ps-2">No.</th>
                                    <th scope="col" className="pe-2" >Applied Pressure</th>
                                    <th scope="col" className="pe-2">As Found</th>
                                    <th scope="col" className="pe-2">As Left</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-start" rowSpan={3}>1</td>
                                    <td className="ps-4">{CRAKING_PRESSURE}</td>
                                    <td className="ps-4"><input type="text" style={inputReadingStyle} value={result1CpF} onChange={(e) => { setResult1CpF(e.target.value) }} /></td>
                                    <td className="ps-4"><input type="text" style={inputReadingStyle} value={result1CpL} onChange={(e) => { setResult1CpL(e.target.value) }} /></td>
                                </tr>
                                <tr>
                                    {/* rowSpan */}
                                    <td className="ps-4">{SET_PRESSURE}</td>
                                    <td className="ps-4"><input type="text" style={inputReadingStyle} value={result1SpF} onChange={(e) => { setResult1SpF(e.target.value) }} /></td>
                                    <td className="ps-4"><input type="text" style={inputReadingStyle} value={result1SpL} onChange={(e) => { setResult1SpL(e.target.value) }} /></td>
                                </tr>
                                <tr>
                                    {/* rowSpan */}
                                    <td className="ps-4">{RESET_PRESSURE}</td>
                                    <td className="ps-4"><input type="text" style={inputReadingStyle} value={result1RpF} onChange={(e) => { setResult1RpF(e.target.value) }} /></td>
                                    <td className="ps-4"><input type="text" style={inputReadingStyle} value={result1RpL} onChange={(e) => { setResult1RpL(e.target.value) }} /></td>
                                </tr>


                                <tr style={rowStyle}>
                                    <td className="text-start" rowSpan={3}>2</td>
                                    <td className="ps-4">{CRAKING_PRESSURE}</td>
                                    <td className="ps-4"><input type="text" style={inputReadingStyle} value={result2CpF} onChange={(e) => { setResult2CpF(e.target.value) }} /></td>
                                    <td className="ps-4"><input type="text" style={inputReadingStyle} value={result2CpL} onChange={(e) => { setResult2CpL(e.target.value) }} /></td>
                                </tr>
                                <tr style={rowStyle}>
                                    {/* rowSpan */}
                                    <td className="ps-4">{SET_PRESSURE}</td>
                                    <td className="ps-4"><input type="text" style={inputReadingStyle} value={result2SpF} onChange={(e) => { setResult2SpF(e.target.value) }} /></td>
                                    <td className="ps-4"><input type="text" style={inputReadingStyle} value={result2SpL} onChange={(e) => { setResult2SpL(e.target.value) }} /></td>
                                </tr>
                                <tr style={rowStyle}>
                                    {/* rowSpan */}
                                    <td className="ps-4">{RESET_PRESSURE}</td>
                                    <td className="ps-4"><input type="text" style={inputReadingStyle} value={result2RpF} onChange={(e) => { setResult2RpF(e.target.value) }} /></td>
                                    <td className="ps-4"><input type="text" style={inputReadingStyle} value={result2RpL} onChange={(e) => { setResult2RpL(e.target.value) }} /></td>
                                </tr>

                                <tr >
                                    <td className="text-start" rowSpan={3}>3</td>
                                    <td className="ps-4">{CRAKING_PRESSURE}</td>
                                    <td className="ps-4"><input type="text" style={inputReadingStyle} value={result3CpF} onChange={(e) => { setResult3CpF(e.target.value) }} /></td>
                                    <td className="ps-4"><input type="text" style={inputReadingStyle} value={result3CpL} onChange={(e) => { setResult3CpL(e.target.value) }} /></td>
                                </tr>
                                <tr>
                                    {/* rowSpan */}
                                    <td className="ps-4">{SET_PRESSURE}</td>
                                    <td className="ps-4"><input type="text" style={inputReadingStyle} value={result3SpF} onChange={(e) => { setResult3SpF(e.target.value) }} /></td>
                                    <td className="ps-4"><input type="text" style={inputReadingStyle} value={result3SpL} onChange={(e) => { setResult3SpL(e.target.value) }} /></td>
                                </tr>
                                <tr>
                                    {/* rowSpan */}
                                    <td className="ps-4">{RESET_PRESSURE}</td>
                                    <td className="ps-4"><input type="text" style={inputReadingStyle} value={result3RpF} onChange={(e) => { setResult3RpF(e.target.value) }} /></td>
                                    <td className="ps-4"><input type="text" style={inputReadingStyle} value={result3RpL} onChange={(e) => { setResult3RpL(e.target.value) }} /></td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="multisteps-form__content">
                            {messages.length > 0 &&
                                <Alert messages={messages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={cancel}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={save} disabled={!saveEnabled} >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>










            </div>
        </div>

    )
}

export default PsvResultForm;