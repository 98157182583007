import * as React from "react"
import * as ReactDOM from "react-dom"
import UnitOfMeasurmentLkp from "../../../Component/Calibration/UnitOfMeasurmentLkp";
import Config from "../../../Constants/index";
import Axios from 'axios';

const MasterEquipmentSpecLkp = (props)=> {

    const [data, setData] = React.useState([]);
    const passiveMode = props.passiveMode || 1;
    const calibrationTypeRef = props.calibrationTypeRef || 0;

    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {

        // let url = "MasterEquipment/GetMasterEquipmentList/?timestamp=";
        // if (passiveOnly)
        //     url = "MasterEquipment/GetPassiveMasterEquipmentList/?timestamp=";
        // if (activeOnly)
        //     url = "MasterEquipment/GetActiveMasterEquipmentList/?timestamp=";

        // fetch(Config.API_URL + url + new Date().getTime(),
        //    { headers: { token: window.localStorage.getItem("apiToken") } })
        //    .then(response => response.json())
        //    .then(json => {
        //        setData(json);  
        //     });


        Axios.get(Config.API_URL + "MasterEquipment/GetMasterEquipmentList/?F_PassiveMode=" + passiveMode        
        + "&F_CalibrationTypeRef=" + calibrationTypeRef + "&time=" + new Date().getTime(),
            {
                headers: {
                    token: window.localStorage.getItem("apiToken"),
                }
            }
        ).then(response => {
            debugger;
            setData(response.data);  
        }).catch(error => {
            console.log(error);
        })
    }

    const onChange = (e)=> {        
        let id = e.target.value;
        let title = e.target.selectedOptions[0].innerText;
        props.onChange(id,title);
    }
    
    return (
            <div>
            <select className="form-control" id="exampleSelect1" name={props.name} 
                onChange={onChange} value={props.selectedValue} >                    
                <option value={0} >-</option>
                {
                    data.map(function(item,index){
                            return <option  value={item.id}>{item.description + " " +  item.model + " " + item.manufacture 
                            + " [" + item.certificateNo + "]" }</option>
                        })
                }            
            </select>  
        </div>

    )
    
}

export default MasterEquipmentSpecLkp;


