import * as React from "react";
import { render } from "react-dom";
import moment from "moment";

const DueDateOfCalibration = (props) => {

    const [period, setPeriod] = React.useState('');
    const [dueDate, setDueDate] = React.useState('');
    const [dueDateEnabled, setDueDateEnabled] = React.useState(false);

    const onPeriodChange = (e) => {

        let selectedItem = e.target.value;

        if (selectedItem == "none") {
            setDueDateEnabled(false);
            setDueDate("")
            props.onDueDateOfCalibrationChange("");
            return;
        }
        if (selectedItem == "Custom") {
            setDueDateEnabled(true);
            return;
        }

        setDueDateEnabled(false);

        let d = Date.parse(props.dateOfCalibration)

        if (selectedItem == "1 month")
            d = moment(d).add(1, "M");

        if (selectedItem == "3 month")
            d = moment(d).add(3, "M");

        if (selectedItem == "6 month")
            d = moment(d).add(6, "M");

        if (selectedItem == "1 Year")
            d = moment(d).add(1, "y");

        if (selectedItem == "2 Year")
            d = moment(d).add(2, "y");

        if (selectedItem == "3 Year")
            d = moment(d).add(3, "y");

        //Decrease a day
        d = moment(d).add(-1, "day");

        let dateString = moment(d).format('YYYY-MM-DD');
        setDueDate(dateString);

        props.onDueDateOfCalibrationChange(dateString)
    }

    const onDueDateChange = (e) => {
        setDueDate(e.target.value);
        props.onDueDateOfCalibrationChange(e.target.value)
    }

    return (
        <div class="row">
            <div class="col-6">
                <div className="input-group input-group-static  my-3">
                    <label for="exampleInputEmail">
                        Due Date Period
                    </label>
                    <select class="form-control" name="period" onChange={onPeriodChange}
                        readOnly={!props.dateOfCalibration}
                        disabled={!props.dateOfCalibration} >
                        <option value={"none"}>-</option>
                        <option value={"1 month"}>1 Month</option>
                        <option value={"3 month"}>3 Months</option>
                        <option value={"6 month"}>6 Months</option>
                        <option value={"1 Year"}>1 Year</option>
                        <option value={"2 Year"}>2 Year</option>
                        <option value={"3 Year"}>3 Year</option>
                        <option value={"Custom"}>Custom Date</option>
                    </select>
                </div>

            </div>
            <div class="col-6">
                <div className="input-group input-group-static  my-3">

                    <label for="exampleInputEmail">
                        Due Date Of Calibration
                    </label>
                    <input type="date" class="form-control" name={props.name} id={props.name} value={dueDate} onChange={onDueDateChange}
                        readOnly={!dueDateEnabled} disabled={!dueDateEnabled} />
                </div>

            </div>

        </div>
    )
}

export default DueDateOfCalibration;