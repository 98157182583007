import * as React from "react";
import * as ReactDOM from "react-dom";
import Config from '../../../Constants/index';
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";

import { Link, Redirect } from 'react-router-dom'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

import { MyCommandCell } from "../../Calibration/myCommandCell";


import Axios from 'axios';
import PressureResultCalc from "../../../Calc/PressureResultCalcs";
import react from "react";
import Alert from "../../../Component/Alert";
//import MasterEquipmentSpecLkp from "../Components/MasterEquipmentSpecLkp";
// import MasterEquipmentLkp from "../Components/MasterEquipmentLkp";
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'

const editField = "inEdit";

const RtResultTable = (props) => {

    const rtCertId = props.rtCertId;

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [messages, setMessages] = React.useState([]);
    const [changed, setChanged] = React.useState(false);
    const [deletedIds, setDeletedIds] = react.useState([]);
    const [pasteData, setPasteData] = react.useState('');

    const auth = getAuth();
    const [user, loadingAuth, error] = useAuthState(auth);

    React.useEffect(() => {
        setLoading(false);
        fetchData();
        console.log(rtCertId);
    }, []);

    const fetchData = () => {
        setLoading(true);

        getIdToken(user).then((idToken) => {

            fetch(Config.API_URL + "RtCertResult/GetRtCertResultList/?rtCertRef=" + rtCertId + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    setLoading(false);
                    setData(json);
                    focusFirstCell();
                });
        });
    }

    const remove = (item) => {
        if (item.isNew !== true) {
            deletedIds.push(item.id);
            setDeletedIds(deletedIds)
        }
        console.log(deletedIds);

        const newList = data.filter((record) => record.id !== item.id);
        setData(newList);
        setChanged(true);
    };

    const saveChanges = () => {

        getIdToken(user).then((idToken) => {
            Axios.post(Config.API_URL + "RtCertResult/SaveChanges",
                {
                    rtCertResultListStr: JSON.stringify(data),
                    deletedIds: deletedIds,
                    rtCertId: rtCertId
                },
                { headers: { 'Authorization': 'Bearer ' + idToken } })
                .then(response => {
                    //let id = response.data;
                    fetchData();
                    //this.setState({ Id: id, isRedirectToParent: true });
                    setChanged(false)
                    setMessages([]);
                })
                .catch(error => {
                    debugger;
                    let list = [];
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            list.push(p);
                        })
                    }
                    else {
                        list.push("Failed to connect to server")
                    }
                    debugger;
                    setMessages(list);
                });
        });
    };

    const cancelChanges = () => {
        fetchData();
        setDeletedIds([]);
        setChanged(false)
    };

    const itemChange = (event) => {
        const newData = data.map((item) => {
            if (item.id === event.dataItem.id)
                item.isChanged = true;

            return item.id === event.dataItem.id
                ? { ...item, [event.field || ""]: event.value }
                : item;
        });

        setChanged(true);

        setData(newData);
    };

    const genRandomId = () => {
        //TODO not in list
        return Math.random().toString().slice(-8)
    }

    const focusFirstCell = () => {
        let firstInput = window.$(".TableRT" + " .k-input")[0];
        if (firstInput)
            firstInput.focus();
    }


    const addNew = () => {
        setData([getNewDataItem(), ...data]);
        setChanged(true);
        focusFirstCell();
    };

    const getNewDataItem = () => {
        const newDataItem = {
            inEdit: true,
            Discontinued: false,
            isNew: true,
            id: genRandomId()
        };
        return newDataItem;
    }

    const CommandCell = (props) => (
        <MyCommandCell
            {...props}
            remove={remove}
            editField={editField}
        />
    );


    return (
        <div className={"TableRT"}>
            <Grid
                data={data}
                onItemChange={itemChange}
                editField={editField}
            >
                <GridToolbar>
                    <div class="row">
                        <div class="col-md-6">
                            <button title="Add new" className="k-button k-primary" onClick={addNew}>
                                Add new
                            </button>
                        </div>
                    </div>
                </GridToolbar>

                <GridToolbar>
                    <button title="Add new 5" className="k-button k-primary" onClick={saveChanges} hidden={!changed}>
                        Save Changes
                    </button>
                    <button title="Add new 5" className="k-button k-primary" onClick={cancelChanges} hidden={!changed}>
                        Cancel Changes
                    </button>
                </GridToolbar>
                <Column cell={CommandCell} width="100px" />
                <Column field="id" title="Id" width="100px" editable={false} />


                <Column field="weldNo" title="Weld No." width="120px" editor="text" editable />
                <Column title="Welder No.">
                    <Column field="diaThickMm" title="Dia/Thick mm" width="200px" editor="text" editable />
                    <Column field="impDesc" title="Imperfection Description" width="300px" editor="text" editable />
                    <Column field="resultAccRep" title="Result" width="80px" editor="text" editable />
                    <Column field="remarks" title="Remarks" width="300px" editor="text" editable />
                    <Column field="aWeldNoR" title="R" width="80px" editor="text" editable />
                    <Column field="aWeldNoH" title="H" width="80px" editor="text" editable />
                    <Column field="aWeldNoF1" title="F1" width="80px" editor="text" editable />
                    <Column field="aWeldNoF2" title="F2" width="80px" editor="text" editable />
                    <Column field="aWeldNoF3" title="F3" width="80px" editor="text" editable />
                    <Column field="aWeldNoF4" title="F4" width="80px" editor="text" editable />
                    <Column field="aWeldNoC1" title="C1" width="80px" editor="text" editable />
                    <Column field="aWeldNoC2" title="C2" width="80px" editor="text" editable />
                    <Column field="bWeldNoR" title="R" width="80px" editor="text" editable />
                    <Column field="bWeldNoH" title="H" width="80px" editor="text" editable />
                    <Column field="bWeldNoF1" title="F1" width="80px" editor="text" editable />
                    <Column field="bWeldNoF2" title="F2" width="80px" editor="text" editable />
                    <Column field="bWeldNoF3" title="F3" width="80px" editor="text" editable />
                    <Column field="bWeldNoF4" title="F4" width="80px" editor="text" editable />
                    <Column field="bWeldNoC1" title="C1" width="80px" editor="text" editable />
                    <Column field="bWeldNoC2" title="C2" width="80px" editor="text" editable />
                </Column>


            </Grid>


            {
                messages.length > 0 &&

                <Alert messages={messages}></Alert>}

            {
                loading && <Dialog>
                    <i class='fa fa-spinner fa-spin '></i> Loading...
                </Dialog>
            }

        </div>

    );
};

export default RtResultTable;





