import React from 'react'
import Select from 'react-select';

const PsvDesingLkp = (props) => {

  const options = [
    { value: 'Conventional', label: 'c: Conventional' },
    { value: 'Bellow Type', label: 'bt: Bellow Type' },
    { value: 'Pilot Operated', label: 'po: Pilot Operated' },    
  ]    
  const filterOptions = ({ label }, input) => label.startsWith(input);

  return (
    <Select options={options} 
      className="form-control" 
      onChange={props.onChange} 
      filterOption={filterOptions}
      value= {props.selectedValue}
      />
  )
}

export default PsvDesingLkp