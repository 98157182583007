import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import Config from '../../Constants/index'
import Axios from 'axios';
import CellSetDefaultUser from '../../Component/cellSetDefaultUser';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import { getIdToken } from "firebase/auth";

export default class RtRequest extends Component {
    /* TODO Complete by Customer */
    constructor() {
        super();
        this.state = {
            data: [], loading: true, messages: [],
            skip: 0,
            take: 100,
            total: 0,
            pageSize: 100,
            deleteDialog: false,
            sort: [
                { field: 'id', dir: 'desc' }
            ],
            filterDialog: false,
            F_title: ''
        };
        this.fetchData = this.fetchData.bind(this);
        this.onChange = this.onChange.bind(this);
        this.setDefault = this.setDefault.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.PAGE_TITLE = "RtRequests";
        this.changeDefaultRtRequestOfUser = this.changeDefaultRtRequestOfUser.bind(this);
    }

    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take,
        }, () => {
            this.fetchData();
        });
    }

    onSortChange = (e) => {
        this.setState({
            sort: e.sort,
        }, () => {
            this.fetchData();
        });
    }


    fetchData() {
        var self = this;
        this.setState({ loading: true });

        getIdToken(this.props.user).then((idToken) => {
            Axios.get(Config.API_URL + "RtRequest/GetRtRequestListAdmin/?Skip=" + this.state.skip
                + "&Take=" + this.state.take
                + "&SortField=" + (this.state.sort[0] ? this.state.sort[0].field : "")
                + "&SortDir=" + (this.state.sort[0] ? this.state.sort[0].dir : "")
                + "&time=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                }
            ).then(response => {
                self.setState({
                    data: response.data.rowModels,
                    total: response.data.total,
                    loading: false
                })
            }).catch(error => {
                console.log(error);
            })
        });
    }

    componentWillMount() {
        this.fetchData()
    }

    componentDidMount() {
        document.title = this.PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }

    setDefault(dataItem) {
        getIdToken(this.props.user).then((idToken) => {

            Axios.post(Config.API_URL + "RtRequest/SetDefault",
                { Id: dataItem.id },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }

                }).then(response => {
                    this.fetchData();
                });
        });
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    changeDefaultRtRequestOfUser = (dataItem) => {
        getIdToken(this.props.user).then((idToken) => {
            Axios.post(Config.API_URL + "RtRequest/ChangeDefaultRtRequestOfUser",
                { Id: dataItem.id },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                }).then(response => {
                    this.fetchData();
                });
        });
    }

    render() {

        return (
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                {/* Navbar */}
                <NavBar />
                <div className="container-fluid py-4">
                    <div className="col-12">
                        <div className="card mb-4">
                            <div class="card-header p-3 pt-2">
                                <div class="icon icon-lg icon-shape bg-gradient-info shadow text-center border-radius-xl mt-n4 float-start">
                                    <i class="fa fa fa-users fa-fw" aria-hidden="true"></i>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <h6 class="mb-0">{this.PAGE_TITLE}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body px-0 pt-0 pb-2">
                                <Grid data={this.state.data} skip={this.state.skip} take={this.state.take}
                                    total={this.state.total} sort={this.state.sort} pageSize={this.state.pageSize}
                                    onPageChange={this.pageChange}
                                    onSortChange={this.onSortChange}
                                    sortable pageable resizable
                                    selectedField="vIsDefault"
                                >
                                    <Column field="id" title="Id" width="70px" className="NoWrap" sortable />
                                    <Column field="no" title="No" width="120px" className="NoWrap" sortable />
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main >
        )
    }
}