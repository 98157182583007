import * as React from "react";
import * as ReactDOM from "react-dom";
import Config from '../../../../Constants/index';
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";
import { Link, Redirect } from 'react-router-dom'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { MyCommandCell } from "../../../Calibration/myCommandCell";
import Axios from 'axios';
import PressureResultCalc from "../../../../Calc/PressureResultCalcs";
import react from "react";
import Alert from "../../../../Component/Alert";
import MasterEquipmentSpecLkp from "../../Components/MasterEquipmentSpecLkp";
import MasterEquipmentLkp from "../../Components/MasterEquipmentLkp";

const editField = "inEdit";

const F0103T1 = (props) => {
    const TblTemplateId = props.tblTemplateId;
    const title = props.title;
    const equipment = props.equipment;
    const unitOfMeasurment = props.unitOfMeasurment;
    const certificateOfCalibrationRef = props.certOfCalId;

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [calTbl, setCalTbl] = React.useState();    
    const [messages, setMessages] = React.useState([]);  
    const [temp, setTemp] = React.useState("");      
    const [changed,setChanged] = React.useState(false);
    const [deletedIds, setDeletedIds] = react.useState([]);  
    const [passiveMasterEquipmentRef, setPassiveMasterEquipmentRef] = React.useState(-1);  
    const [passiveMasterEquipmentTitle, setPassiveMasterEquipmentTitle] = React.useState("-");  
    const [masterEquipmentRef, setMasterEquipmentRef] = React.useState(-1);    
    const [masterEquipmentTitle, setMasterEquipmentTitle] = React.useState("-");  
    const [masterEquipmentSpecRef, setMasterEquipmentSpecRef] = React.useState(-1);    
    const [masterEquipmentSpecTitle, setMasterEquipmentSpecTitle] = React.useState("-");  
    
    React.useEffect(() => {
        fetchCalTbls()
    }, []);

    const fetchCalTbls = () => {
        
        fetch(Config.API_URL + "CalTbl/GetCalTblByCertAndTblTemplate/?certOfCalId=" + certificateOfCalibrationRef + "&reportTblTemplateId=" + TblTemplateId
            + "&timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => response.json())
            .then(calTbl => {
                setCalTbl(calTbl);                
                fetchData(calTbl.id);                                
            });
    }

    const fetchData = (calTblRef) => {
        setLoading(true);
        fetch(Config.API_URL + "CalibrationResult/GetCalibrationResultList/?calTblRef=" + calTblRef 
            + "&timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => response.json())
            .then(json => {
                setLoading(false);
                setData(json);
                focusFirstCell();
            });
    }

    const remove = (item) => { 
        if (item.isNew !== true)        
        {
            deletedIds.push(item.id); 
            setDeletedIds(deletedIds)
        }
        console.log(deletedIds);

        const newList = data.filter((record) => record.id !== item.id);
        setData(newList);
        setChanged(true);
    };

    const saveChanges = () => {

        Axios.post(Config.API_URL + "CalibrationResult/SaveChanges",
            {
                calResultListStr : JSON.stringify(data),
                deletedIds : deletedIds,
                calTblId : calTbl.id
            },
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                //let id = response.data;
                fetchData(calTbl.id);
                //this.setState({ Id: id, isRedirectToParent: true });
                setChanged(false)
                setMessages([]);

            })
            .catch(error => {
                debugger;
                let list = [];
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        list.push(p);
                    })
                }
                else {
                    list.push("Failed to connect to server")
                }
                debugger;
                setMessages(list);
                setTemp("test");
            });

    };

    const cancelChanges = () => {
        fetchData(calTbl.id);
        setDeletedIds([]);
        setChanged(false)
    };

    const itemChange = (event) => {
        const newData = data.map((item) => {           
            if (item.id === event.dataItem.id)
                item.isChanged = true;

            return item.id === event.dataItem.id
            ? { ...item, [event.field || ""]: event.value }
            : item;
        });      
        
        const calcData = newData.map((item) => {
            return item.id === event.dataItem.id
                ? new PressureResultCalc().GetRowF0103(item, equipment) : item;
        });

        setChanged(true);

        setData(calcData);
    };

    const genRandomId = () => {
        //TODO not in list
        return Math.random().toString().slice(-8)
    }

    const focusFirstCell = () =>{
        let firstInput = window.$(".Table" + TblTemplateId + " .k-input")[0];
        if (firstInput)
            firstInput.focus();
    }


    const addNew = () => {
        setData([getNewDataItem(), ...data]);
        setChanged(true);
        focusFirstCell();        
    };

    const getNewDataItem = () =>{
        const newDataItem = {
            inEdit: true,
            Discontinued: false,
            isNew : true,            
            passiveMasterEquipmentref : passiveMasterEquipmentRef,
            masterEquipmentSpecRef : masterEquipmentSpecRef,
            vPassiveMasterEquipmentTitle : passiveMasterEquipmentTitle,
            vMasterEquipmentTitle : masterEquipmentTitle,
            vMasterEquipmentSpecTitle : masterEquipmentSpecTitle,           
            id : genRandomId()
        };        
        return newDataItem;
    }

    const addNew5 = () => {
        setData([getNewDataItem(),
            getNewDataItem(),
            getNewDataItem(),
            getNewDataItem(),
            getNewDataItem(), ...data]);        
        setChanged(true);
        focusFirstCell();
    };

    const addNew6 = () => {
        setData([getNewDataItem(),
            getNewDataItem(),
            getNewDataItem(),
            getNewDataItem(),
            getNewDataItem(),
            getNewDataItem(), ...data]);        
        setChanged(true);
        focusFirstCell();
    };


    const CommandCell = (props) => (
        <MyCommandCell
            {...props}
            remove={remove}
            editField={editField}
        />
    );

    const passiveMasterEquipmentChanges = (id,title)=>{
        setPassiveMasterEquipmentRef(id);
        setPassiveMasterEquipmentTitle(title);
    }

    const masterEquipmentSaveChanges = (id,title)=>{
        setMasterEquipmentRef(id); 
        setMasterEquipmentTitle(title); 
        setMasterEquipmentSpecRef(0);
        setMasterEquipmentSpecTitle("-");

    }

    const masterEquipmentSpecChanges = (id,title)=>{
        setMasterEquipmentSpecRef(id);
        setMasterEquipmentSpecTitle(title);
    }
 
    return (
        <div className={"Table" + TblTemplateId}>
            <Grid
                // style={{
                //     height: "420px",
                // }}
                data={data}
                onItemChange={itemChange}                
                editField={editField}               
            >
                <GridToolbar>   
                    <h2>{title}</h2>
                </GridToolbar>
                <GridToolbar>            
                    <div class="row">
                        <div class="col-md-6">    
                            Passive Master Equipment:<MasterEquipmentLkp name="passiveMasterEquipmentRef" onChange={passiveMasterEquipmentChanges} 
                            passiveMode={2}  //calibrationTypeRef={1}
                             />                                                        
                        </div>
                    </div>
                </GridToolbar>
                <GridToolbar>            
                    <div class="row">
                        <div class="col-md-6">    
                            Main Master Equipment:<MasterEquipmentLkp name="masterEquipmentRef" onChange={masterEquipmentSaveChanges}                             
                            passiveMode={3}  activeOnly={true}  calibrationTypeRef={1} />                           
                        </div>
                    </div>
                </GridToolbar>
                <GridToolbar>     
                    <div class="row">
                        <div class="col-md-6">    
                            Specification:<MasterEquipmentSpecLkp name="masterEquipmentSpecRef" masterEquipmentRef={masterEquipmentRef} 
                            selectedValue={masterEquipmentSpecRef} onChange={masterEquipmentSpecChanges} />
                        </div>
                    </div>                     
                </GridToolbar>
                <GridToolbar>     
                    <div class="row">
                        <div class="col-md-6">    
                            <button title="Add new" className="k-button k-primary" onClick={addNew} 
                                disabled={passiveMasterEquipmentRef <1 || masterEquipmentSpecRef <1}>
                                Add new
                            </button>
                            <button title="Add new 5" className="k-button k-primary" onClick={addNew5}
                                disabled={passiveMasterEquipmentRef <1 || masterEquipmentSpecRef <1}>
                                Add new (5n)
                            </button>
                            <button title="Add new 6" className="k-button k-primary" onClick={addNew6}
                                disabled={passiveMasterEquipmentRef <1 || masterEquipmentSpecRef <1}>
                                Add new (6n)
                            </button>
                        </div>
                    </div> 
                </GridToolbar>
                <GridToolbar>                
                    <button title="Add new 5" className="k-button k-primary" onClick={saveChanges}  hidden={!changed}>
                        Save Changes
                    </button>
                    <button title="Add new 5" className="k-button k-primary" onClick={cancelChanges} hidden={!changed}>
                        Cancel Changes
                    </button>                            
                </GridToolbar>
                <Column cell={CommandCell} width="100px" />
                <Column field="id" title="Id" width="100px" editable={false} />
                <Column field="nominalValue" title="Nominal Value" width="120px" editor="numeric"
                    headerCell={() => { return (<div>Nominal<br /> Value <br /> {unitOfMeasurment.title}</div>) }} />
                <Column title="Ref. Reading">
                    <Column title={unitOfMeasurment.title}>
                        <Column field="reF_ReadingRising" title="Rising 1" width="120px" editor="text"  autoFocus />
                        <Column field="reF_ReadingFalling" title="Falling 1" width="120px" editor="text" />
                    </Column>
                </Column>
                <Column title="U.U.C Reading">
                    <Column title={unitOfMeasurment.title}>
                        <Column field="uuC_ReadingRising" title="Rising 1" width="120px" editor="text"  autoFocus />
                        <Column field="uuC_ReadingFalling" title="Falling 1" width="120px" editor="text" />
                    </Column>
                </Column>
                <Column field="deviation" title="Deviation" width="120px" editable={false} />
                <Column title="Ref. Reading">
                    <Column title={unitOfMeasurment.title}>
                        <Column field="reF_ReadingRising2" title="Rising 2" width="120px" editor="text"  autoFocus />
                        <Column field="reF_ReadingFalling2" title="Falling 2" width="120px" editor="text" />
                    </Column>
                </Column>
                <Column title="U.U.C Reading">
                    <Column title={unitOfMeasurment.title}>
                        <Column field="uuC_ReadingRising2" title="Rising 2" width="120px" editor="text"  autoFocus />
                        <Column field="uuC_ReadingFalling2" title="Falling 2" width="120px" editor="text" />
                    </Column>
                </Column>                
                <Column field="deviation2" title="Deviation 2" width="120px" editable={false} />
                <Column title="Uncertainty">
                    <Column title="±">
                        <Column field="unCertainty" title={unitOfMeasurment.title} width="120px" editor="numeric" editable={false} />
                    </Column>
                </Column>

                <Column field="status" title="Status" width="120px" editable={false} />
                <Column field="vPassiveMasterEquipmentTitle" title="Passive Master Equipment:" width="500px" editable={false} className="NoWrap"  />
                <Column field="vMasterEquipmentTitle" title="Main Master Equipment:" width="500px" editable={false} className="NoWrap"  />
                <Column field="vMasterEquipmentSpecTitle" title="Specification" width="500px" editable={false} className="NoWrap"  />
            </Grid>


            {
            messages.length > 0 &&
            
                <Alert messages={messages}></Alert>}
            
            {
                loading && <Dialog>
                    <i class='fa fa-spinner fa-spin '></i> Loading...
                </Dialog>
            }

        </div>

    );
};

export default F0103T1;





