import * as React from "react";
import * as ReactDOM from "react-dom";
import Config from '../../../Constants/index';
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";
import { Link, Redirect } from 'react-router-dom'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { MyCommandCell } from "../../Calibration/myCommandCell";
import { insertItem, getItems, updateItem, deleteItem } from "../../Calibration/services";
import Axios from 'axios';
import PressureResultCalc from "../../../Calc/PressureResultCalcs";
import react from "react";

const editField = "inEdit";

const CalibrationResultF0101 = (props) => {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [certificateOfCalibrationRef, setCertificateOfCalibrationRef] = React.useState(props.match.params.entityRef);
    const [messages, setMessages] = React.useState([]);
    const [alertDialog, setAlertDialog] = React.useState(false);
    const [calibrationReport, setCalibrationReport] = React.useState('');
    const [equipment, setEquipment] = React.useState('');
    const [unitOfMeasurment, setUnitOfMeasurment] = react.useState('');

    React.useEffect(() => {
        fetchCalibrationReport();
        fetchData();
        console.log(calibrationReport);
    }, []);

    const fetchCalibrationReport = () => {
        //setLoading(true);

        fetch(Config.API_URL + "CertificateOfCalibration/GetCertificateOfCalibration/?id=" + certificateOfCalibrationRef
            + "&timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => response.json())
            .then(json => {
                setCalibrationReport(json)
                fetchEquipment(json.equipmentRef)
            });
    }

    const fetchEquipment = (equipmentId) => {
        fetch(Config.API_URL + "equipment/GetEquipment/?id=" + equipmentId
            + "&timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => response.json())
            .then(json => {
                setEquipment(json)
                fetchUnitOfMeasurment(json.unitMeasurmentRef)
            });
    }

    const fetchUnitOfMeasurment = (unitOfMeasurmentId) => {

        fetch(Config.API_URL + "UnitOfMeasurment/GetUnitOfMeasurment/?id=" + unitOfMeasurmentId
            + "&timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => response.json())
            .then(json => {
                setUnitOfMeasurment(json)
            });
    }

    const fetchData = () => {
        setLoading(true);
        fetch(Config.API_URL + "CalibrationResult/GetCalibrationResultList/?certificateOfCalibrationRef=" + certificateOfCalibrationRef
            + "&timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => response.json())
            .then(json => {
                setLoading(false);
                setData(json);
            });
    }

    const remove = (dataItem) => {
        let messages = [];
        Axios.post(Config.API_URL + "CalibrationResult/delete",
            {
                Id: dataItem.id,
            },
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                //let id = response.data;
                //this.setState({ deleteDialog: false });
                fetchData();
            })
            .catch(error => {
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messages.push(p);
                    })
                }
                else {
                    messages.push("Failed to connect to server")
                }
                setMessages(messages);
                setAlertDialog(true);
            });

    };

    const add = (dataItem) => {


        Axios.post(Config.API_URL + "CalibrationResult/add",
            {
                CertificateOfCalibrationRef: certificateOfCalibrationRef,
                UUC_Setting: dataItem.uuC_Setting,
                UUC_ReadingRising: dataItem.uuC_ReadingRising,
                UUC_ReadingFalling: dataItem.uuC_ReadingFalling,
                Deviation: dataItem.deviation,
                MeanValue: dataItem.meanValue,
                UnCertainty: dataItem.unCertainty,
                Status: dataItem.status
            },
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                let id = response.data;
                fetchData();
                //this.setState({ Id: id, isRedirectToParent: true });
            })
            .catch(error => {
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messages.push(p);
                    })
                }
                else {
                    //messages.push("خطا در برقراری ارتباط با سرور - در صورت استفاده از پروکسی آنرا غیر فعال نمایید")
                    messages.push("Failed to connect to server")
                }
                setMessages(messages);
                setAlertDialog(true);
            });

    };

    const update = (dataItem) => {



        dataItem.inEdit = false;
        const newData = updateItem(dataItem);
        setData(newData);
    };

    const discard = () => {
        const newData = [...data];
        newData.splice(0, 1);
        setData(newData);
    };

    const cancel = (dataItem) => {
        const originalItem = getItems().find(
            (p) => p.id === dataItem.id
        );

        debugger;
        const newData = data.map((item) =>
            item.id === originalItem.id ? originalItem : item
        );
        setData(newData);
    };

    const enterEdit = (dataItem) => {
        setData(
            data.map((item) =>
                item.id === dataItem.id ? { ...item, inEdit: true } : item
            )
        );
    };

    const itemChange = (event) => {
        const newData = data.map((item) => {
            return item.id === event.dataItem.id
                ? { ...item, [event.field || ""]: event.value }
                : item;
        });

        const calcData = newData.map((item) => {
            return item.id === event.dataItem.id
                ? new PressureResultCalc().GetRow(item, equipment) : item;
        });

        setData(calcData);
    };

    const addNew = () => {
        const newDataItem = {
            inEdit: true,
            Discontinued: false,
        };
        setData([newDataItem, ...data]);
    };

    const CommandCell = (props) => (
        <MyCommandCell
            {...props}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
        />
    );

    return (
        <div>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">{this.PAGE_TITLE}</h1>
            </div>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                    <li className="breadcrumb-item"><Link to="/CertificateOfCalibration/">Certificates</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Result of Certificate no: {certificateOfCalibrationRef}</li>
                </ol>
            </nav>
            <div className="row">
                {/* Earnings (Monthly) Card Example */}
                <div className="col-xl-6 col-md-6 mb-4">
                    <div className="card border-left-info shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                                        <i className="fas fa-wrench fa-2x text-gray-300" />UUC-EUC-DUC Description:{equipment.description}
                                    </div>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="mb-0 font-weight-bold text-gray-800">
                                                Manufacture:{equipment.manufacture}
                                            </div>
                                            <div className="mb-0 font-weight-bold text-gray-800">
                                                Model/Type:{equipment.model_Type}
                                            </div>
                                            <div className="mb-0 font-weight-bold text-gray-800">
                                                TagNo:{equipment.tagNo}
                                            </div>
                                            <div className="mb-0 font-weight-bold text-gray-800">
                                                SerialNo:{equipment.serialNo}
                                            </div>
                                            <div className="mb-0 font-weight-bold text-gray-800">
                                                Location:{equipment.location}
                                            </div>
                                        </div>
                                        <div className="col mr-2">
                                            <div className="mb-0 font-weight-bold text-gray-800">
                                                {unitOfMeasurment.title}
                                            </div>
                                            <div className="mb-0 font-weight-bold text-gray-800">
                                                Range {equipment.rangeMin} - {equipment.rangeMax}
                                            </div>
                                            <div className="mb-0 font-weight-bold text-gray-800">
                                                Resolution {equipment.resolution}
                                            </div>
                                            <div className="mb-0 font-weight-bold text-gray-800">
                                                Accurancy {equipment.accurancy}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <img src={Config.API_URL + "equipment/getthumb1/?id=" + calibrationReport.equipmentRef}></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Grid
                style={{
                    height: "420px",
                }}
                data={data}
                onItemChange={itemChange}
                editField={editField}
            >
                <GridToolbar>
                    <button title="Add new" className="k-button k-primary" onClick={addNew}>
                        Add new
                    </button>
                </GridToolbar>
                <Column field="id" title="Id" width="50px" editable={false} />
                <Column field="uuC_Setting" title="U.U.C Setting" width="120px" editor="numeric"
                    headerCell={() => { return (<div>U.U.C <br /> setting <br /> {unitOfMeasurment.title}</div>) }} />
                <Column title="Ref. Reading">
                    <Column title={unitOfMeasurment.title}>
                        <Column field="uuC_ReadingRising" title="Rising 1" width="120px" editor="numeric" />
                        <Column field="uuC_ReadingFalling" title="Falling 1" width="120px" editor="numeric" />
                    </Column>
                </Column>
                <Column field="meanValue" title="Mean Value" width="120px" editable={false} />
                <Column field="deviation" title="Deviation" width="120px" editable={false} />
                <Column title="Uncertainty">
                    <Column title="±">
                        <Column field="unCertainty" title={unitOfMeasurment.title} width="120px" editor="numeric" />
                    </Column>
                </Column>

                <Column field="status" title="Status" width="120px" editable={false} />
                {/* <Column cell={CellDeleteButton(this.deleteConfirm)} /> */}

                <Column cell={CommandCell} width="200px" />
            </Grid>

            {
                loading && <Dialog>
                    <i class='fa fa-spinner fa-spin '></i> Loading...
                </Dialog>
            }

        </div>

    );
};

export default CalibrationResultF0101;





