import React, { Component } from 'react'
import { Grid, GridColumn, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import axios from 'axios';
import Config from '../Constants/index';
import { Redirect, Link } from 'react-router-dom'
import { Button } from '@progress/kendo-react-buttons'
import cellWithEditing from '../Component/cellEditDeleteButton'
import cellActiveToggle from '../Component/cellActiveToggle'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

class News extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isRedirectInAdd: false,
            isRedirectInEdit: false,
            skip: 0,
            take: 10,
            total: 0,
            pageSize: 10,
            deleteDialog: false,
            sort: [
                { field: 'id', dir: 'desc' }
            ],
            filterDialog:false,
            F_title : ''
            
        };

        this.fetchData = this.fetchData.bind(this);
        this.add = this.add.bind(this);
        this.edit = this.edit.bind(this);
        this.delete = this.delete.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.deleteDialog = this.deleteDialog.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
        this.activeToggle = this.activeToggle.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.showfilterDialog = this.showfilterDialog.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.onChange = this.onChange.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
    }

    getFilterStr = () =>{
        if (!this.state.F_title)
            return "";        

        var result = "جستجو: ";

        if (this.state.F_title)
            result = result + "عنوان برابر است با " + this.state.F_title;
        
        return result;        
    }


    showfilterDialog = ()=>{
        this.setState({filterDialog:true});
    }

    onFilter = () => {

        this.fetchData();
        this.setState({filterDialog:false});
    }

    removeFilter = () =>{
        this.setState({
            F_title : ''
        },()=>{this.fetchData()});
        

    }

    
    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take,           
        },()=>{
            this.fetchData();
        }); 
        
    }

    onSortChange= (e) => {
        this.setState({
            sort: e.sort,                                        
        },()=>{
            this.fetchData();
        });
    }


    fetchData() {
        var self = this;
        this.setState({ loading: true });

        axios.get(Config.API_URL + "News/GetNewsListAdmin/?Skip=" + this.state.skip
            + "&Take=" + this.state.take 
            + "&SortField=" + (this.state.sort[0] ? this.state.sort[0].field : "")
            + "&SortDir=" + (this.state.sort[0] ?  this.state.sort[0].dir : "" )
            + "&F_title=" + this.state.F_title
            + "&time=" + new Date().getTime(),
            {
                headers: {
                    token: window.localStorage.getItem("apiToken"),
                }
            }
        ).then(response => {
            self.setState({
                data: response.data.newsModels,
                total: response.data.total,
                loading: false
            })
        }).catch(error => {
            console.log(error);
        })
    }

    add() {
        this.setState({ isRedirectInAdd: true })
    }

    edit(dataItem) {
        this.setState({ isRedirectInEdit: true, selectedRow: dataItem })
    }

    activeToggle(dataItem) {
        axios.post(Config.API_URL + "News/ActiveToggle",
         {Id:dataItem.id}
            ,
            {headers:{token: window.localStorage.getItem("apiToken")}}
        ).then(response => {
            this.fetchData();
        });
    }

    deleteDialog(dataItem) {
        this.setState({ deleteDialog: true, selectedRow: dataItem });
    }

    delete(dataItem) {
        this.setState({ loading: true });

        axios.post(Config.API_URL + "News/Delete",
            {
                Id: this.state.selectedRow.id
            },

            { headers: { token: window.localStorage.getItem("apiToken") } }
        )
            .then(response => {

                if (response.status == 200) {
                    this.setState({ loading: false, deleteDialog: false });
                    this.fetchData();
                }

            }).catch(response => {
                debugger;
                if (response.status == 403) {

                }

            });
    }

    cancelDelete() {
        this.setState({ deleteDialog: false })
    }
    componentDidMount() {
        this.fetchData();
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        if (this.state.isRedirectInAdd)
            return (<Redirect to="/news/add"></Redirect>)
        if (this.state.isRedirectInEdit)
            return <Redirect to={"/news/edit/" + this.state.selectedRow.id} />

        return (
            <div>
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">صفحه اصلی</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">اخبار و اطلاعیه ها</li>
                                </ol>
                            </nav>

                            <Grid
                                data={this.state.data}
                                skip={this.state.skip}
                                take={this.state.take}
                                total={this.state.total}
                                sort={this.state.sort}
                                pageSize={10}
                                pageable={true}
                                onPageChange={this.pageChange}
                                onSortChange={this.onSortChange}
                                sortable = {true}                                
                            >

<GridToolbar>
              <Button iconClass="fa fa-filter"
                            style={{'margin-left': '5px'}}
                            
                             onClick={this.showfilterDialog}>فیلتر</Button>

                             <Button iconClass="fa fa-times"
                            style={{'margin-left': '5px'}}
                            
                             onClick={this.removeFilter}>حذف فیلتر</Button>

                            {this.getFilterStr()}
                            
                  
              </GridToolbar>


                                <GridToolbar>
                                    <Button onClick={this.add}>جدید</Button>&nbsp;
                                </GridToolbar>
                                <GridColumn field="id" title="کد" width="80px" sortable={true}/>
                                <GridColumn field="title" title="عنوان" width="500px" sortable={true}/>
                                <GridColumn field="statusTitle" title="وضعیت نمایش" width="120px" />
                                <GridColumn field="createDatePersian" title="تاریخ درج" width="120px" sortable={true}/>
                                <GridColumn
                                    cell={cellWithEditing(this.edit, this.deleteDialog)}
                                    width="150px"
                                />
                                <GridColumn cell={cellActiveToggle(this.activeToggle)} width="140px" />
                            </Grid>
                            {this.state.deleteDialog &&
                                <Dialog
                                    title="حذف"
                                    onClose={this.cancelDelete}
                                >
                                    <div>
                                        آیا برای حذف {this.state.deleteTitle} مطمئن هستید؟
                                </div>

                                    <DialogActionsBar>
                                        <button
                                            className="k-button k-primary"
                                            onClick={this.delete}
                                        >
                                            تایید
                        </button>
                                        <button
                                            className="k-button"
                                            onClick={this.cancelDelete}
                                        >
                                            انصراف
                        </button>
                                    </DialogActionsBar>
                                </Dialog>}



                                {this.state.filterDialog &&
                            <Dialog
                                title="فیلتر"
                                onClose={() => { this.setState({ filterDialog: false }) }}
                                width={250}
                            >
                                <div>
                                    <form role="form">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                عنوان
                                            </label>
                                            <input type="text" class="form-control" name="F_title"
                                                onChange={this.onChange} value={this.state.F_title}  />
                                        </div>                                        
                                    </form>
                                </div>

                                <DialogActionsBar>
                                    <button
                                        className="k-button k-primary"
                                        onClick={this.onFilter}
                                    >
                                        تایید
                        </button>
                                    <button
                                        className="k-button"
                                        onClick={() => { this.setState({ filterDialog: false }) }}
                                    >
                                        انصراف
                        </button>
                                </DialogActionsBar>
                            </Dialog>}

                            {
                                this.state.loading && <Dialog>
                                    <i class='fa fa-spinner fa-spin '></i> Loading...
                                </Dialog>
                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default News;