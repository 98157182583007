import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import cellWithEditing from '../CellWithEditing'
import { Redirect } from 'react-router-dom'
import Config from '../../Constants/index'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'
import cellEditDeleteButton from '../../Component/cellEditDeleteButton';
import Axios from 'axios';
import CellDeleteButton from '../../Component/CellDeleteButton';
import CellSetDefaultUser from '../../Component/cellSetDefaultUser';
import UserDefaultCustomerText from '../../Component/Calibration/UserDefaultCustomerText';
import CellBtnIcon from '../CellBtnIcon';
import t from '../../Lang/Index';
import CellNewCertOfCalcButton from '../../Component/CellNewCertOfCalcButton'
import { getIdToken } from "firebase/auth";
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';

export default class Mut extends Component {

    constructor() {
        super();
        this.state = {
            data: [], loading: true, messages: [],
            skip: 0,
            take: 10,
            total: 0,
            pageSize: 10,
            deleteDialog: false,
            sort: [
                { field: 'id', dir: 'desc' }
            ],
            filterDialog: false,
            F_description: '',
            F_serialNo: '',
            F_tagNo: '',
            MutRef: -1,
            isRedirectToNewCalibrationReport: false
        };

        this.fetchData = this.fetchData.bind(this);
        this.insert = this.insert.bind(this);
        this.onChange = this.onChange.bind(this);
        this.edit = this.edit.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.delete = this.delete.bind(this);
        this.hideDialogs = this.hideDialogs.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.newCalibrationReport = this.newCalibrationReport.bind(this);
        this.PAGE_TITLE = t.mut;
    }

    getFilterStr = () => {
        if (!this.state.F_description && !this.state.F_serialNo && !this.state.F_tagNo)
            return "";

        var result = " Search: ";

        if (this.state.F_description)
            result = result + "Description contains " + this.state.F_description;

        if (this.state.F_serialNo)
            result = result + " | SerialNo contains " + this.state.F_serialNo;

        if (this.state.F_tagNo)
            result = result + " | TagNo contains " + this.state.F_tagNo;

        return result;
    }


    showfilterDialog = () => {
        this.setState({ filterDialog: true });
    }

    onFilter = () => {

        this.fetchData();
        this.setState({ filterDialog: false });
    }

    removeFilter = () => {
        this.setState({
            F_description: '',
            F_serialNo: '',
            F_tagNo: ''
        }, () => { this.fetchData() });
    }

    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take,
        }, () => {
            this.fetchData();
        });

    }

    onSortChange = (e) => {
        this.setState({
            sort: e.sort,
        }, () => {
            this.fetchData();
        });
    }


    fetchData() {
        var self = this;
        this.setState({ loading: true });
        getIdToken(this.props.user).then((idToken) => {
            debugger;
            Axios.get(Config.API_URL + "Mut/GetMutListAdmin/?Skip=" + this.state.skip
                + "&Take=" + this.state.take
                + "&SortField=" + (this.state.sort[0] ? this.state.sort[0].field : "")
                + "&SortDir=" + (this.state.sort[0] ? this.state.sort[0].dir : "")
                + "&F_description=" + this.state.F_description
                + "&F_serialNo=" + this.state.F_serialNo
                + "&F_tagNo=" + this.state.F_tagNo
                + "&F_customerRef=" + this.state.CustomerRef
                + "&time=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                }
            ).then(response => {
                self.setState({
                    data: response.data.rowModels,
                    total: response.data.total,
                    loading: false
                })
            }).catch(error => {
                console.log(error);
            })
        });
    }

    componentWillMount() {
        //this.fetchData()
        this.select = this.select.bind(this);
    }

    componentDidMount() {
        document.title = this.PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }

    insert = () => {
        this.setState({ isRedirecToInsert: true });
    }

    edit(dataItem) {
        this.setState({ buildingRef: dataItem.id })
        this.setState({ isRedirectToEdit: true })
    }

    select(dataItem) {
        this.setState({ buildingRef: dataItem.id })
        this.setState({ isRedirectToMatchOrderAdmin: true })
    }

    deleteConfirm(dataItem) {
        this.setState({ buildingRef: dataItem.id })
        this.setState({ deleteDialog: true })
    }

    hideDialogs() {
        this.setState({ deleteDialog: false });
        this.setState({ alertDialog: false });
    }

    onCustomerBind = (id) => {
        this.setState({ CustomerRef: id });
        this.fetchData();
    }

    delete() {

        let messages = [];
        let self = this;
        debugger;
        getIdToken(this.props.user).then((idToken) => {
            Axios.post(Config.API_URL + "Mut/delete",
                {
                    Id: self.state.buildingRef,
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    //let id = response.data;
                    this.setState({ deleteDialog: false });
                    this.fetchData();
                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messages.push(p);
                        })
                    }
                    else {
                        messages.push("Failed to connect to server")
                    }
                    self.setState({ messages, alertDialog: true });
                });
        });
    }

    newCalibrationReport = (dataItem) => {
        this.setState({ MutRef: dataItem.id })
        this.setState({ isRedirectToNewCalibrationReport: true })
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {

        if (this.state.isRedirecToInsert) {
            return <Redirect to={'/Mut/Add'} />
        }

        if (this.state.isRedirectToEdit) {
            return <Redirect to={'/Mut/Edit/' + this.state.buildingRef} />
        }

        if (this.state.isRedirectToNewCalibrationReport) {
            return <Redirect to={'/CertificateOfCalibration/Add/&MutRef=' + this.state.MutRef} />
        }

        return (
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

                {/* Navbar */}
                <NavBar />
                <div className="container-fluid py-4">


                    <div className="col-12">
                        <div className="card mb-4">
                            <div class="card-header p-3 pt-2">
                                <div class="icon icon-lg icon-shape bg-gradient-info shadow text-center border-radius-xl mt-n4 float-start">
                                    <i class="fas fa-digital-tachograph" aria-hidden="true"></i>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <h6 class="mb-0"> 
                                            {this.PAGE_TITLE} of <UserDefaultCustomerText onBind={this.onCustomerBind} user={this.props.user} /> 
                                        </h6>
                                    </div>

                                </div>
                            </div>


                            <div className="card-body px-0 pt-0 pb-2">



                                <Grid data={this.state.data} skip={this.state.skip} take={this.state.take}
                                    total={this.state.total} sort={this.state.sort} pageSize={this.state.pageSize}
                                    onPageChange={this.pageChange}
                                    onSortChange={this.onSortChange}
                                    sortable pageable resizable
                                >

                                    <GridToolbar>
                                        <Button iconClass="fa fa-filter"
                                            style={{ 'margin-left': '5px' }}

                                            onClick={this.showfilterDialog}>Filter</Button>

                                        <Button iconClass="fa fa-times"
                                            style={{ 'margin-left': '5px' }}

                                            onClick={this.removeFilter}>Remove</Button>

                                        {this.getFilterStr()}
                                    </GridToolbar>

                                    <GridToolbar>
                                        <Button onClick={this.insert} >New {t.uucEucDuc}</Button>
                                    </GridToolbar>

                                    {/* <Column field="vCustomerName" title="Customer" width="150px" className="NoWrap"  sortable/> */}
                                    <Column field="id" title="Id" width="80px" className="NoWrap" sortable />
                                    <Column field="v_CECSN" title={t.cecId} width="100px" className="NoWrap" sortable />
                                    <Column field="serialNo" title="Serial No" width="130px" className="NoWrap" sortable />
                                    <Column field="description" title="Decsription" width="250px" className="NoWrap" sortable />
                                    <Column field="manufacture" title="Manufacture" width="150px" className="NoWrap" sortable />
                                    <Column cell={CellNewCertOfCalcButton(this.newCalibrationReport)} width="220px"></Column>
                                    <Column cell={CellDeleteButton(this.deleteConfirm)} width="110px" />
                                </Grid>
                                {
                                    //Make Delete As Component
                                    this.state.deleteDialog &&
                                    <Dialog
                                        title="Delete"
                                        onClose={this.hideDialogs}
                                    >
                                        <div>
                                            Are you sure you want to delete this item?
                                            {/* آیا برای حذف {this.state.deleteTitle} مطمئن هستید؟ */}
                                        </div>

                                        <DialogActionsBar>
                                            <button
                                                className="k-button k-primary"
                                                onClick={this.delete}
                                            >
                                                Yes
                                            </button>
                                            <button
                                                className="k-button"
                                                onClick={this.hideDialogs}

                                            >
                                                No
                                            </button>
                                        </DialogActionsBar>
                                    </Dialog>
                                }

                                {
                                    //Make Delete As Component
                                    this.state.alertDialog > 0 &&
                                    <Dialog
                                        title="Alert"
                                        onClose={this.hideDialogs}
                                    >

                                        {this.state.messages.length > 0 &&
                                            <ul>
                                                {this.state.messages.map((message) => {
                                                    return (
                                                        <li>{message}</li>
                                                    )
                                                })}
                                            </ul>
                                        }

                                        <DialogActionsBar>
                                            <button
                                                className="k-button k-primary"
                                                onClick={this.hideDialogs}
                                            >
                                                Close
                                            </button>
                                        </DialogActionsBar>
                                    </Dialog>
                                }

                                {this.state.filterDialog &&
                                    <Dialog
                                        title="Filter"
                                        onClose={() => { this.setState({ filterDialog: false }) }}
                                        width={250}
                                    >
                                        <div>
                                            <form role="form">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Description
                                                    </label>
                                                    <input type="text" class="form-control" name="F_description"
                                                        onChange={this.onChange} value={this.state.F_description} />
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">
                                                        SerialNo
                                                    </label>
                                                    <input type="text" class="form-control" name="F_serialNo"
                                                        onChange={this.onChange} value={this.state.F_serialNo} />
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">
                                                        TagNo
                                                    </label>
                                                    <input type="text" class="form-control" name="F_tagNo"
                                                        onChange={this.onChange} value={this.state.F_tagNo} />
                                                </div>
                                            </form>
                                        </div>

                                        <DialogActionsBar>
                                            <button
                                                className="k-button k-primary"
                                                onClick={this.onFilter}
                                            >
                                                OK
                                            </button>
                                            <button
                                                className="k-button"
                                                onClick={() => { this.setState({ filterDialog: false }) }}
                                            >
                                                Cancel
                                            </button>
                                        </DialogActionsBar>
                                    </Dialog>}

                                {/* {
                                    this.state.loading && <Dialog>
                                        <i class='fa fa-spinner fa-spin '></i> Loading...
                                    </Dialog>
                                } */}


                            </div>
                        </div>
                    </div>




                </div>

                <Footer />
            </main >

        )


    }
}
