import React from 'react'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import Alert from '../../Component/Alert'
import Axios from 'axios';
import Config from '../../Constants/index'
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'

const CopyPsvDialog = (props) => {

    const sourceEquipmentId = props.sourceEquipmentId;
    const [serialNo, setSerialNo] = React.useState(props.serialNo);
    const [tagNo, setTagNo] = React.useState(props.tagNo);
    const [modelType, setModelType] = React.useState(props.model_Type);
    const DEFUALT_CONTROLLER = "Equipment";
    const [messages, setMessages] = React.useState([]);    

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const onSerialNoChange = (e) => { setSerialNo(e.target.value) }
    const onTagNoChange = (e) => { setTagNo(e.target.value) }
    const onModelTypeChange = (e) => { setModelType(e.target.value) }  
    
    const copy = () => {
        let messagesList = [];
        let self = this;

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL +  DEFUALT_CONTROLLER + "/CopyPsv",
                {
                    SourceEquipmentId: sourceEquipmentId,
                    SerialNo : serialNo,
                    TagNo : tagNo,
                    ModelType : modelType                                      
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {                    
                    //TODO Call Refresh Equipment List
                    props.onSuccessed();
                    props.onHideClick();
                })
                .catch(error => {
                    debugger;
                    if (error.response && error.response.status == 400) {
                        for (var key in error.response.data) 
                            if (key.startsWith("message")) 
                                messagesList.push(error.response.data[key]);                                                   
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setMessages(messagesList);
                });
        });
    }


    return (

        <Dialog
            title="Copy PSV"
            onClose={props.onHideClick}
            width={250}
        >
            <div>                
                <form role="form">
                    <div class="form-group">
                        <label for="exampleInputEmail1">
                            NEW Serial No
                        </label>
                        <input type="text" class="form-control" name="serialNo"
                            onChange={onSerialNoChange}  value={serialNo} />
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">
                            NEW Tag No
                        </label>
                        <input type="text" class="form-control" name="tagNo"
                            onChange={onTagNoChange} value={tagNo} />
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">
                            NEW Model No
                        </label>
                        <input type="text" class="form-control" name="model_Type"
                            onChange={onModelTypeChange} value={modelType} />
                    </div>
                </form>
                {messages.length > 0 &&
                                <Alert messages={messages}></Alert>} 
            </div>


            <DialogActionsBar>
                <button
                     className="k-button k-primary"
                     onClick={copy}>                
                    OK
                </button>
                <button
                    className="k-button"
                    onClick={props.onHideClick}
                >
                    CANCEL
                </button>
            </DialogActionsBar>
        </Dialog>
    )

}

export default CopyPsvDialog;
