import * as React from "react";
import * as ReactDOM from "react-dom";
import Config from '../../../../Constants/index';
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";
import { Link, Redirect } from 'react-router-dom'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { MyCommandCell } from "../../../Calibration/myCommandCell";
import Axios from 'axios';
import PressureResultCalc from "../../../../Calc/PressureResultCalcs";
import react from "react";
import Alert from "../../../../Component/Alert";
import MasterEquipmentSpecLkp from "../../Components/MasterEquipmentSpecLkp";
import MasterEquipmentLkp from "../../Components/MasterEquipmentLkp";
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'


const editField = "inEdit";

const EN0121_AC_Voltage_Current = (props) => {
    const TblTemplateId = props.tblTemplateId;
    const title = props.title;
    const equipment = props.equipment;
    const unitOfMeasurment = props.unitOfMeasurment;
    const certificateOfCalibrationRef = props.certOfCalId;
    const masterEquipmentSpecUmRef = props.masterEquipmentSpecUmRef;

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [calTbl, setCalTbl] = React.useState();
    const [messages, setMessages] = React.useState([]);
    const [bulkInsertDialog, setBulkInsertDialog] = React.useState(false);
    const [temp, setTemp] = React.useState("");
    const [changed, setChanged] = React.useState(false);
    const [deletedIds, setDeletedIds] = react.useState([]);
    const [passiveMasterEquipmentRef, setPassiveMasterEquipmentRef] = React.useState(-1);
    const [passiveMasterEquipmentTitle, setPassiveMasterEquipmentTitle] = React.useState("-");
    const [masterEquipmentRef, setMasterEquipmentRef] = React.useState(-1);
    const [masterEquipmentTitle, setMasterEquipmentTitle] = React.useState("-");
    const [masterEquipmentSpecRef, setMasterEquipmentSpecRef] = React.useState(-1);
    const [masterEquipmentSpecTitle, setMasterEquipmentSpecTitle] = React.useState("-");
    const [range, setRange] = React.useState("");
    const [uucResulotion, setUucResulotion] = React.useState("");
    const [uucAccurancy, setUucAccurancy] = React.useState("");
    const [uucConstantTolerance, setUucConstantTolerance] = React.useState("");

    const auth = getAuth();
    const [user, loadingAuth, error] = useAuthState(auth);

    React.useEffect(() => {
        fetchCalTbls()
    }, []);

    const fetchCalTbls = () => {

        getIdToken(user).then((idToken) => {
        fetch(Config.API_URL + "CalTbl/GetCalTblByCertAndTblTemplate/?certOfCalId=" + certificateOfCalibrationRef + "&reportTblTemplateId=" + TblTemplateId
            + "&timestamp=" + new Date().getTime(),
            {
                headers: {
                    'Authorization': 'Bearer ' + idToken
                }
            })
            .then(response => response.json())
            .then(calTbl => {
                setCalTbl(calTbl);
                fetchData(calTbl.id);
            });
        });

    }

    const fetchData = (calTblRef) => {
        setLoading(true);

        getIdToken(user).then((idToken) => {

            fetch(Config.API_URL + "CalibrationResult/GetCalibrationResultList/?calTblRef=" + calTblRef
                + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    setLoading(false);
                    setData(json);
                    focusFirstCell();
                });
        });
    }

    const remove = (item) => {
        if (item.isNew !== true) {
            deletedIds.push(item.id);
            setDeletedIds(deletedIds)
        }

        const newList = data.filter((record) => record.id !== item.id);
        setData(newList);
        setChanged(true);
    };


    const calCulateUncertinitySave = () => saveChanges(false);
    const manualUncertinitySave = () => saveChanges(true);

    const saveChanges = (manualUncertinity) => {

        Axios.post(Config.API_URL + "CalibrationResult/SaveChanges",
            {
                calResultListStr: JSON.stringify(data),
                deletedIds: deletedIds,
                calTblId: calTbl.id,
                manualUncertinity
            },
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                //let id = response.data;
                fetchData(calTbl.id);
                //this.setState({ Id: id, isRedirectToParent: true });
                setChanged(false)
                setMessages([]);

            })
            .catch(error => {
                debugger;
                let list = [];
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        list.push(p);
                    })
                }
                else {
                    list.push("Failed to connect to server")
                }
                debugger;
                setMessages(list);
                setTemp("test");
            });

    };

    const cancelChanges = () => {
        fetchData(calTbl.id);
        setDeletedIds([]);
        setChanged(false)
    };

    const itemChange = (event) => {
        const newData = data.map((item) => {
            if (item.id === event.dataItem.id)
                item.isChanged = true;

            if (item.isChanged && event.field == "reF_ReadingSending1" && item.id === event.dataItem.id)
                {
                    item.reF_ReadingSending2 = event.value;
                    item.reF_ReadingSending3 = event.value;
                }

            return item.id === event.dataItem.id
                ? { ...item, [event.field || ""]: event.value }
                : item;
        });

        const calcData = newData.map((item) => {

            

            return item.id === event.dataItem.id
                ? new PressureResultCalc().GetRowEN0121(item, equipment) : item;
        });

        setChanged(true);


        

        setData(calcData);
    };

    const genRandomId = () => {
        //TODO not in list
        return Math.random().toString().slice(-8)
    }

    const focusFirstCell = () => {
        let firstInput = window.$(".Table" + TblTemplateId + " .k-input")[0];
        if (firstInput)
            firstInput.focus();
    }

    const showBulkInsert = () => {
        setBulkInsertDialog(true);
    }

    const bulkInsert = () => {
        let setting1 = range * 0.1;
        let setting2 = range * 0.9;
        let setting3 = range * 0.9;

        let frequency1 = 50;
        let frequency2 = 50;
        let frequency3 = 1000;

        setData([getNewDataItem(setting1, frequency1),
        getNewDataItem(setting2, frequency2),
        getNewDataItem(setting3, frequency3), ...data]);
        setChanged(true);
        setBulkInsertDialog(false);
        focusFirstCell();
    };

    const getNewDataItem = (reF_Setting, frequency) => {
        const newDataItem = {
            inEdit: true,
            Discontinued: false,
            isNew: true,
            passiveMasterEquipmentref: passiveMasterEquipmentRef,
            masterEquipmentSpecRef: masterEquipmentSpecRef,
            vPassiveMasterEquipmentTitle: passiveMasterEquipmentTitle,
            vMasterEquipmentTitle: masterEquipmentTitle,
            vMasterEquipmentSpecTitle: masterEquipmentSpecTitle,
            range: range,
            reF_Setting: reF_Setting,
            frequency: frequency,
            uuC_Resulotion: uucResulotion,
            uuC_Accurancy: uucAccurancy,
            uuC_ConstantTolerance: uucConstantTolerance,
            id: genRandomId()
        };
        return newDataItem;
    }

    const CommandCell = (props) => (
        <MyCommandCell
            {...props}
            remove={remove}
            editField={editField}
        />
    );

    const passiveMasterEquipmentChanges = (id, title) => {
        setPassiveMasterEquipmentRef(id);
        setPassiveMasterEquipmentTitle(title);
    }

    const masterEquipmentSaveChanges = (id, title) => {
        setMasterEquipmentRef(id);
        setMasterEquipmentTitle(title);
        setMasterEquipmentSpecRef(0);
        setMasterEquipmentSpecTitle("-");

    }

    const masterEquipmentSpecChanges = (id, title) => {
        setMasterEquipmentSpecRef(id);
        setMasterEquipmentSpecTitle(title);
    }

    const rangeChanges = (e) => {
        setRange(e.target.value);
    }

    const uucResulotionChanges = (e) => {
        setUucResulotion(e.target.value);
    }

    const uucAccurancyChanges = (e) => {
        setUucAccurancy(e.target.value);
    }

    const uucConstantToleranceChanges = (e) => {
        setUucConstantTolerance(e.target.value);
    }

    return (
        <div className={"Table" + TblTemplateId}>
            <Grid
                // style={{
                //     height: "420px",
                // }}
                data={data}
                onItemChange={itemChange}
                editField={editField}
            >
                <GridToolbar>
                    <h2>{title}</h2>
                </GridToolbar>
                <GridToolbar>
                    {<button title="bulkInsert" className="k-button k-primary" onClick={showBulkInsert} >
                        Bulk Insert
                    </button>}
                </GridToolbar>
                <GridToolbar>
                    <button className="k-button k-primary" onClick={calCulateUncertinitySave} hidden={!changed}>
                        Calculate Uncertinity \ Save
                    </button>
                    <button className="k-button k-primary" onClick={manualUncertinitySave} hidden={!changed}>
                        Save
                    </button>
                    <button className="k-button k-primary" onClick={cancelChanges} hidden={!changed}>
                        Cancel
                    </button>
                </GridToolbar>
                <Column cell={CommandCell} width="100px" />
                <Column field="id" title="Id" width="100px" editable={false} />
                <Column field="range" title="Range" width="120px" autoFocus editable={false} />
                <Column field="uuC_Setting" title="U.U.C Setting" width="120px" editor="text" />                
                <Column  title="Ref. Reading" >                    
                    <Column field="reF_ReadingSending1" title="Sending 1" width="120px" editor="text" />
                    <Column field="reF_ReadingSending2" title="Sending 2" width="120px" editor="text" />
                    <Column field="reF_ReadingSending3" title="Sending 3" width="120px" editor="text" />
                    <Column field="reF_ReadingAve" title="Average" width="120px" editor="text" editable={false} />
                </Column>
                <Column field="frequency" title="Frequency" width="120px" editor="text" />
                <Column field="meanValue" title="Mean Value" width="120px" editable={false} />
                <Column field="deviation" title="Deviation" width="120px" editable={false} />
                <Column  title="Tolerance" >
                    <Column field="tolerancePerRow" title="Per Row" width="120px" editable={false} />               
                </Column> 
                <Column field="unCertainty" title="unCertainty ±" width="120px" editor="text" />
                <Column field="status" title="Status" width="150px" editable={false} />

                <Column title="U.U.C">
                    <Column field="uuC_Resulotion" title="Resulotion" width="150px" editable={false} className="NoWrap" />
                    <Column field="uuC_Accurancy" title="Accurancy" width="150px" editable={false} className="NoWrap" />
                    <Column field="uuC_ConstantTolerance" title="constant Tolerance" width="150px" editable={false} className="NoWrap" />
                </Column>

                <Column field="vPassiveMasterEquipmentTitle" title="Passive Master Equipment:" width="500px" editable={false} className="NoWrap" />
                <Column field="vMasterEquipmentTitle" title="Main Master Equipment:" width="500px" editable={false} className="NoWrap" />
                <Column field="vMasterEquipmentSpecTitle" title="Specification" width="500px" editable={false} className="NoWrap" />
            </Grid>


            {bulkInsertDialog &&
                <Dialog
                    title="Bulk Insert"
                    onClose={() => { setBulkInsertDialog(false); }}
                    width={650}
                >
                    <div>
                        <form role="form">
                            <div class="form-group">
                                <label for="passiveMasterEquipmentRef">
                                    Passive Master Equipment
                                </label>
                                <MasterEquipmentLkp name="passiveMasterEquipmentRef"
                                    selectedValue={passiveMasterEquipmentRef}
                                    onChange={passiveMasterEquipmentChanges} passiveMode={2} calibrationTypeRef={9} />
                            </div>
                            <div class="form-group">
                                <label for="masterEquipmentRef">
                                    Main Master Equipment *
                                </label>
                                <MasterEquipmentLkp name="masterEquipmentRef" onChange={masterEquipmentSaveChanges}
                                    selectedValue={masterEquipmentRef} passiveMode={3} calibrationTypeRef={9} />
                            </div>
                            <div class="form-group">
                                <label for="masterEquipmentSpecRef">
                                    Specification *
                                </label>
                                <MasterEquipmentSpecLkp name="masterEquipmentSpecRef" masterEquipmentRef={masterEquipmentRef}
                                    rangeUmRef={masterEquipmentSpecUmRef}
                                    selectedValue={masterEquipmentSpecRef} onChange={masterEquipmentSpecChanges} />
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">
                                            Range *
                                        </label>
                                        <input type="text" class="form-control" name="Range" onChange={rangeChanges} value={range} />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">
                                            U.U.C Resulotion *
                                        </label>
                                        <input type="text" class="form-control" name="Range" onChange={uucResulotionChanges} value={uucResulotion} />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">
                                            Resulotion Digits: {new PressureResultCalc().countDecimals(uucResulotion)}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">
                                            U.U.C Accurancy % *
                                        </label>
                                        <input type="text" class="form-control" name="Range" onChange={uucAccurancyChanges} value={uucAccurancy} />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">
                                            U.U.C Constant *
                                        </label>
                                        <input type="text" class="form-control" name="Range" onChange={uucConstantToleranceChanges} value={uucConstantTolerance} />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">
                                            Tolerance: { new PressureResultCalc().GetRowF0301T3Tolerance(range,uucAccurancy,uucResulotion,uucConstantTolerance) }
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <DialogActionsBar>
                        <button className="k-button k-primary" onClick={bulkInsert} disabled={
                            !(masterEquipmentRef > 0 &&
                                masterEquipmentSpecRef > 0 &&
                                range != "" &&
                                uucResulotion != "" &&
                                uucAccurancy != "" &&
                                uucConstantTolerance != "")
                        } > OK </button>
                        <button
                            className="k-button"
                            onClick={() => { setBulkInsertDialog(false); }}
                        >
                            Cancel
                        </button>
                    </DialogActionsBar>
                </Dialog>
            }
            {
                messages.length > 0 &&
                <Alert messages={messages}></Alert>}
            {
                loading && <Dialog>
                    <i class='fa fa-spinner fa-spin '></i> Loading...
                </Dialog>
            }


        </div>

    );
};

export default EN0121_AC_Voltage_Current;







