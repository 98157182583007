import React from "react";
import Config from '../../Constants/index'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'
import Axios from 'axios';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import { Redirect } from 'react-router-dom'
import moment from "moment";
import FileUploaderGraph from '../../Pages/Calibration/Component/FileUploaderGraph'
import Alert from "../Alert";

const RtRequestBacklogNav = (props) => {

  const visible = props.visible;
  const item = props.item;
  const filteredData = props.filteredData;
  const [alertDialog, setAlertDialog] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [uploadDialog, setUploadDialog] = React.useState(false);
  const [uploadDialogCloseButtonVisible, setUploadDialogCloseButtonVisible] = React.useState(true);
  const [messages, setMessages] = React.useState([]);
  const [loadingArea, setLoadingArea] = React.useState(false);
  const [isRedirecToEdit, setIsRedirecToEdit] = React.useState(false);
  const [isRedirectToCalibrationResult, setIsRedirectToCalibrationResult] = React.useState(false);
  const [isRedirectToCalibrationMasterEquipment, setisRedirectToCalibrationMasterEquipment] = React.useState(false);
  
  const today = new Date();
  const formattedToday = today.toISOString().split('T')[0];
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate()-1);
  const formattedYesterday = yesterday.toISOString().split('T')[0];

  const [dateOfTest, setDateOfTest] = React.useState(formattedYesterday);
  const [dateOfIssue, setDateOfIssue] = React.useState(formattedToday);

  const [vCustomerName, setVCustomerName] = React.useState('');
  const [certificateNumber, setCertificateNumber] = React.useState('');
  const [saveEnabled, setSaveEnabled] = React.useState(true);
  const [isGoingToAddResultPageDirectly, setIsGoingToAddResultPageDirectly] = React.useState(false);
  const [certOfCalRef, setCertOfCalRef] = React.useState(-1);


  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);



  

  const createYReportsFromBacklogHandler = () => {
    debugger;
    let messages = [];
    let messagesList = [];

    const selectedIds = filteredData.map(item => item.id);


    getIdToken(user).then((idToken) => {

      Axios.post(Config.API_URL + "RtRequestBacklog/CreateReportsFromBacklog",
        {
          Ids: selectedIds,
          RtCert: {
            dateOfTest: dateOfTest == '' ? '1900-01-01' : dateOfTest,
            dateOfIssue : dateOfIssue == ''? '1900-01-01' : dateOfIssue,
            performedUserRef: 75,
            typeOfEnergySource: "Y-RAY",
            kVCurie: "40.94",
            millAmperage: "N/A",
            focalPointSourceSize: "(1x3.5) mm",
            material: "X65 PSL2",
            scopeOfRTWork: "100%",
            rTTechnique: "DWSI",
            typeOfFilm: "D4 - KODAK",
            sFD: "90 cm",
            iQIPosition: "Film side",
            exposureTime: "25 min",
            filmProcessing: "N/A",
            density: "1.8-4.0 (X-ray) & 2.0-4.0 (Gamma Ray)",
            visibleWireNo: "11",
            sensitivityPercent: "2.0%",
            rtMasterEquipmentRef: 1,
            rtWorkLocationRef: 5,
            acceptanceCriteria: "API Standard 1104, 22nd Edition"
          }
        },
        {
          headers: {
            'Authorization': 'Bearer ' + idToken
          }
        }
      )
        .then(response => {
          //setDeleteDialog(false);
          props.onCreateReportsFromBacklog();
        })
        // .catch(error => {
        //   if (error.response && error.response.status == 400) {
        //     error.response.data.message.map(p => {
        //       messages.push(p);
        //     })
        //   }
        //   else {
        //     messages.push("Failed to connect to server")
        //   }

        //   setAlertDialog(true);
        //   setMessages(messages);
        // });

        .catch(error => {
          debugger;
          if (error.response && error.response.status == 400) {
              for (var key in error.response.data)
                  if (key.startsWith("message"))
                      messagesList.push(error.response.data[key]);
          }
          else {
              messagesList.push("Failed to connect to server")
          }
          setMessages(messagesList);
          setLoadingArea(false);
          setAlertDialog(true);
          setSaveEnabled(true);

      });

    });
  }

  const createXReportsFromBacklogHandler = () => {
    debugger;
    let messages = [];
    let messagesList = [];

    const selectedIds = filteredData.map(item => item.id);


    getIdToken(user).then((idToken) => {

      Axios.post(Config.API_URL + "RtRequestBacklog/CreateReportsFromBacklog",
        {
          Ids: selectedIds,
          RtCert: {
            dateOfTest: dateOfTest == '' ? '1900-01-01' : dateOfTest,
            dateOfIssue : dateOfIssue == ''? '1900-01-01' : dateOfIssue,
            performedUserRef: 75,
            typeOfEnergySource: "X-RAY",
            kVCurie: "220",
            millAmperage: "N/A",
            focalPointSourceSize: "(1x3.5) mm",
            material: "X65 PSL2",
            scopeOfRTWork: "100%",
            rTTechnique: "SWSI",
            typeOfFilm: "Image plate IPS D7 - Baker Hughes",
            sFD: "45 cm",
            iQIPosition: "Film side",
            exposureTime: "7 min",
            filmProcessing: "N/A",
            density: "1.8-4.0 (X-ray) & 2.0-4.0 ( Gama Ray)",
            visibleWireNo: "11",
            sensitivityPercent: "2.0%",
            rtMasterEquipmentRef: 1,
            rtWorkLocationRef: 5,
            acceptanceCriteria: "API Standard 1104, 22nd Edition"
          }
        },
        {
          headers: {
            'Authorization': 'Bearer ' + idToken
          }
        }
      )
        .then(response => {
          //setDeleteDialog(false);
          props.onCreateReportsFromBacklog();
        })
        // .catch(error => {
        //   if (error.response && error.response.status == 400) {
        //     error.response.data.message.map(p => {
        //       messages.push(p);
        //     })
        //   }
        //   else {
        //     messages.push("Failed to connect to server")
        //   }

        //   setAlertDialog(true);
        //   setMessages(messages);
        // });

        .catch(error => {
          debugger;
          if (error.response && error.response.status == 400) {
              for (var key in error.response.data)
                  if (key.startsWith("message"))
                      messagesList.push(error.response.data[key]);
          }
          else {
              messagesList.push("Failed to connect to server")
          }
          setMessages(messagesList);
          setLoadingArea(false);
          setAlertDialog(true);
          setSaveEnabled(true);

      });

    });
  }


  const changeStatus = (statusTitle) => {
    setLoadingArea(true);
    let messages = [];

    getIdToken(user).then((idToken) => {

      Axios.post(Config.API_URL + "CertificateOfCalibration/" + statusTitle,
        {
          id: item.id,
        },
        {
          headers: {
            'Authorization': 'Bearer ' + idToken
          }
        }
      )
        .then(response => {
          setDeleteDialog(false);
          props.onItemUpdate(response.data);
          setLoadingArea(false);
        })
        .catch(error => {
          if (error.response && error.response.status == 400) {
            error.response.data.message.map(p => {
              messages.push(p);
            })
          }
          else {
            messages.push("Failed to connect to server")
          }
          setLoadingArea(false);
          setAlertDialog(true);
          setMessages(messages);
        });
    });
  }

  if (!visible)
    return (<div></div>)

  if (isRedirecToEdit) {
    return <Redirect to={'/CertificateOfCalibration/Edit/' + item.id} />
  }

  if (isRedirectToCalibrationResult) {
    return <Redirect to={'/CalibrationResult' + item.vReportTemplateSimpleCode + '/' + item.id} />
  }

  if (isRedirectToCalibrationMasterEquipment) {
    return <Redirect to={'/CalibrationReport_MasterEquipment/' + item.id} />
  }

  const getDifference = (date1, date2) => {
    let diff = Math.abs(date2 - date1);
    return Math.ceil(diff / (1000 * 3600 * 24));
  }

  const hideDialogs = () => {
    setDeleteDialog(false);
    setAlertDialog(false);
    setUploadDialog(false);
  }

  const onUploadInit = () => setUploadDialogCloseButtonVisible(false);

  const onUploadSuccess = () => {
    setUploadDialogCloseButtonVisible(true);
  };

  const onUploadError = () => {
    setUploadDialogCloseButtonVisible(true);
  };

  //Get diftence item createdDate and now
  const getDifferenceDate = (date) => {
    let date1 = new Date(date);
    let date2 = new Date();
    return getDifference(date1, date2);
  }

  function processData(data) {
    // Assuming the data is already sorted
    const groups = [];
    let start = data[0];
    let last = start;
  
    for (let i = 1; i < data.length; i++) {
      const current = data[i];
      const currentNumber = parseInt(current.split('-')[1]);
      const lastNumber = parseInt(last.split('-')[1]);
  
      if (currentNumber !== lastNumber + 1) {
        // If the sequence breaks, push the range or single element
        groups.push(start === last ? start : `${start} ~ ${last}`);
        start = current;
      }
      last = current;
    }
  
    // Push the last range or element
    groups.push(start === last ? start : `${start} ~ ${last}`);
    return groups;
  }

  return (
    <div className="col-12 col-lg-3">
      <div className="card mb-3 mt-lg-0 mt-4 card-plain">

        {loadingArea &&
          <div className="card-body pb-0">
            <i class='fa fa-spinner fa-spin '></i> Loading...
          </div>
        }

        {!loadingArea &&
          <div className="card-body pb-0">
            <div className="row align-items-center mb-3">
              <div className="col-9">
                <h5 className="mb-1 text-gradient text-primary">
                  <a href="javascript:;">{item.certificateNumber}</a>
                </h5>
              </div>
              {/* <div className="col-3 text-end">
          <div className="dropstart">
            <a href="javascript:;" className="text-secondary" id="dropdownMarketingCard" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="material-icons text-xl">more_vert</i>
            </a>
            <ul className="dropdown-menu dropdown-menu-lg-start px-2 py-3" aria-labelledby="dropdownMarketingCard">
              <li><a className="dropdown-item border-radius-md" href="javascript:;">Edit Team</a></li>
              <li><a className="dropdown-item border-radius-md" href="javascript:;">Add Member</a></li>
              <li><a className="dropdown-item border-radius-md" href="javascript:;">See Details</a></li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li><a className="dropdown-item border-radius-md text-danger" href="javascript:;">Remove Team</a></li>
            </ul>
          </div>
        </div> */}
            </div>
            <p>{item.vEquipmentTitle}</p>
            <p class="mb-0"><b>{item.vEquipmentSerialNo}</b></p>
            <hr className="horizontal dark my-1" />


                                <div className="col-6">
                                    <div className="input-group input-group-static  my-3">
                                        <label>
                                            Date Of Test {dateOfTest}
                                        </label>
                                        <input type="date" class="form-control" name="dateOfTest" 
                                          onChange={(e) => setDateOfTest(e.target.value)}
                                          value={dateOfTest}
                                        />
                                    </div>
                                </div>

                            <div className="col-6">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Date Of Issue {dateOfIssue}
                                    </label>
                                    <input type="date" class="form-control" name="dateOfIssue" 
                                      onChange={ (e) => setDateOfIssue(e.target.value) }
                                      value={dateOfIssue}  
                                    />
                                </div>
                            </div>


{/* 
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Performed User
                                    </label>
                                    <UserLkp name="PerformedUserRef" 
                                    // onChange={onPerformedUserRefChange}
                                    //  value={performedUserRef} user={user} 
                                    // 
                                     />
                                </div>
                            </div> */}


            <div className="d-flex">
              <button className="btn bg-gradient-dark px-3 mb-2 active" data-class="bg-gradient-dark" 
              onClick={createXReportsFromBacklogHandler}>
              {/* <span role="presentation" class="k-icon k-i-print"></span>  */}
                Make X Reports
              </button>

              <button className="btn bg-gradient-dark px-3 mb-2 active" data-class="bg-gradient-dark" 
              onClick={createYReportsFromBacklogHandler}>
              {/* <span role="presentation" class="k-icon k-i-print"></span>  */}
                Make Y Reports
              </button>

              {/* <a className="btn bg-gradient-dark px-3 mb-2 active" data-class="bg-gradient-dark" href={Config.API_URL
                + "report/cert/?certId=" + item.id + "&guid=" + item.reportGUID} target="_blank" >
                <span role="presentation" class="k-icon k-i-print"></span>
                {item.vStatusTitle == "Approved" || item.vStatusTitle == "Archive" ? " Print" : " Print Preview"}
              </a> */}

            </div>


            <ul className="list-unstyled mx-auto">
              <li className="d-flex">
                <div>
                  {filteredData.map((item, index) => (
                    <span key={item.id}>
                      {item.weldNo}{index < filteredData.length - 1 ? ', ' : ''}
                    </span>
                  ))}
                </div>
              </li>
              <li>
                <hr className="horizontal dark" />
              </li>
            </ul>

            
          </div>}
      </div>

      {
        alertDialog &&
        <Dialog
          title="Alert"
          onClose={() => { setAlertDialog(false) }} >

          {messages.length > 0 &&
            <ul>
              {messages.map((message) => {
                return (
                  <li>{message}</li>
                )
              })}
            </ul>
          }

          <DialogActionsBar>
            <button
              className="k-button k-primary"
              onClick={() => { setAlertDialog(false) }}
            >
              Close
            </button>
          </DialogActionsBar>
        </Dialog>
      }

      {uploadDialog &&
        <Dialog
          title="Upload"
          onClose={hideDialogs}
          closeIcon={uploadDialogCloseButtonVisible}
        >
          <div>
            <FileUploaderGraph
              onUploadSuccess={onUploadSuccess}
              onUploadError={onUploadError}
              onUploadInit={onUploadInit}
              certOfCalref={item.id}
              user={props.user} />
          </div>

          <DialogActionsBar>
            {/* <button
                                                    className="k-button k-primary"
                                                    onClick={delete}
                                                >
                                                    Yes
                                                </button> */}
            {/* <button
                                                className="k-button"
                                                onClick={hideDialogs}

                                            >
                                                No
                                            </button> */}
          </DialogActionsBar>
        </Dialog>
      }

{/* {messages.length > 0 &&
                                <Alert messages={messages}></Alert>} */}

    </div>

  )


}

export default RtRequestBacklogNav;