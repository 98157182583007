import React, { Component } from 'react'
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button } from '@progress/kendo-react-buttons'
import Axios from 'axios';
import Config from '../Constants'
import { Redirect, Link } from 'react-router-dom'
import t from '../Lang/Index'

export default class EquipmentUploadImage1 extends Component {

  constructor(props) {
    super(props)
    this.upload = this.upload.bind(this);
    this._crop = this._crop.bind(this);
    this.change = this.change.bind(this);
    this.previous = this.previous.bind(this);

    let id = this.props.match.params.Id;
    this.state = {
      ImageData: '',
      ImageUrl: Config.API_URL + 'equipment/GetImage1/?id=' + id,
      Id: id,
      isRedirectToEquipmentAdd: false
    }
  }

  _crop() {
    // image in dataUrl
    //this.setState({ImageData:this.refs.cropper.getCroppedCanvas().toDataURL()
    // })
    // console.log(this.refs.cropper.getCroppedCanvas().toDataURL());
  }

  upload() {
    Axios.post(Config.API_URL + 'equipment/uploadImage1',
      {
        Id: this.state.Id, Base64image: this.refs.cropper
          .getCroppedCanvas({ width: Config.UPLOAD_IMAGE1_WITDTH, height: Config.UPLOAD_IMAGE1_HEIGHT }).toDataURL()
      },
      { headers: { token: window.localStorage.getItem("apiToken") } }
    )
      .then(response => {
        this.setState({ isRedirectToUploadThumb1: true });
        window.console.log(response);
      })
  }

  change(e) {

    var file = e.target.files[0];
    // var files = this.files;
    // var file;
    /*
        if (cropper && files && files.length) {
          //file = files[0];
    
          if (/^image\/\w+/.test(file.type)) {
            uploadedImageType = file.type;
            uploadedImageName = file.name;
    
            if (uploadedImageURL) {
              URL.revokeObjectURL(uploadedImageURL);
            }
    
            image.src = uploadedImageURL = URL.createObjectURL(file);
            cropper.destroy();
            cropper = new Cropper(image, options);
            inputImage.value = null;
          } else {
            window.alert('Please choose an image file.');
          }
        }*/


    let path = URL.createObjectURL(file);

    this.setState({ ImageUrl: path });
  }

  previous() {
    this.setState({ isRedirectToEquipmentAdd: true });
  }

  render() {
    if (this.state.isRedirectToEquipmentAdd)
      return (<Redirect to={"/Equipment/Edit/" + this.state.Id} />)

    if (this.state.isRedirectToUploadThumb1)
      return (<Redirect to={"/Equipment/UploadThumb1/" + this.state.Id} />)

    return (
      <div>
        <div id="page-wrapper">
          <div className="row">
            <div className="col-lg-12">

              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                  <li className="breadcrumb-item"><Link to="/Equipment/">{t.uucEucDuc}</Link></li>
                  {/* <li className="breadcrumb-item"><Link to={"/Equipment/edit/" + this.state.Id}>ویرایش خبر</Link></li> */}
                  <li className="breadcrumb-item active" aria-current="page">
                    Add {t.uucEucDuc}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Upload Image1
                  </li>
                </ol>
              </nav>
              <Cropper
                ref='cropper'
                src={this.state.ImageUrl}
                style={{ height: 400, width: '100%' }}
                // Cropper.js options
                aspectRatio={Config.UPLOAD_IMAGE1_WITDTH / Config.UPLOAD_IMAGE1_HEIGHT}
                width={100}
                guides={false}
                crop={this._crop.bind(this)}
                autoCrop={true}
                autoCropArea={1}
              />

              <p>Standard Size {Config.UPLOAD_IMAGE1_WITDTH} * {Config.UPLOAD_IMAGE1_HEIGHT}</p>
              <input id="fileUpload" type="file" onChange={this.change} ></input>
              <div class="from-group" style={{ 'padding-top': '10px' }}>
                <button type="button" class="btn btn-primary" onClick={this.upload}>
                  Next
                </button>
                <button type="button" class="btn btn-secondary" style={{ 'margin-right': '5px' }} onClick={this.previous} disabled>
                  Previous
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
