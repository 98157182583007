import * as React from "react"
import * as ReactDOM from "react-dom"
import Config from "../../Constants/index";
import { getIdToken } from "firebase/auth";

const UnitOfMeasurmentLkp = (props) => {

    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        getIdToken(props.user).then((idToken) => {
            fetch(Config.API_URL + "UnitOfMeasurment/GetUnitOfMeasurmentList/?timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => response.json())
                .then(json => { 
                    setData(json);
                });
        });
    }

    const onChange = (event) => {
        props.onChange(event);
    }

    return (
        <select class="form-control" id="exampleSelect1" name={props.name}
            onChange={onChange} disabled={!(props.calibrationTypeRef > 0)} value={props.selectedValue} >
            <option value={0}  >-</option>


            {
                data
                    .filter(function (unitOfMeasurment) {
                        return unitOfMeasurment.calibrationTypeRef == props.calibrationTypeRef
                    })
                    .map(function (item, index) {
                        return <option value={item.id} key={index}>{item.title}
                        </option>
                    })
            }
        </select>
    )
}

export default UnitOfMeasurmentLkp;