import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';

export default function cellButton(onSelect,title) {
  return class extends GridCell {

    render() {
      return (
        <td>
          <button
            className="k-button"
            onClick={() => { onSelect(this.props.dataItem); }}
          >
            {title}
          </button>          
        </td>
      );
    }
  };
}