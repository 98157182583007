import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import cellWithEditing from '../CellWithEditing'
import { Button } from '@progress/kendo-react-buttons'
import Config from '../../Constants/index'
import { Dialog } from '@progress/kendo-react-dialogs'
import { Redirect, Link } from 'react-router-dom'
import axios from 'axios';

export default class MatchUser extends Component {

    constructor() {
        super();
        this.state = {
            matchs: [],
            loading: true,
            isRedirectToMatchOrderInsert: false
        };
        this.fetchData = this.fetchData.bind(this);
        this.PAGE_TITLE = "مسابقات";
        this.select = this.select.bind(this);
    }

    fetchData() {
        this.setState({ loading: true });
        fetch(Config.API_URL + "match/GetMatchUserList/?timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => response.json())
            .then(json => {
                this.setState({ matchs: json, loading: false });
            });
    }

    componentWillMount() {
        this.fetchData()
    }

    componentDidMount() {
        document.title = this.PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }

    select(dataItem) {
        this.setState({ matchId: dataItem.id })
        this.setState({ isRedirectToMatchOrderInsert: true })
    }

    render() {

        if (this.state.isRedirectToMatchOrderInsert) {
            return <Redirect to={'/MatchOrder/Add/&matchRef=' + this.state.matchId} />
        }

        return (
            <div>
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">صفحه اصلی</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{this.PAGE_TITLE}</li>
                                </ol>
                            </nav>

                            {this.state.matchs.length == 0 &&
                                <div className="alert alert-dismissable alert-warning">
                                    موردی برای نمایش یافت نگردید
                                </div>
                            }

                            {this.state.matchs.length > 0 &&
                                <Grid style={{ height: '400px', width: '100' }} data={this.state.matchs}  >
                                    <Column
                                        cell={cellWithEditing(this.select, 'خرید')}
                                        width="100px" />
                                    <Column field="id" title="کد مسابقه" width="120px" />
                                    <Column field="title" title="عنوان" width="120px" />
                                    <Column field="price" title="قیمت-تومان" width="100px" />
                                    <Column field="genderTitle" title="جنسیت" width="100px" />
                                    <Column field="matchAgeTypeTitle" title="رده ی سنی" width="220px" />
                                    <Column field="matchLevelTypeTitle" title="دسته" width="180px" filter="text" />
                                    }
                                </Grid>
                            }
                            {
                                this.state.loading && <Dialog>
                                    <i class='fa fa-spinner fa-spin '></i> Loading...
                                </Dialog>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
