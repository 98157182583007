import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import cellWithEditing from '../Component/cellEditDeleteButton'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import Config from '../Constants/index';
import axios from 'axios'
import { Redirect, Link } from 'react-router-dom'
import CellDeleteButton from '../Component/CellDeleteButton'
import cellButton from '../Component/cellButton';

export default class Tournament extends Component {

    constructor() {
        super();

        this.state = {
            data: [],
            matchOrderInChangeStatus: undefined,
            selectedStatus: 1,
            isRedirectToMatchMemberAdmin: false,
            deleteDialog: false,
            saveResultDialog: false
        };

        this.fetchData = this.fetchData.bind(this)
        this.insert = this.insert.bind(this);
        this.edit = this.edit.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.delete = this.delete.bind(this);
        this.onChange = this.onChange.bind(this);
        this.saveResult = this.saveResult.bind(this);

    }

    fetchData() {
        this.setState({ loading: true });

        axios.get(Config.API_URL + "tournament/GetTournamentListAdmin/?timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                this.setState({ data: response.data, loading: false });
            });
    }

    saveResult() {
        this.setState({ loading: true });

        axios.post(Config.API_URL + "Tournament/Update",
            {
                Id: this.state.selectedRow.id,
                ResultA : this.state.ResultA,
                ResultB : this.state.ResultB,
            },
            { headers : { token: window.localStorage.getItem("apiToken") } }
        )
            .then(response => {

                if (response.status == 200) {
                    this.setState({ loading: false, saveResultDialog: false });
                    this.fetchData();
                }

            }).catch(response => {
                debugger;
            });
    }

    componentWillMount() {
        this.fetchData();
    }

    report(dataItem) {
        //this.setState({ matchOrderInChangeStatus: this.cloneProduct(dataItem) });
        debugger;
        console.log(dataItem)
    }

    changeStatus() {

        const selected = this.state.data.filter(item => item.selected);

        if (selected.length == 0) {
            this.setState({ visible: true });
            return;
        }

        this.setState({ matchOrderInChangeStatus: true });
    }

    deleteConfirm(dataItem) {
        this.setState({
            deleteTitle: " کد " + dataItem.id + " - " + (dataItem.teamA ? dataItem.teamA:"") + "-" + (dataItem.teamB ? dataItem.teamB:""),
            deleteDialog: true,
            selectedRow: dataItem
        });
    }

    delete() {
        debugger;
        this.setState({ loading: true });

        axios.post(Config.API_URL + "tournament/Delete",
            {
                Id: this.state.selectedRow.id
            },

            { headers: { token: window.localStorage.getItem("apiToken") } }
        )
            .then(response => {

                if (response.status == 200) {
                    this.setState({ loading: false, deleteDialog: false });
                    this.fetchData();
                }

            }).catch(response => {
                debugger;
                if (response.status == 403) {

                }

            });

    }


    edit(dataItem) {
        this.setState({
            Id: dataItem.id,
            isInRedirectToEdit: true
        });
    }

    insert = () => {
        this.setState({ isInRedirectToInsert: true })
    }

    render() {

        if (this.state.isInRedirectToInsert)
            return (
                <Redirect to={"/Tournament/Add"} />
            )

        if (this.state.isInRedirectToEdit) {
            return (
                <Redirect to={"/Tournament/Edit/" + this.state.Id} />
            )
        }

        return (

            <div>
                <div id="page-wrapper" >
                    <div className="row">
                        <div className="col-lg-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">صفحه اصلی</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">برنامه / نتایج مسابقات</li>
                                </ol>
                            </nav>

                            {/* /.col-lg-12 */}

                            <Grid style={{ height: '600px' }} data={this.state.data}
                                onRowClick={this.rowClick}
                            >

                                <GridToolbar>
                                    <Button style={{ 'margin-left': '5px', float:"right" }}
                                        onClick={this.insert} >درج جدید</Button>
                                </GridToolbar>



                                <Column
                                    cell={cellButton((dataItem) => {
                                        this.setState({
                                            saveResultDialog: true,
                                            teamA: dataItem.teamA,
                                            teamB: dataItem.teamB,
                                            ResultA : dataItem.resultA,
                                            ResultB : dataItem.resultB,
                                            selectedRow: dataItem
                                        })
                                    }, "اعلام نتایج")}
                                    width="120px"
                                />

                                <Column
                                    cell={cellWithEditing(this.edit, this.deleteConfirm)}
                                    width="100px"
                                />


                                <Column field="id" title="کد" width="80px" />
                                <Column field="teamA" title="تیم الف" width="150px" />
                                <Column field="teamB" title="تیم ب" width="150px" />
                                <Column field="genderTitle" title="جنسیت" width="80px" />
                                <Column field="resultA" title="نتیجه A" width="80px" />
                                <Column field="resultB" title="نتیجه B" width="80px" />
                                <Column field="teamDate" title="تاریخ برگزاری" width="120px" />
                                <Column field="dayOfWeak" title="روز مسابقه" width="120px" />
                                <Column field="matchTime" title="ساعت مسابقه" width="120px" />
                                <Column field="location" title="مکان برگزاری" width="180px" />
                                <Column field="ageTypeTitle" title="رده سنی" width="150px" />
                                <Column field="levelGroup" title="مرحله / گروه" width="120px" />

                            </Grid>

                        </div>
                        {this.state.deleteDialog &&
                            <Dialog
                                title="حذف"
                                onClose={this.cancelDelete}
                            >
                                <div>
                                    آیا برای حذف {this.state.deleteTitle} مطمئن هستید؟
                                </div>

                                <DialogActionsBar>
                                    <button
                                        className="k-button k-primary"
                                        onClick={this.delete}
                                    >
                                        تایید
                        </button>
                                    <button
                                        className="k-button"
                                        onClick={() => {
                                            this.setState({ deleteDialog: false });
                                        }}
                                    >
                                        انصراف
                        </button>
                                </DialogActionsBar>
                            </Dialog>
                        }

                        {
                            this.state.loading && <Dialog>
                                <i class='fa fa-spinner fa-spin '></i> Loading...
                                </Dialog>
                        }


                        {this.state.saveResultDialog &&
                            <Dialog
                                title="اعلام نتایج"
                                onClose={() => { this.setState({ saveResultDialog: false }) }}
                                width={250}
                            >
                                <div>
                                    <form role="form">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                نتیجه A - {this.state.teamA}
                                            </label>
                                            <input type="number" class="form-control" name="ResultA"
                                                onChange={this.onChange} value={this.state.ResultA} min={0} max={300} />
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                نتیجه B - {this.state.teamB}
                                            </label>
                                            <input type="number" class="form-control" name="ResultB"
                                                onChange={this.onChange} value={this.state.ResultB} min={0} max={300} />
                                        </div>
                                    </form>
                                </div>

                                <DialogActionsBar>
                                    <button
                                        className="k-button k-primary"
                                        onClick={this.saveResult}
                                    >
                                        تایید
                        </button>
                                    <button
                                        className="k-button"
                                        onClick={() => { this.setState({ saveResultDialog: false }) }}
                                    >
                                        انصراف
                        </button>
                                </DialogActionsBar>
                            </Dialog>}

                    </div>
                </div>
            </div>
        )
    }

    toggleDialog() {
        this.setState({
            visible: !this.state.visible
        });
    }

    cloneProduct(product) {
        return Object.assign({}, product);
    }

    newProduct(source) {
        const newProduct = {
            ProductID: this.generateId(),
            ProductName: '',
            UnitsInStock: 0,
            Discontinued: false
        };

        return Object.assign(newProduct, source);
    }

    generateId() {
        let id = 1;
        this.state.products.forEach(p => { id = Math.max((p.ProductID || 0) + 1, id); });
        return id;
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }


}
