import * as React from "react"
import * as ReactDOM from "react-dom"
import Config from "../../../Constants/index";
import { getIdToken } from "firebase/auth";
import Select from 'react-select';
import Axios from 'axios';

const MasterEquipmentSpekSelectLkp = (props) => {

    const [data, setData] = React.useState([]);
    const [inputValue, setInputValue] = React.useState("");
    const [selectedOption, setSelectedOption] = React.useState("");
    const [calibrationTypeRef, setCalibrationTypeRef] = React.useState(props.calibrationTypeRef);
    const [isLoading,setIsLoading] = React.useState(true);

    React.useEffect(() => {
        setSelectedOption(props.defaultValue);
    }, [props.defaultValue]);

    React.useEffect(() => {        
       fetchData();
    }, []);

    const fetchData = () => {
        debugger;
        setIsLoading(true);
        getIdToken(props.user).then((idToken) => {
            Axios.get(Config.API_URL + "MasterEquipmentSpec/GetMasterEquipmentSpecAndMasterEquipmentList/?F_CalibrationTypeRef=" +
                calibrationTypeRef + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                }
            )
                .then(response => {

                    var list = response.data.map(item => {
                        return {
                            value: item.id,
                            label: item.v_MasterEquipmentManufacture + " (" + item.v_MasterEquipmentSerialNo + ") [" +
                                item.rangeMin + " ~ " + item.rangeMax + " " + item.vUmTitle + "] " + item.v_MasterEquipmentCertificateNo,
                        }
                    });
                    debugger;

                    setData(list)
                    setIsLoading(false);


                }).catch(error => {
                    console.log(error);
                    setIsLoading(false);
                })
        });

    }

    const handleInputChange = (newValue) => {
        setInputValue(newValue)
    };

    const selectedOptionHandler = (selectedValue) => {
        setSelectedOption(selectedValue)
        props.onChange(selectedValue);
    }

    return (
        <Select className="form-control"
            value={selectedOption}
            onChange={selectedOptionHandler}
            onInputChange={handleInputChange}
            options={data}
            controlShouldRenderValue={true}                        
            isClearable={true}
            isLoading ={isLoading}
        />
    )
}

export default MasterEquipmentSpekSelectLkp;


