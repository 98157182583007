//TODO react component for comments
import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import Config from '../../Constants';
import { getIdToken } from 'firebase/auth';

const AddCommentsForm = (props) => {

    const entityId = props.entityId;
    const user = props.user;
    const [memo, setMemo] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const onSent = props.onSent;

    const handleSubmit = (e) => {

        if (memo == "")
            return;

        let messagesList = [];

        setIsLoading(true);

        getIdToken(user).then((idToken) => {

            axios.post(Config.API_URL + "CertHistory/AddComment",
                {
                    Memo: memo,
                    CertRef: entityId,
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    setIsLoading(false);
                    setMemo("");
                    onSent();
                    //Refresh the list of messages
                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messagesList.push(p);
                        })
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setIsLoading(false);
                    setMessages(messagesList);
                });
        });
    }

    return (<div className="input-group input-group-outline d-flex">
        <label className="form-label"></label>
        <input
            type="text"
            id="memo"
            className="form-control form-control-lg"
            value={memo}
            onChange={e => setMemo(e.target.value)}
        />
        <button className="btn bg-gradient-primary mb-0" onClick={handleSubmit} disabled={isLoading}>
            <i className="material-icons">send</i>
        </button>

        {messages.length > 0 &&
            <div className="input-group input-group-outline d-flex">
                {messages.map(p => <div className="alert alert-danger" role="alert">{p}</div>)}
            </div>
        }

    </div>)

}

export default AddCommentsForm;