import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';

export default function cellActiveToggle(select) {
    return class extends GridCell {
        render() {
            return (
                <td>
                    <button
                        className="k-button"
                        onClick={() => { select(this.props.dataItem); }}
                        
                    >
                        Enable / Disable
                        </button>
                </td>
            );
        }
    };
}