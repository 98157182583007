import * as React from "react"
import * as ReactDOM from "react-dom"
import Config from "../../../Constants/index";
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'

const MasterEquipmentSpecLkp = (props) => {

    const [data, setData] = React.useState([]);
    const masterEquipmentRef = props.masterEquipmentRef;
    const rangeUmRef = props.rangeUmRef;

    const auth = getAuth();
    const [user, loadingAuth, error] = useAuthState(auth);

    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        getIdToken(user).then((idToken) => {
            fetch(Config.API_URL + "MasterEquipmentSpec/GetMasterEquipmentSpecList/?timestamp=" + new Date().getTime() +
                "&F_RangeUmRef=" + rangeUmRef,
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    setData(json);
                });
        });

    }

    const onChange = (e) => {
        let id = e.target.value;
        let title = e.target.selectedOptions[0].innerText;
        props.onChange(id, title);
    }

    return (
        <div>
            <select class="form-control" id="exampleSelect1" name={props.name}
                onChange={onChange} value={props.selectedValue} disabled={masterEquipmentRef < 1} >
                <option value={0} >-</option>
                {
                    data.filter(function (item) {
                        return item.masterEquipmentRef == masterEquipmentRef;
                    })
                        .map(function (item, index) {
                            return <option value={item.id}>{item.title + " | min:" + item.rangeMin + " max:" + item.rangeMax + " " + item.vUmTitle}</option>
                        })
                }
            </select>
        </div>

    )

}

export default MasterEquipmentSpecLkp;

