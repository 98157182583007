import React from 'react'
import Creatable, { useCreatable } from 'react-select/creatable';


const PsvManufacurerLkp = (props) => {

  const options = [
    { value: 'Atilim Makina', label: 'am: Atilim Makina' },  
    { value: 'Farris Engineering', label: 'fe: Farris Engineering' },
    { value: 'COI Technology S.r.l', label: 'coi: COI Technology S.r.l' },
    { value: 'SEETRU-BRISTOL', label: 'sb: SEETRU-BRISTOL' },
    { value: 'KLEEV', label: 'kl: KLEEV' },
    { value: 'Omran Gas Puya Co.', label: 'ogp: Omran Gas Puya Co.' },   
    { value: 'Fisher Controls Co.', label: 'fc: Fisher Controls Co.' },
    { value: 'Kunkle Valves', label: 'kv: Kunkle Valves' },    
    { value: 'Anderson Greenwood', label: 'ag: Anderson Greenwood' },    
    { value: 'Dresser', label: 'dr: Dresser' },    
    { value: 'Groth Corporation', label: 'gc: Groth Corporation' },    
    { value: 'Hydroseal', label: 'hs: Hydroseal' },    
    { value: 'N/A', label: 'na: N/A' }
  ]  

  return (
    <Creatable options={options} 
      className="form-control" 
      onChange={props.onChange} 
      value= {props.selectedValue}
      />
  )
}

export default PsvManufacurerLkp