import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';

export default function CellBtnIcon(select, icon,text = "") {
    return class extends GridCell {
        render() {
            if (!this.props.dataItem.isManual)
            return <></>

            return (
                <td>
                   <button class="k-button k-flat k-button-icontext" onClick={() => { select(this.props.dataItem); }}>

                        <i class={"fas fa-" + (icon ? icon : "plus")} style={{"margin-right": "5px"}}></i>
                        {text}
                        </button>                  
                </td>


            );
        }
    };
}