import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../../Component/Alert'
import CalibrationTypeLkp from '../../Component/Calibration/CalibrationTypeLkp';
import UserDefaultCustomer from '../../Component/Calibration/UserDefaultCustomer';
import UnitOfMeasurmentLkp from '../../Component/Calibration/UnitOfMeasurmentLkp';
import t from '../../Lang/Index'
import ReportTemplateLkp from '../../Component/Calibration/ReportTemplateLkp';
import CalibrationProcedureLkp from '../../Component/Calibration/CalibrationProcedureLkp';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import PsvManufacurerLkp from '../../Component/Calibration/PsvManufacurerLkp';
import PsvValveTypeLkp from '../../Component/Calibration/PsvValveTypeLkp';
import PsvBodyMaterialLkp from '../../Component/Calibration/PsvBodyMaterialLkp';
import PsvDesingLkp from '../../Component/Calibration/PsvDesingLkp';
import PsvTestMediumLkp from '../../Component/Calibration/PsvTestMediumLkp';

const EquipmentPsv_Add = (props) => {

    const PAGE_TITLE = "New " + t.psvUuc;
    const DEFUALT_CONTROLLER = "Equipment";
    const DEFAULT_CALTYPE_REF = 18;

    const [id, setId] = React.useState(props.match.params.id);
    const isEdit = props.match.params.id > 0;
    
    const [description, setDescription] = React.useState('');
    const [model_Type, setModel_Type] = React.useState('');
    const [serialNo, setSerialNo] = React.useState('');
    const [tagNo,setTagNo] = React.useState('');
    const [location,setLocation] = React.useState('');    
    const [customerRef, setCustomerRef] = React.useState(-1);
    const [unitMeasurmentRef, setUnitMeasurmentRef] = React.useState(-1);
    const [cECSN, setCECSN] = React.useState('');    
    const [messages, setMessages] = React.useState([]);    
    const [isGoingToAddNewReportDirectly, setIsGoingToAddNewReportDirectly] = React.useState(false)
    const [isRedirectToEquipment, setIsRedirectToEquipment] = React.useState(false)
    const [manufacture, setManufacture] = React.useState('');
    const [valveType,setValveType] = React.useState('');
    const [bodyMaterial,setBodyMaterial] = React.useState('');
    const [design,setDesign] = React.useState('');
    const [bodySize,setBodySize] = React.useState('');
    const [inletSize,setInletSize] = React.useState('');
    const [outletSize,setOutletSize] = React.useState('');
    const [testMedium,setTestMedium] = React.useState('');


    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);


    const onUnitOfMeasurmentChange = (e) => {
        setUnitMeasurmentRef(e.target.value);
    }

    const onManufactureChange = (e) => setManufacture(e.value);
    const onModel_TypeChange = (e) => setModel_Type(e.target.value);
    const onSerialNoChnage = (e) => setSerialNo(e.target.value);
    const onTagNoChnage = (e) => setTagNo(e.target.value);
    const onLocationChange = (e) => setLocation(e.target.value);
    const onValveTypeChange = (e) => setValveType(e.value);
    const onBodyMaterialChange = (e) => setBodyMaterial(e.value);
    const onDesignChange = (e) => setDesign(e.value);
    const onBodySizeChange = (e) => setBodySize(e.target.value);
    const onInletSizeChange = (e) => setInletSize(e.target.value);
    const onOutletSizeChange = (e) => setOutletSize(e.target.value);
    const onTestMediumChange = (e) => setTestMedium(e.value);
    
    React.useEffect(() => {
        if (isEdit)
            fetchData();

    }, []);

    const fetchData = () => {
        debugger;
        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + "Equipment/GetEntityRowPsv/?id=" + id + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    const item = response.data;
                    debugger;
                    setDescription(item.description);
                    setManufacture(item.manufacture);
                    setModel_Type(item.model_Type);
                    setSerialNo(item.serialNo);
                    setTagNo(item.tagNo);
                    setLocation(item.location);
                    setUnitMeasurmentRef(item.unitMeasurmentRef);
                    setValveType(item.valveType);
                    setBodyMaterial(item.bodyMaterial);
                    setDesign(item.design);
                    setBodySize(item.bodySize);
                    setInletSize(item.inletsize);
                    setOutletSize(item.outletsize);
                    setTestMedium(item.testMedium);
                });
        });
    }
    
    const cancel = () => {
        setIsRedirectToEquipment(true);
    }


    const addAndGoToNewReport = () => {
        setIsGoingToAddNewReportDirectly(true);
        add();
    }

    const addEdit = () => {
        debugger;
        if (isEdit)
            edit();
        else
            add();
    }

    const add = () => {
        let messagesList = [];
        let self = this;

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL +  DEFUALT_CONTROLLER + "/addPsv",
                {
                    CustomerRef: customerRef,
                    Description: description,
                    Manufacture: manufacture,
                    Model_Type: model_Type,
                    SerialNo: serialNo,
                    TagNo: tagNo,
                    Location: location,
                    UnitMeasurmentRef: unitMeasurmentRef,
                    ValveType : valveType,
                    BodyMaterial : bodyMaterial,
                    Design : design,
                    BodySize : bodySize,
                    INLETSIZE : inletSize,
                    OUTLETSIZE : outletSize,
                    TestMedium : testMedium                    
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let _id = response.data;
                    setId(_id)
                    //TO DO redirecto Calibration Report
                    setIsRedirectToEquipment(true);
                })
                .catch(error => {
                    debugger;
                    if (error.response && error.response.status == 400) {
                        for (var key in error.response.data) 
                            if (key.startsWith("message")) 
                                messagesList.push(error.response.data[key]);                                                   
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setMessages(messagesList);
                });
        });
    }

    const edit = () => {
        let messagesList = [];

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "Equipment/editPsv",
                {
                    id: id,                    
                    Description: description,
                    Manufacture: manufacture,
                    Model_Type: model_Type,
                    SerialNo: serialNo,
                    TagNo: tagNo,
                    Location: location,
                    UnitMeasurmentRef: unitMeasurmentRef,                    
                    ValveType : valveType,
                    BodyMaterial : bodyMaterial,
                    Design : design,
                    BodySize : bodySize,
                    INLETSIZE : inletSize,
                    OUTLETSIZE : outletSize,
                    TestMedium : testMedium                    
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let _id = response.data;
                    setId(_id)
                    //TO DO redirecto Calibration Report
                    setIsRedirectToEquipment(true);
                })
                .catch(error => {
                    debugger;
                    if (error.response && error.response.status == 400) {
                        for (var key in error.response.data) 
                            if (key.startsWith("message")) 
                                messagesList.push(error.response.data[key]);                                                   
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setMessages(messagesList);
                });
        });

      
    }

    const onCustomerBind = (id) => setCustomerRef(id);


    if (isRedirectToEquipment && isGoingToAddNewReportDirectly)
        return <Redirect to={"/CertOfCalPsv/Add/&equipmentRef=" + id}></Redirect>

    if (isRedirectToEquipment) {
        return <Redirect to={"/EquipmentPsv/"}></Redirect>
    }


    return (

        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-9 col-12 mx-auto position-relative">
                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fa fa-wrench fa-fw" aria-hidden="true"></i>
                            </div>
                            <h6 className="mb-0">{PAGE_TITLE}</h6>
                        </div> 
                        <div className="card-body pt-2">
                            <div class="row">
                                {isEdit == true &&
                                    <div className="form-group">
                                        <label>
                                            {t.cecId}
                                        </label>
                                        <p className="form-text text-muted ms-1">
                                            {cECSN}
                                        </p>
                                    </div>
                                }
                            </div>
                            <div class="row">
                                <div className="form-group">
                                    <label>
                                        Customer Name
                                    </label>
                                    <UserDefaultCustomer onBind={onCustomerBind} name="CustomerRef" user={user} />
                                </div>
                            </div>

                            <div class="row">                                
                                <div class="col-6">
                                    <div className="input-group input-group-static  my-3">
                                        <label for="exampleInputEmail">{t.unitOfMeasurment}</label>
                                        <label for="exampleInputEmail">{unitMeasurmentRef}</label>

                                        <UnitOfMeasurmentLkp name="UnitMeasurmentRef"                                        
                                            calibrationTypeRef={DEFAULT_CALTYPE_REF}
                                            onChange={onUnitOfMeasurmentChange}
                                            selectedValue={unitMeasurmentRef}
                                            user={user} />
                                    </div>
                                </div>
                            </div>   
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Manufacture {manufacture}
                                    </label>
                                    <PsvManufacurerLkp 
                                        onChange={onManufactureChange}                                     
                                        selectedValue={{ value: manufacture , label: manufacture }}>
                                    </PsvManufacurerLkp>                                    
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Model No
                                    </label>
                                    <input type="text" class="form-control" name="model_Type" onChange={onModel_TypeChange} value={model_Type} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Serial No
                                    </label>
                                    <input type="text" class="form-control" name="serialNo" onChange={onSerialNoChnage} value={serialNo} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Tag No
                                    </label>
                                    <input type="text" class="form-control" name="tagNo" onChange={onTagNoChnage} value={tagNo} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Location
                                    </label>
                                    <input type="text" class="form-control" name="location" onChange={onLocationChange} value={location} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Valve Type
                                    </label>
                                    <PsvValveTypeLkp onChange={onValveTypeChange} 
                                        selectedValue={{ value: valveType , label: valveType }}></PsvValveTypeLkp>                                    
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                    Body Material
                                    </label>
                                    <PsvBodyMaterialLkp onChange={onBodyMaterialChange}
                                        selectedValue={{ value: bodyMaterial , label: bodyMaterial }}></PsvBodyMaterialLkp>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                    Design
                                    </label>
                                    <PsvDesingLkp onChange={onDesignChange}
                                        selectedValue={{ value: design , label: design }}></PsvDesingLkp>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                    Body Size
                                    </label>
                                    <input type="text" class="form-control" name="bodySize" onChange={onBodySizeChange} value={bodySize} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                    INLET SIZE
                                    </label>
                                    <input type="text" class="form-control" name="inletSize" onChange={onInletSizeChange} value={inletSize} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                    OUTLET SIZE
                                    </label>
                                    <input type="text" class="form-control" name="outletSize" onChange={onOutletSizeChange} value={outletSize} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                    Test Medium
                                    </label>
                                    <PsvTestMediumLkp onChange={onTestMediumChange} 
                                        selectedValue={{ value: testMedium , label: testMedium }}></PsvTestMediumLkp>
                                    
                                </div>
                            </div>
                            


                            {messages.length > 0 &&
                                <Alert messages={messages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={cancel}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={addEdit}>
                                    Save
                                </button>
                                { !isEdit &&
                                <button type="button" class="btn btn-primary m-0 ms-2" onClick={addAndGoToNewReport}>
                                    Save & Add new report
                                </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >

    )

}

export default EquipmentPsv_Add;