import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../../Component/Alert'
import CalibrationTypeLkp from '../../Component/Calibration/CalibrationTypeLkp';
import UserDefaultCustomer from '../../Component/Calibration/UserDefaultCustomer';
import UnitOfMeasurmentLkp from '../../Component/Calibration/UnitOfMeasurmentLkp';
import t from '../../Lang/Index'
import ReportTemplateLkp from '../../Component/Calibration/ReportTemplateLkp';
import CalibrationProcedureLkp from '../../Component/Calibration/CalibrationProcedureLkp';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
 
const Mut_Add = (props) => {

    const PAGE_TITLE = "New UUC-EUC-DUC";
    const [id, setId] = React.useState(props.match.params.id);
    const isEdit = props.match.params.id > 0;

    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [model_Type, setModel_Type] = React.useState('');
    const [serialNo, setSerialNo] = React.useState('');
    const [tagNo,setTagNo] = React.useState('');
    const [location,setLocation] = React.useState('');
    const [short, setShort] = React.useState('');
    const [calTypeRef, setCalTypeRef] = React.useState(-1);
    const [customerRef, setCustomerRef] = React.useState(-1);
    const [unitMeasurmentRef, setUnitMeasurmentRef] = React.useState(-1);
    const [cECSN, setCECSN] = React.useState('');
    const [rangeMin, setRangeMin] = React.useState(0);
    const [rangeMax, setRangeMax] = React.useState(0);
    const [resolution, setResolution] = React.useState(0);
    const [esResolution, setEsResolution] = React.useState(0);
    const [accurancy, setAccurancy] = React.useState(0);
    const [tolerance, setTolerance] = React.useState(0);
    const [unitOfMeasurmentTitle, setUnitOfMeasurmentTitle] = React.useState('');
    const [reportTemplateRef, setReportTemplateRef] = React.useState(-1);
    const [calibrationProcedureRef, setCalibrationProcedureRef] = React.useState(-1);
    const [milliAmpDigits, setMilliAmpDigits] = React.useState('');
    const [messages, setMessages] = React.useState([]);
    
    const [isGoingToAddNewReportDirectly, setIsGoingToAddNewReportDirectly] = React.useState(false)

    const [isRedirectToMut, setIsRedirectToMut] = React.useState(false)
    const [isRedirectNextStep, setIsRedirectNextStep] = React.useState(false);
    const [manufacture, setManufacture] = React.useState('');

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);


    //const onZipChange = (e) => setZip(e.target.value);

    const onCalibrationTypeChange = (e) => {
        setUnitMeasurmentRef(-1);
        setCalTypeRef(e.target.value);
    };

    const onUnitOfMeasurmentChange = (e) => {
        setUnitMeasurmentRef(e.target.value);
        setUnitOfMeasurmentTitle(e.target.selectedOptions[0].innerText);
    }

    const onReportTemplateRefChange = (e) => setReportTemplateRef(e.target.value);
    const onCalibrationProcedureRefChange = (e) => setCalibrationProcedureRef(e.target.value);
    const onDescriptionChange = (e) => setDescription(e.target.value);
    const onManufactureChange = (e) => setManufacture(e.target.value);
    const onModel_TypeChange = (e) => setModel_Type(e.target.value);
    const onSerialNoChnage = (e) => setSerialNo(e.target.value);
    const onTagNoChnage = (e) => setTagNo(e.target.value);
    const onLocationChange = (e) => setLocation(e.target.value);
    const onRangeMinChange = (e) =>setRangeMin(e.target.value);
    const onRangeMaxChange = (e) =>setRangeMax(e.target.value);
    const onResolutionChange = (e) =>setResolution(e.target.value);
    const onEsResolutionChange = (e) =>setEsResolution(e.target.value);
    const onAccurancyChange = (e) =>setAccurancy(e.target.value);
    const onToleranceChange = (e) =>setTolerance(e.target.value);
    const onMilliAmpDigitsChange = (e) =>setMilliAmpDigits(e.target.value);
    
    const cancel = () => {
        setIsRedirectToMut(true);
    }


    const addAndGoToNewReport = () => {
        setIsGoingToAddNewReportDirectly(true);
        add();
    }

    const addEdit = () => {
        if (isEdit)
            edit();
        else
            add();
    }

    const add = () => {
        let messagesList = [];
        let self = this;

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "Mut/add",
                {
                    CustomerRef: customerRef,
                    CalTypeRef: calTypeRef,
                    Description: description,
                    Manufacture: manufacture,
                    Model_Type: model_Type,
                    SerialNo: serialNo,
                    TagNo: tagNo,
                    Location: location,
                    UnitMeasurmentRef: unitMeasurmentRef,
                    RangeMin: rangeMin,
                    RangeMax: rangeMax,
                    Resolution: resolution,
                    EsResolution: esResolution,
                    Accurancy: accurancy,
                    ReportTemplateRef: reportTemplateRef,
                    CalibrationProcedureRef: calibrationProcedureRef,
                    MilliAmpDigits: milliAmpDigits
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let _id = response.data;
                    setId(_id)
                    //TO DO redirecto Calibration Report
                    setIsRedirectToMut(true);
                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messagesList.push(p);
                        })
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setMessages(messagesList);
                });
        });
    }

    const edit = () => {

        let messages = [];
        let self = this;
        debugger;

        Axios.post(Config.API_URL + "Town_Unit/edit",
            {
                Id: this.state.Id,
                Title: this.state.Title
            },
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                let id = response.data;
                this.setState({ Id: id, isRedirectUnit: true });
            })
            .catch(error => {
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messages.push(p);
                    })
                }
                else {
                    //messages.push("خطا در برقراری ارتباط با سرور - در صورت استفاده از پروکسی آنرا غیر فعال نمایید")
                    messages.push("Failed to connect to server")
                }
                self.setState({ messages, isInsert: true });
            });
    }

    const onCustomerBind = (id) => setCustomerRef(id);


    if (isRedirectToMut && isGoingToAddNewReportDirectly)
        return <Redirect to={"/CertificateOfCalibration/Add/&MutRef=" + id}></Redirect>

    if (isRedirectToMut) {
        return <Redirect to={"/Mut/"}></Redirect>
    }


    return (

        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-9 col-12 mx-auto position-relative">
                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fa fa-wrench fa-fw" aria-hidden="true"></i>
                            </div>
                            <h6 className="mb-0">{PAGE_TITLE}</h6>
                        </div>
                        <div className="card-body pt-2">
                            <div class="row">
                                {isEdit == true &&
                                    <div className="form-group">
                                        <label>
                                            {t.cecId}
                                        </label>
                                        <p className="form-text text-muted ms-1">
                                            {cECSN}
                                        </p>
                                    </div>
                                }
                            </div>
                            <div class="row">
                                <div className="form-group">
                                    <label>
                                        Customer Name
                                    </label>
                                    <UserDefaultCustomer onBind={onCustomerBind} name="CustomerRef" user={user} />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <div className="input-group input-group-static  my-3">
                                        <label for="exampleInputEmail">
                                            Calibration Type
                                        </label>
                                        <CalibrationTypeLkp name="CalTypeRef" onChange={onCalibrationTypeChange} user={user} />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div className="input-group input-group-static  my-3">
                                        <label for="exampleInputEmail">{t.unitOfMeasurment}</label>
                                        <UnitOfMeasurmentLkp name="UnitMeasurmentRef"
                                            calibrationTypeRef={calTypeRef}
                                            onChange={onUnitOfMeasurmentChange}
                                            selectedValue={unitMeasurmentRef}
                                            user={user} />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label >
                                            {t.reportTemplateRef}
                                        </label>
                                        <ReportTemplateLkp
                                            name="ReportTemplateRef" calTypeRef={calTypeRef}
                                            onChange={onReportTemplateRefChange}
                                            user={user} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label for="exampleInputEmail">
                                            {t.calibrationProcedureRef}
                                        </label>
                                        <CalibrationProcedureLkp name="CalibrationProcedureRef" calTypeRef={calTypeRef}
                                            onChange={onCalibrationProcedureRefChange}
                                            user={user} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Description
                                    </label>
                                    <input type="text" class="form-control" name="description" onChange={onDescriptionChange} value={description} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Manufacture
                                    </label>
                                    <input type="text" class="form-control" name="manufacture" onChange={onManufactureChange} value={manufacture} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Model / Type
                                    </label>
                                    <input type="text" class="form-control" name="model_Type" onChange={onModel_TypeChange} value={model_Type} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Serial No
                                    </label>
                                    <input type="text" class="form-control" name="serialNo" onChange={onSerialNoChnage} value={serialNo} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Tag No
                                    </label>
                                    <input type="text" class="form-control" name="tagNo" onChange={onTagNoChnage} value={tagNo} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Location
                                    </label>
                                    <input type="text" class="form-control" name="location" onChange={onLocationChange} value={location} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Range Min {unitOfMeasurmentTitle}
                                    </label>
                                    <input type="text" class="form-control" name="rangeMin" onChange={onRangeMinChange} value={rangeMin} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Range Max {unitOfMeasurmentTitle}
                                    </label>
                                    <input type="text" class="form-control" name="rangeMax" onChange={onRangeMaxChange} value={rangeMax} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Resolution {unitOfMeasurmentTitle}
                                    </label>
                                    <input type="text" class="form-control" name="resolution" onChange={onResolutionChange} value={resolution} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        EsResolution {unitOfMeasurmentTitle}
                                    </label>
                                    <input type="text" class="form-control" name="esResolution" onChange={onEsResolutionChange} value={esResolution} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Accurancy {unitOfMeasurmentTitle}
                                    </label>
                                    <input type="text" class="form-control" name="accurancy" onChange={onAccurancyChange} value={accurancy} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Tolerance {unitOfMeasurmentTitle}
                                    </label>
                                    <input type="text" class="form-control" name="tolerance" onChange={onToleranceChange} value={tolerance} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label>
                                        Milli Amp Digits 
                                    </label>
                                    <input type="text" class="form-control" name="milliAmpDigits" onChange={onMilliAmpDigitsChange} value={milliAmpDigits} />
                                </div>
                            </div>
                            
                            


                            {messages.length > 0 &&
                                <Alert messages={messages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={cancel}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={addEdit}>
                                    Save
                                </button>
                                { !isEdit &&
                                <button type="button" class="btn btn-primary m-0 ms-2" onClick={addAndGoToNewReport}>
                                    Save & Add new report
                                </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >

    )

}

export default Mut_Add;