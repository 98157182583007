import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import cellWithEditing from '../CellWithEditing'
import { Redirect } from 'react-router-dom'
import Config from '../../Constants/index'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'
import cellEditDeleteButton from '../../Component/cellEditDeleteButton';
import Axios from 'axios';
import CellDeleteButton from '../../Component/CellDeleteButton';


export default class ExhibitionForm extends Component {

    constructor() {
        super();
        this.state = { buildingLists: [], loading: true, messages: [] };
        this.fetchData = this.fetchData.bind(this);
        this.insert = this.insert.bind(this);
        this.edit = this.edit.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.delete = this.delete.bind(this);
        this.hideDialogs = this.hideDialogs.bind(this);
        this.PAGE_TITLE = "Exhibition Forms";
    }

    fetchData() {
        this.setState({ loading: true });
        fetch(Config.API_URL + "ExhibitionForm/GetExhibitionFormList/?timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => response.json())
            .then(json => {
                this.setState({ buildingLists: json, loading: false });
            });

    }

    componentWillMount() {
        this.fetchData()
        this.select = this.select.bind(this);
    }

    componentDidMount() {
        document.title = this.PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }

    insert = () => {
        this.setState({ isRedirecToInsert: true });
    }

    edit(dataItem) {
        this.setState({ buildingRef: dataItem.id })
        this.setState({ isRedirectToEdit: true })
    }

    select(dataItem) {
        this.setState({ buildingRef: dataItem.id })
        this.setState({ isRedirectToMatchOrderAdmin: true })
    }

    deleteConfirm(dataItem) {
        this.setState({ buildingRef: dataItem.id })
        this.setState({ deleteDialog: true })
    }

    hideDialogs() {
        this.setState({ deleteDialog: false });
        this.setState({ alertDialog: false });
    }

    delete() {

        let messages = [];
        let self = this;
        debugger;
        Axios.post(Config.API_URL + "ExhibitionForm/delete",
            {
                Id: self.state.buildingRef,
            },
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                //let id = response.data;
                this.setState({ deleteDialog: false });
                this.fetchData();
            })
            .catch(error => {
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messages.push(p);
                    })
                }
                else {
                    messages.push("Failed to connect to server")
                }
                self.setState({ messages, alertDialog: true });
            });
    }


    render() {

        if (this.state.isRedirectToMatchOrderAdmin) {
            return <Redirect to={'/Town_Unit/' + this.state.buildingRef} />
        }

        if (this.state.isRedirecToInsert) {
            return <Redirect to={'/ExhibitionForm/Add' } />
        }

        if (this.state.isRedirectToEdit) {
            return <Redirect to={'/ExhibitionForm/Edit/' + this.state.buildingRef} />
        }


        return (


            <div>

                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">{this.PAGE_TITLE}</h1>
                </div>
                <Grid style={{ height: '500px' }} data={this.state.buildingLists} resizable >                
                    <Column field="id" title="id" width="80px" className="NoWrap" filter />
                    <Column field="firstName" title="FirstName" width="120px"  className="NoWrap"/>
                    <Column field="lastName" title="LastName" width="120px"  className="NoWrap"/>
                    <Column field="companyName" title="Company Name" width="250px"  className="NoWrap"/>
                    <Column field="companyPosition" title="Position" width="150px"  className="NoWrap"/>
                    <Column field="email" title="Email" width="200px"  className="NoWrap"/>
                    <Column field="phoneNumber" title="PhoneNumber" width="150px"  className="NoWrap"/>
                </Grid>

                {
                    //Make Delete As Component
                    this.state.deleteDialog &&
                    <Dialog
                        title="Delete"
                        onClose={this.hideDialogs}
                    >
                        <div>
                            Are you sure you want to delete this item?
                            {/* آیا برای حذف {this.state.deleteTitle} مطمئن هستید؟ */}
                        </div>

                        <DialogActionsBar>
                            <button
                                className="k-button k-primary"
                                onClick={this.delete}
                            >
                                Yes
                        </button>
                            <button
                                className="k-button"
                                onClick={this.hideDialogs}

                            >
                                No
                        </button>
                        </DialogActionsBar>
                    </Dialog>
                }

                {
                    //Make Delete As Component
                    this.state.alertDialog > 0 &&
                    <Dialog
                        title="Alert"
                        onClose={this.hideDialogs}
                    >

                                {this.state.messages.length > 0 && 
                <ul>
                {this.state.messages.map((message)=>{
                    return (                       
                        <li>{message}</li>      
                    )
                })}
                </ul>
    }

                        <DialogActionsBar>
                            <button
                                className="k-button k-primary"
                                onClick={this.hideDialogs}
                            >
                                Close
                        </button>
                        </DialogActionsBar>
                    </Dialog>
                }

                {
                    this.state.loading && <Dialog>
                        <i class='fa fa-spinner fa-spin '></i> Loading...
                                </Dialog>
                }

            </div>
        )


    }
}
