import React, { Component } from 'react'
import './Invoice.css'
export default class Invoice extends Component {
  render() {
    return (
      <div className="page" >
        <div className="header">
            <div>
                Logo
            </div>
            <div>
                Contacts
            </div>
        </div>
      </div>
    )
  }
}
