import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import cellWithEditing from '../CellWithEditing'
import { Redirect } from 'react-router-dom'
import Config from '../../Constants/index'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'
import cellEditDeleteButton from '../../Component/cellEditDeleteButton';
import Axios from 'axios';
import CellDeleteButton from '../../Component/CellDeleteButton';


export default class UnitOfMeasurment extends Component {

    constructor() {
        super();
        this.state = { buildingLists: [], loading: true, messages: [] };
        this.fetchData = this.fetchData.bind(this);
        this.insert = this.insert.bind(this);
        this.edit = this.edit.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.delete = this.delete.bind(this);
        this.hideDialogs = this.hideDialogs.bind(this);
        this.PAGE_TITLE = "Unit Of Measurments";
    }

    fetchData() {
        this.setState({ loading: true });
        fetch(Config.API_URL + "UnitOfMeasurment/GetUnitOfMeasurmentList/?timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => response.json())
            .then(json => {
                this.setState({ buildingLists: json, loading: false });
            });

    }

    componentWillMount() {
        this.fetchData()
        this.select = this.select.bind(this);
    }

    componentDidMount() {
        document.title = this.PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }

    insert = () => {
        this.setState({ isRedirecToInsert: true });
    }

    edit(dataItem) {
        this.setState({ buildingRef: dataItem.id })
        this.setState({ isRedirectToEdit: true })
    }

    select(dataItem) {
        this.setState({ buildingRef: dataItem.id })
        this.setState({ isRedirectToMatchOrderAdmin: true })
    }

    deleteConfirm(dataItem) {
        this.setState({ buildingRef: dataItem.id })
        this.setState({ deleteDialog: true })
    }

    hideDialogs() {
        this.setState({ deleteDialog: false });
        this.setState({ alertDialog: false });
    }

    delete() {

        let messages = [];
        let self = this;
        debugger;
        Axios.post(Config.API_URL + "UnitOfMeasurment/delete",
            {
                Id: self.state.buildingRef,
            },
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                //let id = response.data;
                this.setState({ deleteDialog: false });
                this.fetchData();
            })
            .catch(error => {
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messages.push(p);
                    })
                }
                else {
                    messages.push("Failed to connect to server")
                }
                self.setState({ messages, alertDialog: true });
            });
    }


    render() {

        if (this.state.isRedirectToMatchOrderAdmin) {
            return <Redirect to={'/Town_Unit/' + this.state.buildingRef} />
        }

        if (this.state.isRedirecToInsert) {
            return <Redirect to={'/UnitOfMeasurment/Add' } />
        }

        if (this.state.isRedirectToEdit) {
            return <Redirect to={'/UnitOfMeasurment/Edit/' + this.state.buildingRef} />
        }


        return (


            <div>

                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">{this.PAGE_TITLE}</h1>
                </div>
                <Grid style={{ height: '500px' }} data={this.state.buildingLists}  >
                    <Column field="id" title="Id" width="50px" />
                    <Column field="title" title="Title" width="100px" />
                    <Column field="description" title="Description" width="200px" />
                    <Column field="multiplyBy" title="Multiply By" width="200px" />
                    <Column field="vCalibrationTypeTitle" title="Calibration Type" width="200px" />                    
                </Grid>

                {
                    this.state.loading && <Dialog>
                        <i class='fa fa-spinner fa-spin '></i> Loading...
                                </Dialog>
                }

            </div>
        )


    }
}
