import React, { Component } from 'react'
import Config from '../../Constants/index'
import { Link, Redirect } from 'react-router-dom'
import t from '../../Lang/Index'
import Footer from '../../Navigation/Footer';
import CardCertOfCalibrationTotal from './CardCertOfCalibrationTotal';
import NavBar from '../../Navigation/NavBar';
export default class Dashboard extends Component {

  componentDidMount() {
    document.title = "Dashboard" + " | " + Config.SITE_TITLE;
    //window.chart1();
    //window.chart2();
    //window.chart3();
  }

  render() {
    return (
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">        
        <NavBar />
        <div className="container-fluid py-4">
          <div className="row">
            <CardCertOfCalibrationTotal counterTitle="150" user={this.props.user} />
          </div>
        </div>
        <Footer />
      </main>

    )
  }
}
