import React, { useRef } from 'react'
import axios from 'axios';
import Config from '../../../Constants/index'
import Alert from '../../../Component/Alert';

const FileUploaderGraph = (props) => {
    const fileInput = useRef(null)
    const maxSize = 40 * 1048576;
    const [error, setError] = React.useState([]);
    const [uploading, setUploading] = React.useState(false);
    const [message, setMessage] = React.useState([]);
    const certOfCalId = props.certOfCalref;
    const [inputVisible, setInputVisible] = React.useState(true);
    const ref = useRef();

    const handleFileInput = (e) => {        
        setMessage("");
        setError("");
      
        const file = e.target.files[0];

        if (!file) {
            setError("File Not Selected");
            onUploadError();
            return;
        }        

        if (file.size > maxSize) {
            setError("File size cannot exceed more than 10MB");
            onUploadError();
            return;
        }

        var fileExtension = file.name.split('.').pop();

        if (!(fileExtension == "pdf")) {
            setError("Only pdf is allowed.");
            onUploadError();
            return;
        }

        submitForm(file);
    }

    const onUploadInit = () =>  {
        props.onUploadInit();
        setUploading(true);
    }

    const onUploadSuccess = () =>  {
        props.onUploadSuccess();
        setUploading(false);   
        setInputVisible(false);    
    }

    const onUploadError = () =>  {
        reset();
        props.onUploadError();
        setUploading(false);
    }

    const reset = () => {
        ref.current.value = "";
    };

    const submitForm = (selectedFile) => {

        onUploadInit();

        const formData = new FormData();
        formData.append("name", "test");
        formData.append("files", selectedFile);
        const UPLOAD_URL = Config.API_URL + "CertificateOfCalibration/Upload/?certOfCalId=" + certOfCalId

        axios.post(UPLOAD_URL, formData, {
            headers: {
                'Authorization': 'Bearer ' + props.user.accessToken,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                let id = response.data;
                setMessage("Uploaded Successfully")
                onUploadSuccess();
            })
            .catch(error => {
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        setError(p);
                    })
                }
                else {
                    setError("Failed to connect to server")
                }

                onUploadError();
            });

    };

    return (
        <div className="file-uploader">
            { inputVisible &&
                <input type="file" onChange={handleFileInput} disabled={uploading} ref={ref} />
            }
            {/* <button onClick={e => fileInput.current && fileInput.current.click()} className="btn btn-primary" /> */}
            {/* {messages.length > 0 && */}
            {error.length > 0 &&
                <div style={{ color: 'red' }}> {error}</div>
            }

            {message.length > 0 &&
                <div style={{ color: 'green' }}> {message}</div>
            }
            {uploading && 
            <div>
                <i class='fa fa-spinner fa-spin '></i> Uploading...
            </div>}
        </div>
    )
}

export default FileUploaderGraph