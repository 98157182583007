import React, { Component } from 'react'

export default class Alert extends Component {
    render() {
        return (
            <div className="alert alert-dismissable alert-warning">
                {/* <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button> */}
                {/* <h4>Alert!</h4> */}
                {/* <strong>Warning!</strong> */ 
                }
                {this.props.messages.length > 0 && 
                <ul>
                {this.props.messages.map((message)=>{
                    return (                       
                        <li>{message}</li>   
                    )
                })}
                </ul>
                }
                {/* <a href="#" className="alert-link">alert link</a> */}
            </div>

        )
    }
}
