import * as React from "react"
import * as ReactDOM from "react-dom"
import Config from "../../Constants/index";
import { getIdToken } from "firebase/auth";

const ReportTemplateLkp = (props) => {

    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        getIdToken(props.user).then((idToken) => { 
            fetch(Config.API_URL + "ReportTemplate/GetReportTemplateList/?timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    setData(json);
                });
        });
    }

    const onChange = (event) => {
        props.onChange(event);
    }

    return (
            <select class="form-control" id="exampleSelect1" name={props.name}
                onChange={onChange} disabled={!(props.calTypeRef > 0)} value={props.selectedValue} >
                <option value={0}  >-</option>
                {
                    data
                        .filter(function (reportTemplate) {
                            return reportTemplate.calibrationTypeRef == props.calTypeRef
                        })
                        .map(function (item, index) {
                            return <option value={item.id}>{item.docNo + ' - ' + item.title}</option>
                        })
                }
            </select>
    )

}

export default ReportTemplateLkp;

