import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import cellWithEditing from '../CellWithEditing'
import { Redirect } from 'react-router-dom'
import Config from '../../Constants/index'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'
import cellEditDeleteButton from '../../Component/cellEditDeleteButton';
import Axios from 'axios';
import CellDeleteButton from '../../Component/CellDeleteButton';
import cellButton from '../../Component/cellButton';
import cellButtonReport from '../../Component/CellButtonReport';
import CellBtnIcon from '../CellBtnIcon';
import UserDefaultCustomerText from '../../Component/Calibration/UserDefaultCustomerText';
import CellButtonResult from '../../Component/CellButtonResults';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import { getAuth, onAuthStateChanged, getIdToken, debugErrorMap } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import CertHistory from '../../Component/History/CertHistory';

const CertOfCal = (props) => {

    const PAGE_TITLE = "Calibration Reports";

    const [data, setData] = React.useState([]);
    const [messages, setMessages] = React.useState([]);
    const [skip, setSkip] = React.useState(0);
    const [take, setTake] = React.useState(20);
    const [total, setTotal] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(100);
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const [alertDialog, setAlertDialog] = React.useState(false);
    const [uploadDialog, setUploadDialog] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(false);
    const [uploadDialogCloseButtonVisible, setUploadDialogCloseButtonVisible] = React.useState(true);
    const [isRedirecToInsert, setIsRedirecToInsert] = React.useState(false);

    const [filterDialog, setFilterDialog] = React.useState(false);
    const [customerRef, setCustomerRef] = React.useState(0);

    const [f_equipmentDescription, setF_equipmentDescription] = React.useState('');
    const [f_Status_All, setD_Status_All] = React.useState(true);
    const [f_Status_Draft, setD_Status_Draft] = React.useState(false);
    const [f_Status_Submited, setD_Status_Submited] = React.useState(false);
    const [f_Status_Approved, setD_Status_Approved] = React.useState(false);
    const [f_Status_Rejected, setD_Status_Rejected] = React.useState(false);
    const [f_Status_Archive, setD_Status_Archive] = React.useState(false);

    const onf_EquipmentDescriptionChange = (e) => { setF_equipmentDescription(e.target.value) }
    const onf_Status_AllChange = (e) => { setD_Status_All(e.target.checked) }
    const onf_Status_DraftChange = (e) => { setD_Status_Draft(e.target.checked) }
    const onf_Status_SubmitedChange = (e) => { setD_Status_Submited(e.target.checked) }
    const onf_Status_ApprovedChange = (e) => { setD_Status_Approved(e.target.checked) }
    const onf_Status_RejectedChange = (e) => { setD_Status_Rejected(e.target.checked) }
    const onf_Status_ArchiveChange = (e) => { setD_Status_Archive(e.target.checked) }

    const [lastSelectedItem, setLastSelectedItem] = React.useState([]);

    const [sort, setSort] = React.useState([
        { field: 'id', dir: 'desc' }
    ]);

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    


    React.useEffect(() => {
        document.title = PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }, []);

    React.useEffect(() => {
        if (customerRef != 0)
            fetchData();
        setLastSelectedItem([]);
    }, [skip, take, sort, customerRef]);


    const getFilterStr = () => {
        var result = "";

        if (f_equipmentDescription)
            result = result + "UUC-EUC-DUC Description:" + f_equipmentDescription;

        if (!f_Status_All) {
            result = result + " Status is: [";
            if (f_Status_Draft)
                result = result + " Draft ";
            if (f_Status_Submited)
                result = result + " Submitted ";
            if (f_Status_Approved)
                result = result + " Approved ";
            if (f_Status_Rejected)
                result = result + " Rejected ";
            if (f_Status_Archive)
                result = result + " Archive ";
            result = result + "]";
        }

        if (result)
            result = " Search: " + result;

        return result;
    }

    const showfilterDialog = () => {
        setFilterDialog(true);
    }

    const onFilter = () => {
        fetchData();
        setFilterDialog(false);
    }

    const removeFilter = () => {
        setF_equipmentDescription('');
        setD_Status_All(true);
        setD_Status_Draft(false);
        setD_Status_Submited(false);
        setD_Status_Approved(false);
        setD_Status_Rejected(false);
        setD_Status_Archive(false);
    }

    const pageChange = (event) => {
        setSkip(event.page.skip);
        setTake(event.page.take);
    }

    const onSortChange = (e) => setSort(e.sort);

    const onCustomerBind = (id) => {
        setCustomerRef(id);
    }

    const fetchData = () => {
        debugger;
        var self = this;
        setLastSelectedItem([]);


        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + "CertificateOfCalibration/GetCertificateOfCalibrationListAdmin/?Skip=" + skip
                + "&Take=" + take
                + "&SortField=" + (sort[0] ? sort[0].field : "")
                + "&SortDir=" + (sort[0] ? sort[0].dir : "")
                + "&F_equipmentDescription=" + f_equipmentDescription
                + "&F_customerRef=" + customerRef
                + "&time=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                }
            ).then(response => {
                setData(response.data.rowModels);
                setTotal(response.data.total);
            }).catch(error => {
                console.log(error);
            })
        });
    }

    const updateItem = (changedItem) => {

        const newData = data.map((currentItem) => {

            if (currentItem.id == changedItem.id)
                currentItem = changedItem

            return currentItem;
        });

        setData(newData);
        setLastSelectedItem(changedItem);

    }

    const insert = () => {
        setIsRedirecToInsert(true);
    }

    const selectionChange = (event) => {
        const id = event.dataItem.id;
        setLastSelectedItem(event.dataItem);

        const newList = data.map((item) => {
            if (item.id === id) {
                const updatedItem = {
                    ...item,
                    selected: !item.selected,
                };
                return updatedItem;
            }
            item.selected = false;
            return item;
        });

        setData(newList);
    }

    const rowClick = (event) => {

        const id = event.dataItem.id;
        setLastSelectedItem(event.dataItem);

        const newList = data.map((item) => {
            if (item.id === id) {
                const updatedItem = {
                    ...item,
                    selected: true //isCheckBoxCell ? !item.selected : true,
                };

                return updatedItem;
            }
            //if (!isCheckBoxCell)
            item.selected = false;
            return item;
        });

        setData(newList);
    }

    const headerSelectionChange = (event) => {

        const checked = event.syntheticEvent.target.checked;

        const newList = data.map((item) => {
            item.selected = checked
            return item;
        });

        setData(newList);
    }


    if (isRedirecToInsert) {
        return <Redirect to={'/CertificateOfCalibration/Add/'} />
    }

    return (


        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

            {/* Navbar */}
            <NavBar />
            <div className="container-fluid py-4">


                <div className="col-12">
                    <div className="card mb-4">
                        <div class="card-header p-3 pt-2">
                            <div class="icon icon-lg icon-shape bg-gradient-info shadow text-center border-radius-xl mt-n4 float-start">
                                <i class="fa fas fa-certificate fa-fw" aria-hidden="true"></i>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 class="mb-0">
                                        {PAGE_TITLE} of <UserDefaultCustomerText
                                            onBind={onCustomerBind}
                                            user={props.user} />
                                    </h6>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-lg-9">

                                {/* <div className="card-body px-0 pt-0 pb-2"> */}


                                <Grid data={data} skip={skip} take={take}
                                    total={total} sort={sort} pageSize={pageSize}
                                    onPageChange={pageChange}
                                    onSortChange={onSortChange}
                                    sortable
                                    pageable={{
                                        pageSizes: [10, 20, 50, 100],
                                        //type: 'input'

                                    }}

                                    resizable
                                    selectedField="selected"
                                    onSelectionChange={selectionChange}
                                    onHeaderSelectionChange={headerSelectionChange}
                                    onRowClick={rowClick}
                                >

                                    <GridToolbar>
                                        <Button iconClass="fa fa-filter"
                                            style={{ 'margin-left': '5px' }}
                                            onClick={showfilterDialog}>Filter</Button>
                                        <Button iconClass="fa fa-times"
                                            style={{ 'margin-left': '5px' }}
                                            onClick={removeFilter}>Remove</Button>
                                        {getFilterStr()}
                                    </GridToolbar>

                                    <GridToolbar>
                                        <Button onClick={insert} >New Certificate</Button>
                                    </GridToolbar>

                                    {/* <Column field="vCustomerName" title="Customer" width="150px" className="NoWrap"  sortable/> */}
                                    <Column
                                        field="selected"
                                        width="50px"
                                        headerSelectionValue={
                                            data.length > 0
                                            &&
                                            data.findIndex(dataItem => dataItem.selected === false) === -1
                                        } />


                                    <Column field="id" title="Id" width="60px" className="NoWrap" />
                                    <Column field="certificateNumber" title="Certificate Number" width="200px" className="NoWrap" />
                                    <Column field="vStatusTitle" title="Status" width="100px" className="NoWrap" />
                                    <Column field="vDateOfCalibration" title="Date" width="120px" className="NoWrap" />
                                    <Column field="vDueDateOfCalibration" title="Due Date" width="120px" className="NoWrap" />
                                    <Column title="UUC-EUC-DUC">
                                        <Column field="vEquipmentTitle" title="Description" width="250px" className="NoWrap" />

                                        <Column field="vEquipmentModel_Type" title="Model / Type" width="250px" className="NoWrap" />
                                        <Column field="vEquipmentSerialNo" title="Serial No" width="250px" className="NoWrap" />
                                        <Column field="vEquipmentTagNo" title="Tag No" width="250px" className="NoWrap" />
                                    </Column>
                                </Grid>

                                <div>


                                    {
                                        //Make Delete As Component
                                        alertDialog > 0 &&
                                        <Dialog
                                            title="Alert"
                                            onClose={this.hideDialogs} >

                                            {messages.length > 0 &&
                                                <ul>
                                                    {messages.map((message) => {
                                                        return (
                                                            <li>{message}</li>
                                                        )
                                                    })}
                                                </ul>
                                            }

                                            <DialogActionsBar>
                                                <button
                                                    className="k-button k-primary"
                                                    onClick={this.hideDialogs}
                                                >
                                                    Close
                                                </button>
                                            </DialogActionsBar>
                                        </Dialog>
                                    }

                                    {filterDialog &&
                                        <Dialog
                                            title="Filter"
                                            onClose={() => setFilterDialog(false)}
                                            width={250}
                                        // height={400}
                                        >
                                            <div>


                                                <div class="row">
                                                    <div className="col-12">
                                                        <div className="input-group input-group-static  my-3">
                                                            <label>UUC-EUC-DUC Description</label>
                                                            <input type="text" class="form-control" name="F_equipmentDescription" onChange={onf_EquipmentDescriptionChange}
                                                                value={f_equipmentDescription} />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div class="row">
                                                    <div className="col-12">
                                                        <div className="input-group input-group-static  my-3">
                                                            <label>
                                                                <input type="checkbox" name="reCert" onChange={onf_Status_AllChange}
                                                                    value={f_Status_All} checked={f_Status_All} /> All Status
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col-12">
                                                        <div className="input-group input-group-static  my-3">
                                                            <label>
                                                                <input type="checkbox" name="f_Status_Draft" onChange={onf_Status_DraftChange}
                                                                    value={f_Status_Draft} checked={f_Status_Draft} disabled={f_Status_All} /> Draft &nbsp;
                                                            </label>
                                                            <label>
                                                                <input type="checkbox" name="f_Status_Submited" onChange={onf_Status_SubmitedChange}
                                                                    value={f_Status_Submited} checked={f_Status_Submited} disabled={f_Status_All} /> Submited &nbsp;
                                                            </label>
                                                            <label>
                                                                <input type="checkbox" name="f_Status_Approved" onChange={onf_Status_ApprovedChange}
                                                                    value={f_Status_Approved} checked={f_Status_Approved} disabled={f_Status_All} /> Approved &nbsp;
                                                            </label>
                                                            <label>
                                                                <input type="checkbox" name="f_Status_Rejected" onChange={onf_Status_RejectedChange}
                                                                    value={f_Status_Rejected} checked={f_Status_Rejected} disabled={f_Status_All} /> Rejected &nbsp;
                                                            </label>
                                                            <label>
                                                                <input type="checkbox" name="f_Status_Archive" onChange={onf_Status_ArchiveChange}
                                                                    value={f_Status_Archive} checked={f_Status_Archive} disabled={f_Status_All} /> Archive &nbsp;
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>

                                            <DialogActionsBar>
                                                <button
                                                    className="k-button k-primary"
                                                    onClick={onFilter}
                                                >
                                                    OK
                                                </button>
                                                <button
                                                    className="k-button"
                                                    onClick={() => { setFilterDialog(false) }}
                                                >
                                                    Cancel
                                                </button>
                                            </DialogActionsBar>
                                        </Dialog>}



                                </div>






                            </div>
                            <CertHistory item={lastSelectedItem} visible={lastSelectedItem.id > 0} onDelete={() => { fetchData() }}
                                onItemUpdate={updateItem} user={props.user} />
                        </div>



                    </div>
                </div>




            </div>

            <Footer />
        </main>


    )
}

export default CertOfCal;
