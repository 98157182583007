import React, { Component } from 'react'
import Config from '../../Constants/index'
import { getIdToken } from "firebase/auth";

export default class UserDefaultCustomerText extends Component {

    constructor(props) {
        super(props);       
        
        this.state = {                                    
            data: [],
            customerTitle:"-",
            customerRef:-1
        };

        this.onBind = this.onBind.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }
 
    onBind = (id) => {
        this.props.onBind(id);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        getIdToken(this.props.user).then((idToken) => {
        fetch(Config.API_URL + "Customer/GetDefaultCustomerOfUser/?timestamp=" + new Date().getTime(),
            { headers: {
                'Authorization': 'Bearer ' + idToken
            } })
            .then(response => response.json())
            .then(customer => {
                this.setState({ customerRef:customer.id,customerTitle:customer.name});  
                this.onBind(customer.id);
            });
        });
    }

    render() {
        return (
            <span>{this.state.customerTitle}</span>
        )
    }
}
