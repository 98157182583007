import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../../Component/Alert'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'

const Customer_Add = (props) => {

    const PAGE_TITLE = "New Customer";
    const [id, setId] = React.useState(props.match.params.id);
    const isEdit = props.match.params.id > 0;

    const [isRedirectCustomer, setIsRedirectCustomer] = React.useState(false);
    const [Name, setName] = React.useState("");
    const [Address, setAddress] = React.useState("");
    const [CityRef, setCityRef] = React.useState(0);
    const [Zip, setZip] = React.useState("");
    const [Contact, setContact] = React.useState("");
    const [Phone, setPhone] = React.useState("");
    const [Email, setEmail] = React.useState("");
    const [PreFixChar, setPreFixChar] = React.useState("");
    const [CountryName, setCountryName] = React.useState("");
    const [StateName, setStateName] = React.useState("");
    const [messages, setMessages] = React.useState([]);


    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const Cities = [{ Id: 0, Title: "-" },
            { Id: 1, Title: "Erbil" },
            { Id: 2, Title: "Dahuk" },
            { Id: 3, Title: "Sulaymaniyah" },
            { Id: 4, Title: "Baghdad" },
            { Id: 5, Title: "Zakho" },
            { Id: 6, Title: "Kirkuk" },
            { Id: 7, Title: "Basra" },
            { Id: 8, Title: "İzmir" }
        ];

    const onNameChange = (e) => setName(e.target.value);
    const onAddressChange = (e) => setAddress(e.target.value);

    const onCityRefChange = (e) => {
        let cityRef = e.target.value;
        setCountryName(getCountryName(cityRef));
        setStateName(getStateName(cityRef));
        setCityRef(cityRef)
    };

    const onZipChange = (e) => setZip(e.target.value);
    const onContactChange = (e) => setContact(e.target.value);
    const onEmailChange = (e) => setEmail(e.target.value);
    const onPreFixCharChange = (e) => setPreFixChar(e.target.value);

    const getCountryName = (cityId) => {
        if (cityId == 0)
            return "";

        if (cityId == 8)
            return "Turkey";

        return "Iraq"
    }

    const getStateName = (cityId) => {
        if (cityId == 0)
            return "";

        if (cityId == 4)
            return "BAGHDAD";

        if (cityId == 7)
            return "BASRA";
        
        if (cityId == 8)
            return "İZMIR";

        return "KRG";
    }

    const cancel = () => {
        setIsRedirectCustomer(true);
    }

    const fetchData = () => {
        //TODO
        Axios.get(Config.API_URL + "Customer/GetCustomer/?id=" + this.state.Id + "&timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                //TODO
                //this.setState({ Title: response.data.title });              
            });
    }

    //componentDidMount() {
    //if (this.state.isEdit)
    //this.fetchData();
    //}

    const addEdit = () => {
        if (isEdit)
            edit();
        else
            add();
    }

    const add = () => {
        let messagesList = [];
        let self = this;

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "Customer/add",
                {
                    Name: Name,
                    Address: Address,
                    CityRef: CityRef,
                    Zip: Zip,
                    Contact: Contact,
                    Phone: Phone,
                    Email: Email,
                    PreFixChar: PreFixChar
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let _id = response.data;
                    setId(_id)
                    setIsRedirectCustomer(true);
                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messagesList.push(p);
                        })
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setMessages(messagesList);
                });
        });
    }

    const edit = () => {

        let messages = [];
        let self = this;
        debugger;

        Axios.post(Config.API_URL + "Town_Unit/edit",
            {
                Id: this.state.Id,
                Title: this.state.Title
            },
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                let id = response.data;
                this.setState({ Id: id, isRedirectUnit: true });
            })
            .catch(error => {
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messages.push(p);
                    })
                }
                else {
                    //messages.push("خطا در برقراری ارتباط با سرور - در صورت استفاده از پروکسی آنرا غیر فعال نمایید")
                    messages.push("Failed to connect to server")
                }
                self.setState({ messages, isInsert: true });
            });
    }

    if (isRedirectCustomer) {
        return <Redirect to={"/Customer/"}></Redirect>
    }

    return (

        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-9 col-12 mx-auto position-relative">
                    <div className="card">

                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fa fa-users fa-fw" aria-hidden="true"></i>
                            </div>
                            <h6 className="mb-0">{PAGE_TITLE}</h6>
                        </div>

                        <div className="card-body pt-2">
                            {/* TODO Show in edit only 
                                <div class="row">
                                    <div className="col-12">
                                        <div className="input-group input-group-static  my-3">
                                            <label>Customer Number</label>
                                            <input type="text" class="form-control" readOnly name="SerialNumber" value={this.state.serialNumber} />
                                        </div>
                                    </div>
                                </div>
                                */}
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>
                                            Customer Name
                                        </label>
                                        <input type="text" class="form-control" name="Name" onChange={onNameChange} value={Name} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>
                                            Address
                                        </label>
                                        <input type="text" class="form-control" name="Address" onChange={onAddressChange} value={Address} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>
                                            City
                                        </label>
                                        <select class="form-control" id="exampleSelect1" name="CityRef"
                                            onChange={onCityRefChange}
                                            value={CityRef}>
                                            {Cities.map((op, index) => (
                                                <option value={op.Id} > {op.Title} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>
                                            Country : {CountryName} , State : {StateName}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>
                                            Zip
                                        </label>
                                        <input type="text" class="form-control" name="Zip" onChange={onZipChange} value={Zip} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>
                                            Contact Person
                                        </label>
                                        <input type="text" class="form-control" name="Contact" onChange={onContactChange} value={Contact} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group1 input-group-static1  my-3">
                                        <label>
                                            Phone
                                        </label>
                                        <PhoneInput country={'iq'} value={Phone} onChange={phone => setPhone(phone)} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label for="exampleInputEmail">
                                            Email
                                        </label>
                                        <input type="text" class="form-control" name="Email" onChange={onEmailChange} value={Email} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label for="exampleInputEmail">
                                            PreFix Code of Customer
                                        </label>
                                        <input type="text" class="form-control" name="PreFixChar" onChange={onPreFixCharChange} value={PreFixChar} maxLength={4} />
                                    </div>
                                </div>
                            </div>


                            {messages.length > 0 &&
                                <Alert messages={messages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={cancel}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={addEdit}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >

    )

}

export default Customer_Add;