import React, { useState, useEffect } from 'react'
import Config from '../../Constants/index'
import Axios from 'axios';
import CardType1 from './CardType1';
import { getAuth, onAuthStateChanged,getIdToken } from "firebase/auth";

const CardCertOfCalibrationTotal = (props) => {

    const [totalDoc, setTotalDoc] = useState([]);

    const getData = () => {

        getIdToken(props.user).then((idToken)=> { 

            Axios.get(Config.API_URL + "CertificateOfCalibration/GetCount", {
                headers: {
                    'Authorization': 'Bearer ' + idToken
                }
            }
            ).then(response => {            
                setTotalDoc(response.data);
            }).catch(error => {
                console.log(error);
            })

        });
        
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <CardType1 title={"Calibration Reports"} counterTitle={totalDoc} />
    )
}

export default CardCertOfCalibrationTotal



