import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import Config from '../Constants';

export default function cellUrl() {
    return class extends GridCell {
        render() {
            return (
                <td>                    
                    <a className="k-button k-flat k-button-icontext" href={ Config.API_URL 
                        + "report/rt/?certId=" + this.props.dataItem.id +"&guid=" + this.props.dataItem.vReportGuid} target="_blank" >
                         <span role="presentation" class="k-icon k-i-print"></span>Print
                    </a>                    
                </td>                           
            );
        }
    };
}