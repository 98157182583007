import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import cellWithEditing from '../CellWithEditing'
import { Redirect } from 'react-router-dom'
import Config from '../../Constants/index'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'
import cellEditDeleteButton from '../../Component/cellEditDeleteButton';
import Axios from 'axios';
import CellDeleteButton from '../../Component/CellDeleteButton';


export default class ReportTemplate extends Component {

    constructor() {
        super();
        this.state = { buildingLists: [], loading: true };
        this.fetchData = this.fetchData.bind(this);
        this.PAGE_TITLE = "Report Templates";
    }

    fetchData() {
        this.setState({ loading: true });
        fetch(Config.API_URL + "ReportTemplate/GetReportTemplateList/?timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => response.json())
            .then(json => {
                this.setState({ buildingLists: json, loading: false });
            });
    }

    componentWillMount() {
        this.fetchData()
    }

    componentDidMount() {
        document.title = this.PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }

    render() {

        return (
            <div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">{this.PAGE_TITLE}</h1>
                </div>
                <Grid style={{ height: '500px' }} data={this.state.buildingLists}  >
                    <Column field="id" title="Id" width="80px" />
                    <Column field="docNo" title="Doc No" width="200px" />
                    <Column field="title" title="Title" width="350px" />
                    <Column field="vCalibrationTypeTitle" title="Calibration Type" width="150px" />
                    <Column field="vRevisionDate" title="Revision Date" width="150px" />
                </Grid>
                {
                    this.state.loading && <Dialog>
                        <i class='fa fa-spinner fa-spin '></i> Loading...
                    </Dialog>
                }
            </div>
        )


    }
}
