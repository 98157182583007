
import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';

export default function CellDeleteButton(select) {
    return class extends GridCell {
        render() {
            return (
                <td>
                    <button class="k-button k-flat k-button-icontext" onClick={() => { select(this.props.dataItem); }}>
                        <span role="presentation" class="k-icon k-i-delete"></span>Delete</button>
                </td>
            );
        }
    };
}