import React, { Component } from 'react'
import Alert from '../../Component/Alert'
import isEmail from 'validator/lib/isEmail'
import axios from 'axios'
import { Redirect,Link } from 'react-router-dom'
import Config from '../../Constants/index'
import t from '../../Lang/Index'

export default class ResetPasswordForm extends Component {

    constructor() {
        super();

        const urlParams = new URLSearchParams(window.location.search);
        let email = urlParams.get('email');
        let token = urlParams.get('token');

        this.state = {
            isInsert: true,
            email,
            token,
            newPassword : "",
            messages: [],
            isSuccess: false,
            isExpiredLink : false,
        };

        this.onClick_Btn = this.onClick_Btn.bind(this);
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onClick_Btn = (e) => {
        e.preventDefault();

        if (!this.validate())
            return;

        var messages = [];
        this.setState({ messages, isInsert: false });

        var self = this;

        axios.post(Config.API_URL + 'auth/ResetPassword/'
            , {
                Email: self.state.email,
                NewPassword : self.state.newPassword,
                Token: self.state.token
            }
        ).then(function (response) {
            //window.localStorage.setItem('apiToken', response.data.token);
            self.setState({ isSuccess: true });
        })
            .catch(function (error) {

                if (error.response && error.response.status == 400) {

                    if (error.response.data.message[0] == "ExpiredLink") {
                        self.setState({ messages : [], isExpiredLink: true });
                    }
                    else
                    {
                        error.response.data.message.map(p => {
                            messages.push(p);
                        })
                    }                    
                }
                else {
                    //messages.push("خطا در برقراری ارتباط با سرور - در صورت استفاده از پروکسی آنرا غیر فعال نمایید")
                    messages.push("Failed to connect to server")
                }
                console.log(error);
                self.setState({ messages, isInsert: true });

            });
    }

    validate = () => {
        var messages = [];
        if (this.state.email.length == 0)
            messages.push("Please enter an email");
        //messages.push("لطفا ایمیل را تکمیل نمایید");

        if (this.state.email.length > 0 && !isEmail(this.state.email))
            messages.push("Email is invalid")
        //messages.push("ایمیل را به صورت صحیح درج نمایید");

        this.setState({ messages: messages });

        return messages.length == 0;
    }

    render() {

        if (this.state.isRedirectToHome) {
            return <Redirect to={'/'} />
        }

        if (this.state.isSuccess) {
            return <div className="alert alert-dismissable alert-success">
                <h4>Success!</h4>
                    Go to the login page and login with new password.
                <br />
                <Link to="/Login">{t.login}</Link>
         </div>
        }

        if (this.state.isExpiredLink) {
            return <div className="alert alert-dismissable alert-warning">
                <h4>Failed!</h4>
                Reset password link has been expired, Please request a link again: 
                <Link to="/ForgotPassword">{t.forgot_password}</Link>
         </div>
        }


        return (
            <form role="form" method="post">
                <div className="alert alert-dismissable alert-danger" id="ContentGridAddModalAlertDangerContainer" style={{ display: 'none' }}>
                </div>
                <fieldset>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                            {t.new_password}
                        </label>
                        <input className="form-control" style={{ direction: 'ltr' }} placeholder="New Password"
                            id="newPassword" name="newPassword" type="password" ref="password" autofocus onChange={this.onChange} />


                    </div>

                    {this.state.messages.length > 0 &&
                        <Alert messages={this.state.messages}></Alert>}

                    {this.state.isInsert ?
                        (<button className="btn btn-lg btn-primary btn-block" id="btnLogin" type="button"
                            onClick={this.onClick_Btn}>{t.reset_password}</button>)
                        : (
                            <button className="btn btn-lg btn-primary btn-block disabled" type="button"
                            >
                                <i class='fa fa-spinner fa-spin '></i> {t.loading}</button>)
                    }

                </fieldset>
            </form>
        )
    }
}
