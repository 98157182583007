import React, { Component } from 'react'
import { Redirect , Link } from 'react-router-dom'
import Axios from 'axios';
import Config from '../Constants/index'
import t from '../Lang/Index'
import { getAuth, createUserWithEmailAndPassword, signOut } from "firebase/auth";

export default class TopBar extends Component {
  constructor() {
    super();
    this.state = { isRedirectToLogout: false, fullName: "" }
    this.fetchUser = this.fetchUser.bind(this);
  }

  Logout = () => {
    signOut(getAuth())
    this.setState({ isRedirectToLogout: true });
  }

  fetchUser = () => {
    var self = this;

    Axios.get(Config.API_URL + "Auth/User", {
      headers: { token: window.localStorage.getItem("apiToken") }
    }).then(response => {
      self.setState({ fullName: response.data.fullName });
    }).catch(function (error) {


      if (error.response.status == 401) {
        self.Logout();
      }
    });
  }

  componentDidMount() {
    //this.fetchUser();
  }

  sidebarToggle = () => {
    window.sidebarToggleClick()
  }

  render() {
    if (this.state.isRedirectToLogout) {
      return (
        <Redirect to="/Login" ></Redirect>
      )
    }

    return (
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        {/* Sidebar Toggle (Topbar) */}
        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3" onClick={this.sidebarToggle}>
          <i className="fa fa-bars" />
        </button>
        {/* Topbar Navbar */}
        <ul className="navbar-nav ml-auto">

          <div className="topbar-divider d-none d-sm-block" />
          {/* Nav Item - User Information */}
          <li className="nav-item dropdown no-arrow">
            <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">{this.state.fullName}</span>
              {/* <img className="img-profile rounded-circle" src="https://source.unsplash.com/QAB-WJcbgJk/60x60" /> */}
              <i className="fa fa-user" />

            </a>
            {/* Dropdown - User Information */}
            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
              <Link className="dropdown-item" to="/ChangePassword">
                <i className="fas fa-key fa-sm fa-fw mr-2 text-gray-400" />
                 {t.change_password}
              </Link>
              {/* <a className="dropdown-item" href="#">
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                Profile
              </a>
              <a className="dropdown-item" href="#">
                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                Settings
              </a>
              <a className="dropdown-item" href="#">
                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400" />
                Activity Log
              </a> */}
              {/* <div className="dropdown-divider" /> */}
              <a className="dropdown-item" onClick={this.Logout}>
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                {t.logout}
              </a>


            </div>
          </li>
        </ul>
      </nav>
    )
  }
}
