import React, { useState } from 'react';

function TextInput(props) {
    const [value, setValue] = useState('');

    const onChange = (event) => {
      setValue(event.target.value);
  
      // Propagate this change to the parent component
      if (props.onChange) {
          props.onChange(event); // pass the entire event object
      }
  };
  

    return (
      <div class="row">

        <div className="col-12">
            <div className="input-group input-group-static my-3">
                <label>
                    {props.label}
                </label>
                <input type="text" className="form-control" name={props.name} onChange={onChange} value={value} />
            </div>
        </div>
        </div>

    );
}

export default TextInput;
