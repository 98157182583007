import React, { Component } from 'react'
import {Link, Redirect } from 'react-router-dom'
import Config from '../Constants/index'
import t from '../Lang/Index'
import ResetPasswordForm from './ResetPassword/ResetPasswordForm'

export default class ResetPassword extends Component {
    constructor() {
        super();
        document.title = t.reset_password + " - " + Config.SITE_TITLE;
        this.state = {};
        document.body.classList.add('bg-gradient-primary');
    }

    render() {
        return (
            <div className="container">
                {/* Outer Row */}
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-12 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                {/* Nested Row within Card Body */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">{Config.SITE_TITLE}</h1>
                                                <h1 className="h4 text-gray-900 mb-4">{t.reset_password}</h1>
                                            </div>
                                            <ResetPasswordForm />                                                                                      
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
