import React from 'react'

const CardType1 = (props) => {
    return (
        <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">

            <div className="card">
                <div className="card-header p-3 pt-2">
                    <div className="icon icon-lg icon-shape bg-gradient-info shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                        <i class="fa fas fa-certificate fa-fw" aria-hidden="true"></i>
                    </div>
                    <div className="text-end pt-1">
                        <p className="text-sm mb-0 text-capitalize">{props.title}</p>
                        <h4 className="mb-0">{props.counterTitle}</h4>
                    </div>
                </div>
                <hr className="dark horizontal my-0" />
                <div className="card-footer p-3">
                    {/* <p className="mb-0"><span className="text-success text-sm font-weight-bolder">+55% </span>than lask week</p> */}
                    {/* <p className="mb-0"><span className="text-success text-sm font-weight-bolder">+100% </span>Approved</p> */}
                </div>
            </div>
        </div>    
    )
}

export default CardType1



