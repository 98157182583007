import React, { Component } from 'react'
import axios from 'axios'
import Config from '../../Constants/index'
import { Link, Redirect } from 'react-router-dom'
import Alert from '../../Component/Alert'

export default class GoogleCallBack extends Component {

    constructor() {
        super();
        const urlParams = new URLSearchParams(window.location.search);
        this.state = {
            code: urlParams.get('code'),
            isSuccess: false,
            messages: []
        }

        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        var messages = [];
        var self = this;
        axios.get(Config.API_URL + '/auth/googlecallback?code=' + this.state.code).then(function (response) {
            window.localStorage.setItem('apiToken', response.data.token);
            self.setState({ isSuccess: true });
        })
            .catch(function (error) {

                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messages.push(p);
                    })
                }
                else {
                    //messages.push("خطا در برقراری ارتباط با سرور - در صورت استفاده از پروکسی آنرا غیر فعال نمایید")
                    messages.push("Failed to connect to server")
                }
                console.log(error);
                self.setState({ messages, isInsert: true });

            });
    }
    render() {
        if (this.state.isSuccess)
            return <Redirect to="/"></Redirect>

        return (
            <div>
                {this.state.messages.length > 0 &&
                    <Alert messages={this.state.messages}></Alert>}

            </div>
        )
    }
}
