
import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';

export default function CellCopyButton(select) {
    return class extends GridCell {
        render() {
            return (
                <td>
                    <button class="k-button k-flat k-button-icontext" onClick={() => { select(this.props.dataItem); }}>
                        <span role="presentation" class="fa fa-files-o"></span>Copy</button>

                        
                </td>
            );
        }
    };
}