import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../../Component/Alert'
import CalibrationTypeLkp from '../../Component/Calibration/CalibrationTypeLkp';
import UserDefaultCustomer from '../../Component/Calibration/UserDefaultCustomer';
import UnitOfMeasurmentLkp from '../../Component/Calibration/UnitOfMeasurmentLkp';
import t from '../../Lang/Index'
import ReportTemplateLkp from '../../Component/Calibration/ReportTemplateLkp';
import CalibrationProcedureLkp from '../../Component/Calibration/CalibrationProcedureLkp';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import CustomerLkp from '../../Component/CustomerLkp';
import EquipmentLkp from '../../Component/EquipmentLkp';
import UserLkp from '../../Component/UserLkp';
import moment from 'moment';
import DueDateOfCalibration from './DueDateOfCalibration';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import PsvStatusTemplateLkp from '../../Component/Calibration/PsvStatusTemplateLkp';

const CertOfCalPsv_Add = (props) => {

    const PAGE_TITLE = "Calibration PSV Report";
    const ControllerName = "CertOfCalPsv";
    const [id, setId] = React.useState(props.match.params.id);
    const isEdit = props.match.params.id > 0;
   
    const [isRedirectCertificateOfCalibration, setIsRedirectCertificateOfCalibration] = React.useState(false);
    const [messages, setMessages] = React.useState([]);
    const [customerRef, setCustomerRef] = React.useState(-1);
    const [calibrationUserRef, setCalibrationUserRef] = React.useState(-1);
    const [reviewUserRef, setReviewUserRef] = React.useState(-1);
    const [approvedUserRef, setApprovedUserRef] = React.useState(-1);
    const [dateOfCalibration, setDateOfCalibration] = React.useState('');
    const [dueDateOfCalibration, setDueDateOfCalibration] = React.useState('');
    const [dateOfIssue, setDateOfIssue] = React.useState('');
    const [jobNo, setJobNo] = React.useState('');
    const [pressureTestDesc, setPressureTestDesc] = React.useState('');    
    const [setPressure, setSetPressure] = React.useState(0);    
    const [cdtp, setCdtp] = React.useState('');
    const [backPressure, setBackPressure] = React.useState('');
    const [satisfied, setSatisfied] = React.useState(false);
    const [damaged, setDamaged] = React.useState(false);
    const [unsatisfied, setUnsatisfied] = React.useState(false);
    const [adjustmentNeeded, setAdjustmentNeeded] = React.useState(false);
    const [repaired, setRepaired] = React.useState(false);
    const [rejected, setRejected] = React.useState(false);    
    const [vCustomerName, setVCustomerName] = React.useState('');
    const [certificateNumber, setCertificateNumber] = React.useState('');
    const [saveEnabled, setSaveEnabled] = React.useState(true);
    const [isGoingToAddResultPageDirectly, setIsGoingToAddResultPageDirectly] = React.useState(false);
    const [certOfCalRef, setCertOfCalRef] = React.useState(-1);
    const [endUserTitle, setEndUserTitle] = React.useState('');
    const [projectRefNo, setProjectRefNo] = React.useState('');
    const [BubblesTotal, setBubblesTotal] = React.useState(0);
    const [Bubbles1Min, setBubbles1Min] = React.useState(0);
    const [Bubbles2Min, setBubbles2Min] = React.useState(0);


    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const onCustomerBind = (id) => setCustomerRef(id);
    const onDateOfCalibrationChange = (e) => setDateOfCalibration(e.target.value);
    const onDateOfIssueChange = (e) => setDateOfIssue(e.target.value);
    const onCalibrationUserRefChange = (e) => setCalibrationUserRef(e.target.value);
    const onReviewUserRefChange = (e) => setReviewUserRef(e.target.value);
    const onApprovedUserRefChange = (e) => setApprovedUserRef(e.target.value);    
    const onJobNoChange = (e) => setJobNo(e.target.value);
    const onPressureTestDescChange = (e) => setPressureTestDesc(e.target.value);
    const onSetPressureChange = (e) => setSetPressure(e.target.value);
    const onCdtpChange = (e) => setCdtp(e.target.value);
    const onBackPressureChange = (e) => setBackPressure(e.target.value);    
    const onSatisfiedChange = (e) => setSatisfied(e.target.checked);
    const onDamagedChange = (e) => setDamaged(e.target.checked);
    const onUnsatisfiedChange = (e) => setUnsatisfied(e.target.checked);
    const onAdjustmentNeededChange = (e) => setAdjustmentNeeded(e.target.checked);
    const onRepairedChange = (e) => setRepaired(e.target.checked);
    const onRejectedChange = (e) => setRejected(e.target.checked);  

    const onDueDateOfCalibrationHandler = (e) =>  setDueDateOfCalibration(e.target.value);

    //Add Mode
    const onDueDateOfCalibrationChange = (dueDate) => {
        setDueDateOfCalibration(dueDate);
    }

    const cancel = () => setIsRedirectCertificateOfCalibration(true);

    React.useEffect(() => {

        if (isEdit)
            fetchData();

    },[]);

    const fetchData = () => {
        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + ControllerName + "/GetEntityRow/?id=" + id + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    const item = response.data;
                    debugger;
                    setVCustomerName(item.vCustomerName);
                    setCertificateNumber(item.certificateNumber);
                    setDateOfCalibration(item.vDateOfCalibration);
                    setDueDateOfCalibration(item.vDueDateOfCalibration);
                    setCalibrationUserRef(item.calibrationUserRef);
                    setApprovedUserRef(item.approvedUserRef);
                    setReviewUserRef(item.reviewUserRef);
                    setDateOfIssue(item.vDateOfIssue);
                    setJobNo(item.jobNo);
                    setPressureTestDesc(item.pressureTestDesc);
                    setSetPressure(item.setPressure);
                    setSatisfied(item.satisfied);
                    setDamaged(item.damaged);
                    setUnsatisfied(item.unsatisfied);
                    setRejected(item.rejected);
                    setAdjustmentNeeded(item.adjustmentNeeded);
                    setRepaired(item.repaired);      
                    setCdtp(item.cdtp);
                    setBackPressure(item.backPressure);
                });
        });
    }

    const addEdit = () => {
        setSaveEnabled(false);

        if (isEdit)
            edit();
        else
            add();
    }

    const addAndGoToResultPage = () => {
        setIsGoingToAddResultPageDirectly(true);
        setSaveEnabled(false);      
        if (isEdit)
            edit();  
        else
            add();
    }


    const add = () => {
        let messagesList = [];
        let self = this;

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "CertOfCalPsv/add",
                {
                    CustomerRef: customerRef,
                    DateOfCalibration: dateOfCalibration == '' ? '1900-01-01' : dateOfCalibration,
                    DueDateOfCalibration: dueDateOfCalibration == '' ? '1900-01-01' : dueDateOfCalibration,
                    EquipmentRef: props.match.params.equipmentRef,
                    CalibrationUserRef: calibrationUserRef,
                    ApprovedUserRef: approvedUserRef,
                    ReviewUserRef: reviewUserRef,
                    DateOfIssue: dateOfIssue == '' ? '1900-01-01' : dateOfIssue,
                    JobNo: jobNo,
                    PressureTestDesc: pressureTestDesc,
                    SetPressure: setPressure,
                    Satisfied: satisfied,
                    Damaged: damaged,
                    Unsatisfied: unsatisfied,
                    AdjustmentNeeded: adjustmentNeeded,
                    Repaired: repaired,                                      
                    Rejected: rejected,
                    Cdtp: cdtp,  
                    BackPressure: backPressure,
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let id = response.data.id;
                    setId(id);

                    setCertOfCalRef(response.data.certOfCalRef);

                    setIsRedirectCertificateOfCalibration(true);
                    setSaveEnabled(true);
                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messagesList.push(p);
                        })
                    }
                    else {
                        messagesList.push("Failed to send data to server")
                    }
                    setSaveEnabled(true);

                    setMessages(messagesList);
                    //Is insert TODO

                });
        });

    }

    const edit = () => {
        let messagesList = [];

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "CertOfCalPsv/Edit",
                {
                    Id: id,
                    CustomerRef: customerRef,
                    DateOfCalibration: dateOfCalibration == '' ? '1900-01-01' : dateOfCalibration,
                    DueDateOfCalibration: dueDateOfCalibration,
                    EquipmentRef: props.match.params.equipmentRef,
                    CalibrationUserRef: calibrationUserRef,
                    ApprovedUserRef: approvedUserRef,
                    ReviewUserRef: reviewUserRef,
                    DateOfIssue: dateOfIssue,
                    JobNo: jobNo,
                    PressureTestDesc: pressureTestDesc,
                    SetPressure: setPressure,
                    Satisfied: satisfied,
                    Damaged: damaged,
                    Unsatisfied: unsatisfied,
                    AdjustmentNeeded: adjustmentNeeded,
                    Repaired: repaired,                                      
                    Rejected: rejected,
                    Cdtp: cdtp,
                    BackPressure: backPressure,
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {                    
                    setSaveEnabled(true);
                    setIsRedirectCertificateOfCalibration(true);

                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messagesList.push(p);
                        })
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setSaveEnabled(true);

                    setMessages(messagesList);
                });
        });
    }

    const onStatusTemplateChange = (e) => setPressureTestDesc(e.value);
    

    if (isRedirectCertificateOfCalibration && isGoingToAddResultPageDirectly) 
        return <Redirect to={"/CalibrationResultPsv/" + certOfCalRef}></Redirect>

    if (isRedirectCertificateOfCalibration && !isGoingToAddResultPageDirectly) 
        return <Redirect to={"/CertOfCalPsv/"}></Redirect>  

    return (

        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-9 col-12 mx-auto position-relative">








                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fa fa-wrench fa-fw" aria-hidden="true"></i>
                            </div>
                            {!isEdit &&
                                <h6 className="mb-0">New {PAGE_TITLE} </h6>
                            }
                            {isEdit &&
                                <>
                                    <h6 className="mb-0">Edit {PAGE_TITLE}</h6>
                                    <p className="mb-0 font-weight-normal text-sm">
                                        {certificateNumber}
                                    </p>
                                </>
                            }

                        </div>


                        <div className="card-body pt-2">


                            <div class="row">
                                <div className="form-group">
                                    <label>
                                        Customer Name
                                    </label>

                                    {!isEdit &&
                                        <UserDefaultCustomer onBind={onCustomerBind} name="CustomerRef" user={user} />
                                    }
                                    {isEdit &&
                                        <p className="form-text text-muted ms-1">
                                            {vCustomerName}
                                        </p>
                                    }
                                </div>
                            </div>


                            <div class="row">
                                <div className="col-3">
                                    <div className="input-group input-group-static  my-3">
                                        <label>
                                            Date Of Calibration
                                        </label>
                                        <input type="date" class="form-control" name="dateOfCalibration" onChange={onDateOfCalibrationChange}
                                            value={dateOfCalibration} />
                                    </div>
                                </div>
                            </div>


                            {isEdit &&
                            <div class="row">
                                <div className="col-3">
                                    <div className="input-group input-group-static  my-3">
                                        <label>
                                            Due Date Of Calibration
                                        </label>
                                        <input type="date" class="form-control" name="dueDateOfCalibration" onChange={onDueDateOfCalibrationHandler}
                                            value={dueDateOfCalibration} />
                                    </div>
                                </div>
                            </div>}
                            {!isEdit &&
                            <DueDateOfCalibration dateOfCalibration={dateOfCalibration}
                                name="dueDateOfCalibration" onDueDateOfCalibrationChange={onDueDateOfCalibrationChange}
                                value={dueDateOfCalibration} />
                            }

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Calibration User
                                    </label>
                                    <UserLkp name="CalibrationUserRef" onChange={onCalibrationUserRefChange} value={calibrationUserRef} user={user} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Review User
                                    </label>
                                    <UserLkp name="reviewUserRef" onChange={onReviewUserRefChange} value={reviewUserRef} user={user} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Approved User
                                    </label>
                                    <UserLkp name="approvedUserRef" onChange={onApprovedUserRefChange} value={approvedUserRef} user={user} />
                                </div>
                            </div>

                            <div className="col-3">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Date Of Issue
                                    </label>
                                    <input type="date" class="form-control" name="dateOfIssue" onChange={onDateOfIssueChange} value={dateOfIssue} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Job No
                                    </label>
                                    <input type="text" class="form-control" name="jobNo" onChange={onJobNoChange} value={jobNo} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Set Pressure
                                    </label>
                                    <input type="text" class="form-control" name="Temperature" onChange={onSetPressureChange} value={setPressure} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        CDTP
                                    </label>
                                    <input type="text" class="form-control" name="Cdtp" onChange={onCdtpChange} value={cdtp} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        BackPressure
                                    </label>
                                    <input type="text" class="form-control" name="BackPressure" onChange={onBackPressureChange} value={backPressure} />
                                </div>
                            </div>


                            {/* <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        EndUserRef. No
                                    </label>
                                    <input type="text" class="form-control" name="EndUserTitle" onChange={onEndUserTitleChange} value={setEndUserTitle} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Project Ref. No
                                    </label>
                                    <input type="text" class="form-control" name="ProjectRefNo" onChange={onProjectRefNoChange} value={setProjectRefNo} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Bubbles Total
                                    </label>
                                    <input type="text" class="form-control" name="BubblesTotal" onChange={onBubblesTotalChange} value={setBubblesTotal} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Bubbles 1 Min
                                    </label>
                                    <input type="text" class="form-control" name="Bubbles1Min" onChange={onBubbles1MinChange} value={setBubbles1Min} />
                                </div>
                            </div> */}
 
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        <input type="checkbox" name="damaged" onChange={onSatisfiedChange}
                                            value={satisfied} checked={satisfied}  /> Satisfied
                                    </label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        <input type="checkbox" name="damaged" onChange={onDamagedChange}
                                            value={damaged} checked={damaged}  /> Damaged
                                    </label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        <input type="checkbox" name="unsatisfied" onChange={onUnsatisfiedChange}
                                            value={unsatisfied} checked={unsatisfied}  /> Unsatisfied
                                    </label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        <input type="checkbox" name="adjustmentNeeded" onChange={onAdjustmentNeededChange}
                                            value={adjustmentNeeded} checked={adjustmentNeeded}  /> AdjustmentNeeded
                                    </label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        <input type="checkbox" name="repaired" onChange={onRepairedChange}
                                            value={repaired} checked={repaired}  /> Repaired
                                    </label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        <input type="checkbox" name="Rejected" onChange={onRejectedChange}
                                            value={rejected} checked={rejected} /> Rejected
                                    </label>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Status Template                                        
                                    </label>
                                    <PsvStatusTemplateLkp name="statusTemplateRef" onChange={onStatusTemplateChange} />
                                    
                                </div>
                            </div>
                            
                            
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Status                                        
                                    </label>
                                    <textarea type="text" class="form-control" name="pressureTestDesc" onChange={onPressureTestDescChange}
                                        value={pressureTestDesc} style={{"height" : "150px"}} />
                                </div>
                            </div>


                            {messages.length > 0 &&
                                <Alert messages={messages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={cancel}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={addEdit} disabled={!saveEnabled} >
                                    Save
                                </button>

                                { !isEdit &&
                                    <button type="button" class="btn btn-primary m-0 ms-2" onClick={addAndGoToResultPage}>
                                        Save & Add Results
                                    </button>
                                }                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >
    )
}

export default CertOfCalPsv_Add;