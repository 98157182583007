import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import Config from '../../Constants/index'
import Axios from 'axios';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import { getAuth, onAuthStateChanged, getIdToken, debugErrorMap } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import CertHistory from '../../Component/History/CertHistory';
import RtRequestBacklogNav from '../../Component/RT/RtRequestBacklogNav';
import RtCert_Add from './RtCert_Add';

const RtRequestBacklog = (props) => {


    const PAGE_TITLE = "Request Backlog";

    const [data, setData] = React.useState([]);
    const [messages, setMessages] = React.useState([]);
    const [skip, setSkip] = React.useState(0);
    const [take, setTake] = React.useState(100);
    const [total, setTotal] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(1000);
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const [alertDialog, setAlertDialog] = React.useState(false);
    const [uploadDialog, setUploadDialog] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(false);
    const [uploadDialogCloseButtonVisible, setUploadDialogCloseButtonVisible] = React.useState(true);
    const [isRedirecToInsert, setIsRedirecToInsert] = React.useState(false);

    const [filterDialog, setFilterDialog] = React.useState(false);
    const [f_weldNo, setF_weldNo] = React.useState('');

    const onf_weldNoChange = (e) => { setF_weldNo(e.target.value) }



    const [lastSelectedItem, setLastSelectedItem] = React.useState([]);

    const [sort, setSort] = React.useState([
        { field: 'id', dir: 'desc' }
    ]);

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    React.useEffect(() => {
        document.title = PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }, []);

    React.useEffect(() => {
            fetchData();
        setLastSelectedItem([]);
    }, [skip, take, sort]);


    const getFilterStr = () => {
        var result = "";

        if (f_weldNo)
            result = result + "Weld No:" + f_weldNo;

       
        if (result)
            result = " Search: " + result;

        return result;
    }

    const showfilterDialog = () => {
        setFilterDialog(true);
    }

    const onFilter = () => {
        fetchData();
        setFilterDialog(false);
    }

    const removeFilter = () => {
        setF_weldNo('');
    }

    const pageChange = (event) => {
        setSkip(event.page.skip);
        setTake(event.page.take);
    }

    const onSortChange = (e) => setSort(e.sort);

    const fetchData = () => {
        debugger;
        var self = this;
        setLastSelectedItem([]);


        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + "RtRequestBacklog/GetRtRequestBacklogListAdmin/?Skip=" + skip
                + "&Take=" + take
                + "&SortField=" + (sort[0] ? sort[0].field : "")
                + "&SortDir=" + (sort[0] ? sort[0].dir : "")
                + "&F_WeldNo=" + f_weldNo
                + "&time=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                }
            ).then(response => {
                setData(response.data.rowModels);
                setTotal(response.data.total);
            }).catch(error => {
                console.log(error);
            })
        });
    }
    const updateItem = (changedItem) => {

        const newData = data.map((currentItem) => {

            if (currentItem.id == changedItem.id)
                currentItem = changedItem

            return currentItem;
        });

        setData(newData);
        setLastSelectedItem(changedItem);

    }

    const insert = () => {
        setIsRedirecToInsert(true);
    }

    const selectionChange = (event) => {
        const id = event.dataItem.id;
        setLastSelectedItem(event.dataItem);

        const newList = data.map((item) => {
            if (item.id === id) {
                const updatedItem = {
                    ...item,
                    selected: !item.selected,
                };
                return updatedItem;
            }
            //item.selected = false;
            return item;
        });

        setData(newList);
    }

    const rowClick = (event) => {

        const id = event.dataItem.id;
        setLastSelectedItem(event.dataItem);

        const newList = data.map((item) => {
            if (item.id === id) {
                const updatedItem = {
                    ...item,
                    selected: true //isCheckBoxCell ? !item.selected : true,
                };

                return updatedItem;
            }
            //if (!isCheckBoxCell)
            //item.selected = false;
            return item;
        });

        setData(newList);
    }

    const headerSelectionChange = (event) => {

        const checked = event.syntheticEvent.target.checked;

        const newList = data.map((item) => {
            item.selected = checked
            return item;
        });

        setData(newList);
    }

    const filteredData = data.filter(item => item.selected);

    return (
<main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

{/* Navbar */}
<NavBar />
<div className="container-fluid py-4">


    <div className="col-12">
        <div className="card mb-4">
            <div class="card-header p-3 pt-2">
                <div class="icon icon-lg icon-shape bg-gradient-info shadow text-center border-radius-xl mt-n4 float-start">
                    <i class="fa fas fa-certificate fa-fw" aria-hidden="true"></i>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <h6 class="mb-0">
                            {PAGE_TITLE} 
                        </h6>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-9">

                    {/* <div className="card-body px-0 pt-0 pb-2"> */}

                    <Grid data={data} skip={skip} take={take}
                                    total={total} sort={sort} pageSize={pageSize}
                                    onPageChange={pageChange}
                                    onSortChange={onSortChange}
                                    sortable
                                    pageable={{
                                        pageSizes: [10, 20, 50, 100,1000],
                                        //type: 'input'

                                    }}

                                    resizable
                                    selectedField="selected"
                                    onSelectionChange={selectionChange}
                                    onHeaderSelectionChange={headerSelectionChange}
                                    onRowClick={rowClick}
                                >

                                    <GridToolbar>
                                        <Button iconClass="fa fa-filter"
                                            style={{ 'margin-left': '5px' }}
                                            onClick={showfilterDialog}>Filter</Button>
                                        <Button iconClass="fa fa-times"
                                            style={{ 'margin-left': '5px' }}
                                            onClick={removeFilter}>Remove</Button>
                                        {getFilterStr()}
                                    </GridToolbar>

                                    <GridToolbar>
                                        <Button onClick={insert} >New Certificate</Button>
                                    </GridToolbar>

                                    {/* <Column field="vCustomerName" title="Customer" width="150px" className="NoWrap"  sortable/> */}
                                    <Column
                                        field="selected"
                                        width="50px"
                                        headerSelectionValue={
                                            data.length > 0
                                            &&
                                            data.findIndex(dataItem => dataItem.selected === false) === -1
                                        } />


                                    <Column field="id" title="Id" width="60px" className="NoWrap" />
                                    <Column field="weldNo" title="WeldNo" width="200px" className="NoWrap" />
                                    <Column field="reqDocNoOfSheet" title="Req No" width="300px" className="NoWrap" />

                                </Grid>

                    <div>


                        {
                            //Make Delete As Component
                            alertDialog > 0 &&
                            <Dialog
                                title="Alert"
                                onClose={this.hideDialogs} >

                                {messages.length > 0 &&
                                    <ul>
                                        {messages.map((message) => {
                                            return (
                                                <li>{message}</li>
                                            )
                                        })}
                                    </ul>
                                }

                                <DialogActionsBar>
                                    <button
                                        className="k-button k-primary"
                                        onClick={this.hideDialogs}
                                    >
                                        Close
                                    </button>
                                </DialogActionsBar>
                            </Dialog>
                        }

                        {filterDialog &&
                            <Dialog
                                title="Filter"
                                onClose={() => setFilterDialog(false)}
                                width={250}
                            // height={400}
                            >
                                <div>


                                    <div class="row">
                                        <div className="col-12">
                                            <div className="input-group input-group-static  my-3">
                                                <label>Weld No</label>
                                                <input type="text" class="form-control" name="f_weldNo" onChange={onf_weldNoChange}
                                                    value={f_weldNo} />
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div class="row">
                                        <div className="col-12">
                                            <div className="input-group input-group-static  my-3">
                                                <label>
                                                    <input type="checkbox" name="reCert" onChange={onf_Status_AllChange}
                                                        value={f_Status_All} checked={f_Status_All} /> All Status
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div className="col-12">
                                            <div className="input-group input-group-static  my-3">
                                                <label>
                                                    <input type="checkbox" name="f_Status_Draft" onChange={onf_Status_DraftChange}
                                                        value={f_Status_Draft} checked={f_Status_Draft} disabled={f_Status_All} /> Draft &nbsp;
                                                </label>
                                                <label>
                                                    <input type="checkbox" name="f_Status_Submited" onChange={onf_Status_SubmitedChange}
                                                        value={f_Status_Submited} checked={f_Status_Submited} disabled={f_Status_All} /> Submited &nbsp;
                                                </label>
                                                <label>
                                                    <input type="checkbox" name="f_Status_Approved" onChange={onf_Status_ApprovedChange}
                                                        value={f_Status_Approved} checked={f_Status_Approved} disabled={f_Status_All} /> Approved &nbsp;
                                                </label>
                                                <label>
                                                    <input type="checkbox" name="f_Status_Rejected" onChange={onf_Status_RejectedChange}
                                                        value={f_Status_Rejected} checked={f_Status_Rejected} disabled={f_Status_All} /> Rejected &nbsp;
                                                </label>
                                                <label>
                                                    <input type="checkbox" name="f_Status_Archive" onChange={onf_Status_ArchiveChange}
                                                        value={f_Status_Archive} checked={f_Status_Archive} disabled={f_Status_All} /> Archive &nbsp;
                                                </label>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                                <DialogActionsBar>
                                    <button
                                        className="k-button k-primary"
                                        onClick={onFilter}
                                    >
                                        OK
                                    </button>
                                    <button
                                        className="k-button"
                                        onClick={() => { setFilterDialog(false) }}
                                    >
                                        Cancel
                                    </button>
                                </DialogActionsBar>
                            </Dialog>}



                    </div>






                </div>
                <RtRequestBacklogNav filteredData={filteredData} item={lastSelectedItem} visible={lastSelectedItem.id > 0} onDelete={() => { fetchData() }}
                    onItemUpdate={updateItem} user={props.user} 
                    onCreateReportsFromBacklog={()=> {fetchData()}} />
            </div>



        </div>
    </div>




</div>

<Footer />
</main>
    )

}

export default RtRequestBacklog;
