import React, { Component } from 'react'
import Axios from 'axios';
import Config from '../Constants/index'
//import CKEditor from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Redirect, Link } from 'react-router-dom'

export default class TournamentAdd extends Component {


    constructor(props) {
        super(props);
        this.add = this.add.bind(this);

        let id = 0;
        let isEdit = false;
        if (this.props.match.params.Id) {
            id = this.props.match.params.Id;
            isEdit = true;
        }

        this.state = {
            Id: id, isEdit, Title: '', Short: '', isRedirectTournament: false,
            isRedirectNextStep: false,
            Gender: true
        };
        this.onChange = this.onChange.bind(this);
        this.cancel = this.cancel.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.addEdit = this.addEdit.bind(this);


        // var objCal1 = new window.AMIB.persianCalendar('BirthDay_fa',
        // { extraInputID: "BirthDay_en", extraInputFormat: "YYYY/MM/DD" });

    }

    addEdit() {
        if (this.state.isEdit)
            this.edit();
        else
            this.add();
    }

    add() {

        Axios.post(Config.API_URL + "tournament/add",
            {
                TeamA: this.state.TeamA,
                TeamB: this.state.TeamB,
                TeamDate: this.state.TeamDate,
                DayOfWeak: this.state.DayOfWeak,
                MatchTime: this.state.MatchTime,
                Location: this.state.Location,
                AgeTypeTitle: this.state.AgeTypeTitle,
                LevelGroup: this.state.LevelGroup,
                Gender: this.state.Gender
            },
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                let id = response.data;
                this.setState({ Id: id, isRedirectTournament: true });
            })
            .catch(response => {
                console.log(response);
            });

    }

    edit() {
        Axios.post(Config.API_URL + "tournament/edit", {
            id: this.state.Id,
            TeamA: this.state.TeamA,
            TeamB: this.state.TeamB,
            TeamDate: this.state.TeamDate,
            DayOfWeak: this.state.DayOfWeak,
            MatchTime: this.state.MatchTime,
            Location: this.state.Location,
            AgeTypeTitle: this.state.AgeTypeTitle,
            LevelGroup: this.state.LevelGroup,
            Gender: this.state.Gender

        },
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                this.setState({ isRedirectTournament: true });
            })
            .catch(response => {
                console.log(response);
            });
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    cancel = () => {
        this.setState({ isRedirectTournament: true })
    }

    fetchData() {
        Axios.get(Config.API_URL + "tournament/Gettournament/?id=" + this.state.Id + "&timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                this.setState({ TeamA:response.data.teamA });
                this.setState({ TeamB:response.data.teamB });
                this.setState({ TeamDate:response.data.teamDate });
                this.setState({ DayOfWeak:response.data.dayOfWeak });
                this.setState({ MatchTime:response.data.matchTime });
                this.setState({ Location:response.data.location });
                this.setState({ AgeTypeTitle:response.data.ageTypeTitle });
                this.setState({ LevelGroup:response.data.levelGroup });
                this.setState({ Gender:response.data.gender });            
            });
    }

    componentDidMount() {
        if (this.state.isEdit)
            this.fetchData();
    }

    render() {
        if (this.state.isRedirectTournament)
            return <Redirect to="/tournament/" />

        return (
            <div>
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">صفحه اصلی</Link></li>
                                    <li className="breadcrumb-item"><Link to="/Tournament">برنامه / نتایج مسابقات</Link></li>
                                    {this.state.isEdit ?
                                        <li className="breadcrumb-item active" aria-current="page">ویرایش</li>
                                        :
                                        <li className="breadcrumb-item active" aria-current="page">درج جدید</li>
                                    }
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-8">
                                <form role="form">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">
                                            عنوان تیم الف
					                    </label>
                                        <input type="text" class="form-control" name="TeamA" onChange={this.onChange} value={this.state.TeamA} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">
                                            عنوان تیم ب
					                    </label>
                                        <input type="text" class="form-control" name="TeamB" onChange={this.onChange} value={this.state.TeamB} />
                                    </div>

                                    <div class="form-group">
                                        <label for="exampleInputEmail1">
                                            تاریخ مسابقه
					                    </label>
                                        <input type="text" class="form-control" name="TeamDate" onChange={this.onChange} value={this.state.TeamDate} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">
                                            روز مسابقه
					                    </label>
                                        <input type="text" class="form-control" name="DayOfWeak" onChange={this.onChange} value={this.state.DayOfWeak} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">
                                            ساعت مسابقه
					                    </label>
                                        <input type="text" class="form-control" name="MatchTime" onChange={this.onChange} value={this.state.MatchTime} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">
                                            محل برگزاری
					                    </label>
                                        <input type="text" class="form-control" name="Location" onChange={this.onChange} value={this.state.Location} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">
                                            رده سنی
					                    </label>
                                        <input type="text" class="form-control" name="AgeTypeTitle" onChange={this.onChange} value={this.state.AgeTypeTitle} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">
                                            مرحله / گروه
					                    </label>
                                        <input type="text" class="form-control" name="LevelGroup" onChange={this.onChange} value={this.state.LevelGroup} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">
                                            جنسیت
					                    </label>
                                        <select class="form-control" value={this.state.Gender} name="Gender" onChange={this.onChange}>
                                            <option value={true}>آقایان</option>
                                            <option value={false}>بانوان</option>
                                        </select>
                                    </div>

                                    <div class="from-group" style={{ 'padding-top': '10px' }}>
                                        <button type="button" class="btn btn-primary" onClick={this.addEdit}>
                                            تایید و ادامه
				          </button>
                                        <button type="button" class="btn btn-secondary" style={{ 'margin-right': '5px' }} onClick={this.cancel}>
                                            انصراف
				          </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        )
    }
}


