import * as React from "react";
import * as ReactDOM from "react-dom";
import Config from '../../../Constants/index';
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";
import { Link, Redirect } from 'react-router-dom'
import EquipmentDeltail from "../Components/EquipmentDetail";


import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import NavBar from '../../../Navigation/NavBar';
import Footer from '../../../Navigation/Footer';
import PsvResultTable from "./PsvResultTable";
import PsvResultForm from "./PsvResultForm";

const editField = "inEdit";

const CalibrationResultPsv = (props) => {

    const [certificateOfCalibrationRef, setCertificateOfCalibrationRef] = React.useState(props.match.params.entityRef);
    const [equipment, setEquipment] = React.useState('');
    const [unitOfMeasurment, setUnitOfMeasurment] = React.useState('');

    const auth = getAuth();
    const [user, loadingAuth, error] = useAuthState(auth);

    React.useEffect(() => {
        fetchCalibrationReport();
    }, []);

    const fetchCalibrationReport = () => {
        getIdToken(user).then((idToken) => {
            fetch(Config.API_URL + "CertificateOfCalibration/GetCertificateOfCalibration/?id=" + certificateOfCalibrationRef
                + "&timestamp=" + new Date().getTime(),
                { headers: { token: window.localStorage.getItem("apiToken") } })
                .then(response => response.json())
                .then(json => {
                    //setCalibrationReport(json)
                    fetchEquipment(json.equipmentRef)
                });
        });

    }

    const fetchEquipment = (equipmentId) => {
        getIdToken(user).then((idToken) => {
            fetch(Config.API_URL + "equipment/GetEquipment/?id=" + equipmentId
                + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    setEquipment(json)
                    fetchUnitOfMeasurment(json.unitMeasurmentRef)
                });
        });

    }

    const fetchUnitOfMeasurment = (unitOfMeasurmentId) => {
        getIdToken(user).then((idToken) => {
            fetch(Config.API_URL + "UnitOfMeasurment/GetUnitOfMeasurment/?id=" + unitOfMeasurmentId
                + "&timestamp=" + new Date().getTime(),
                { headers: { token: window.localStorage.getItem("apiToken") } })
                .then(response => response.json())
                .then(json => {
                    setUnitOfMeasurment(json)
                });
        });
    }

    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <EquipmentDeltail equipment={equipment} unitOfMeasurment={unitOfMeasurment} />

                <PsvResultForm
                    equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                    title="PRESSURE TEST"
                    certOfCalId={certificateOfCalibrationRef}
                ></PsvResultForm>

            </div>

            <Footer />
        </main >
    );
};

export default CalibrationResultPsv;





