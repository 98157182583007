import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../../Component/Alert'
import CalibrationTypeLkp from '../../Component/Calibration/CalibrationTypeLkp';
import UserDefaultCustomer from '../../Component/Calibration/UserDefaultCustomer';
import UnitOfMeasurmentLkp from '../../Component/Calibration/UnitOfMeasurmentLkp';
import t from '../../Lang/Index'
import ReportTemplateLkp from '../../Component/Calibration/ReportTemplateLkp';
import CalibrationProcedureLkp from '../../Component/Calibration/CalibrationProcedureLkp';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import CustomerLkp from '../../Component/CustomerLkp';
import EquipmentLkp from '../../Component/EquipmentLkp';
import UserLkp from '../../Component/UserLkp';
import moment from 'moment';
import DueDateOfCalibration from './DueDateOfCalibration';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'

const CertOfMut_Add = (props) => {

    const PAGE_TITLE = "New Calibration Report";
    const [id, setId] = React.useState(props.match.params.id);
    const isEdit = props.match.params.id > 0;       

    const [title, setTitle] = React.useState('');
    const [short, setShort] = React.useState('');
    const [isRedirectCertOfMut, setIsRedirectCertOfMut] = React.useState(false);
    const [messages, setMessages] = React.useState([]);
    const [customerRef, setCustomerRef] = React.useState(-1);
    const [calibrationUserRef, setCalibrationUserRef] = React.useState(-1);
    const [reviewUserRef, setReviewUserRef] = React.useState(-1);
    const [approvedUserRef, setApprovedUserRef] = React.useState(-1);
    const [description, setDescription] = React.useState('');
    const [dateOfCalibration, setDateOfCalibration] = React.useState('');
    const [dueDateOfCalibration, setDueDateOfCalibration] = React.useState('');
    const [dateOfIssue, setDateOfIssue] = React.useState('');
    const [relativeHumidity, setRelativeHumidity] = React.useState('');
    const [temperature, setTemperature] = React.useState('');
    const [remark, setRemark] = React.useState('');
    const [asFound, setAsFound] = React.useState(false);
    const [rejected, setRejected] = React.useState(false);
    const [zeroAdjustment, setZeroAdjustment] = React.useState(false);
    const [spanAdjustment, setSpanAdjustment] = React.useState(false);
    const [caliRangeMin, setCaliRangeMin] = React.useState('');
    const [caliRangeMax, setCaliRangeMax] = React.useState('');

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const onCustomerBind = (id) => setCustomerRef(id);

    const onDateOfCalibrationChange = (e) => setDateOfCalibration(e.target.value);
    const onDateOfIssueChange = (e) => setDateOfIssue(e.target.value);
    const onCalibrationUserRefChange = (e) => setCalibrationUserRef(e.target.value);
    const onReviewUserRefChange = (e) => setReviewUserRef(e.target.value);
    const onApprovedUserRefChange = (e) => setApprovedUserRef(e.target.value);
    const onTemperatureChange = (e) => setTemperature(e.target.value);
    const onRelativeHumidityChange  = (e) => setRelativeHumidity(e.target.value);
    const onAsFoundChange = (e) => setAsFound(e.target.checked);
    const onRejectedChange = (e) => setRejected(e.target.checked);
    const onZeroAdjustmentChange = (e) => setZeroAdjustment(e.target.checked);
    const onSpanAdjustmentChange = (e) => setSpanAdjustment(e.target.checked);   
    const onRemarkChange = (e) => setRemark(e.target.value);
    const onCaliRangeMinChange = (e) => setCaliRangeMin(e.target.value);
    const onCaliRangeMaxChange = (e) => setCaliRangeMax(e.target.value);

    const onDueDateOfCalibrationChange = (dueDate) => {
        setDueDateOfCalibration(dueDate);
    }

    const cancel = () => setIsRedirectCertOfMut(true);


    //TODO Edit
    // fetchData = () => {
    //     Axios.get(Config.API_URL + "CertOfMut/GetCertOfMut/?id=" + this.state.Id + "&timestamp=" + new Date().getTime(),
    //         { headers: { token: window.localStorage.getItem("apiToken") } })
    //         .then(response => {
    //             //TODO
    //             //this.setState({ Title: response.data.title });              
    //         });
    // }

    //TODO Edit
    // componentDidMount() {
    //     if (this.state.isEdit)
    //         this.fetchData();
    // }

    const addEdit = () => {
        if (isEdit)
            edit();
        else
            add();
    }

    const add = () => {
        let messagesList = [];
        let self = this;
        debugger;

        getIdToken(user).then((idToken) => {
            debugger;

        Axios.post(Config.API_URL + "CertOfMut/add",
            {
                DateOfCalibration: dateOfCalibration == '' ? '1900/1/1' : dateOfCalibration,
                DueDateOfCalibration: dueDateOfCalibration,
                CustomerRef: customerRef,
                EquipmentRef: props.match.params.equipmentRef,
                CalibrationUserRef: calibrationUserRef,
                ApprovedUserRef: approvedUserRef,
                ReviewUserRef: reviewUserRef,
                DateOfIssue: dateOfIssue,
                RelativeHumidity: relativeHumidity,
                Temperature: temperature,
                Remark: remark,
                AsFound: asFound,
                Rejected: rejected,
                ZeroAdjustment: zeroAdjustment,
                SpanAdjustment: spanAdjustment,
                CaliRangeMin: caliRangeMin,
                CaliRangeMax: caliRangeMax,
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + idToken
                }
            })
            .then(response => {
                let id = response.data;
                setId(id);
                setIsRedirectCertOfMut(true);                
            })
            .catch(error => {
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messagesList.push(p);
                    })
                }
                else {                    
                    messagesList.push("Failed to send data to server")
                }
                setMessages(messagesList);
                //Is insert TODO

            });
        });

    }

    const edit = () => {
        //TODO
        // let messages = [];
        // let self = this;
        // debugger;

        // Axios.post(Config.API_URL + "Town_Unit/edit",
        //     {
        //         Id : this.state.Id,
        //         Title: this.state.Title
        //     },
        //     { headers: { token: window.localStorage.getItem("apiToken") } })
        //     .then(response => {
        //         let id = response.data;
        //         this.setState({ Id: id, isRedirectUnit: true });
        //     })
        //     .catch(error => {
        //         if (error.response && error.response.status == 400) {
        //             error.response.data.message.map(p => {
        //                 messages.push(p);
        //             })
        //         }
        //         else {
        //             //messages.push("خطا در برقراری ارتباط با سرور - در صورت استفاده از پروکسی آنرا غیر فعال نمایید")
        //             messages.push("Failed to connect to server")
        //         }
        //         self.setState({ messages, isInsert: true });            
        //     });
    }



    if (isRedirectCertOfMut) {
        return <Redirect to={"/CertOfMut/"}></Redirect>
    }

    return (

        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-9 col-12 mx-auto position-relative">
                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fa fa-wrench fa-fw" aria-hidden="true"></i>
                            </div>
                            <h6 className="mb-0">{PAGE_TITLE}</h6>
                        </div>
                        <div className="card-body pt-2">

                            <div class="row">
                                <div className="form-group">
                                    <label>
                                        Customer Name
                                    </label>
                                    <UserDefaultCustomer onBind={onCustomerBind} name="CustomerRef" user={user} />
                                </div>
                            </div>

                            <div class="row">
                                <div className="col-3">
                                    <div className="input-group input-group-static  my-3">
                                        <label>
                                            Date Of Calibration
                                        </label>
                                        <input type="date" class="form-control" name="dateOfCalibration" onChange={onDateOfCalibrationChange}
                                            value={dateOfCalibration} />
                                    </div>
                                </div>
                            </div>
                            <DueDateOfCalibration dateOfCalibration={dateOfCalibration}
                                name="dueDateOfCalibration" onDueDateOfCalibrationChange={onDueDateOfCalibrationChange}
                                value={dueDateOfCalibration} />

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Calibration User
                                    </label>
                                    <UserLkp name="CalibrationUserRef" onChange={onCalibrationUserRefChange} value={calibrationUserRef} user={user} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Review User
                                    </label>
                                    <UserLkp name="reviewUserRef" onChange={onReviewUserRefChange} value={reviewUserRef} user={user} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Approved User
                                    </label>
                                    <UserLkp name="approvedUserRef" onChange={onApprovedUserRefChange} value={approvedUserRef} user={user} />
                                </div>
                            </div>

                            <div className="col-3">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Date Of Issue
                                    </label>
                                    <input type="date" class="form-control" name="dateOfIssue" onChange={onDateOfIssueChange} value={dateOfIssue} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Temperature
                                    </label>
                                    <input type="text" class="form-control" name="Temperature" onChange={onTemperatureChange} value={temperature} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Relative Humidity
                                    </label>
                                    <input type="text" class="form-control" name="relativeHumidity" onChange={onRelativeHumidityChange} value={relativeHumidity} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        <input type="checkbox" name="AsFound" onChange={onAsFoundChange} 
                                            value={asFound} /> As Found 
                                    </label>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                         <input type="checkbox" name="Rejected" onChange={onRejectedChange}
                                            value={rejected} /> Rejected 
                                    </label>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        {rejected ? "Reason Of Rejection" : "Remark"}
                                    </label>
                                    <textarea type="text" class="form-control" name="remark" onChange={onRemarkChange}
                                        value={remark} />
                                </div>
                            </div>


                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        <input type="checkbox" name="ZeroAdjustment" onChange={onZeroAdjustmentChange}
                                            value={zeroAdjustment} /> Zero Adjustment
                                    </label>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        <input type="checkbox" name="SpanAdjustment" onChange={onSpanAdjustmentChange}
                                            value={spanAdjustment} /> Span Adjustment
                                    </label>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Cali. Range Min
                                    </label>
                                    <input type="number" class="form-control" name="CaliRangeMin" onChange={onCaliRangeMinChange} value={caliRangeMin} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Cali. Range Max
                                    </label>
                                    <input type="number" class="form-control" name="CaliRangeMax" onChange={onCaliRangeMaxChange} value={caliRangeMax} />
                                </div>
                            </div>


                            {messages.length > 0 &&
                                <Alert messages={messages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={cancel}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={addEdit}>
                                    Save
                                </button>
                                {/* TODO GOTO RESULT
                                { !isEdit &&
                                <button type="button" class="btn btn-primary m-0 ms-2" onClick={addAndGoToNewReport}>
                                    Save & Add new report
                                </button>
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >
    )
}

export default CertOfMut_Add;