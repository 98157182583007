import React from 'react'
import Select from 'react-select';

const PsvTestMediumLkp = (props) => {

  const options = [
    { value: 'N2', label: 'n: N2' },
    { value: 'Water', label: 'w: Water' },
    { value: 'Steam', label: 's: Steam' },    
  ]    
  const filterOptions = ({ label }, input) => label.startsWith(input);

  return (
    <Select options={options} 
      className="form-control" 
      onChange={props.onChange} 
      filterOption={filterOptions}
      value= {props.selectedValue}
      />
  )
}

export default PsvTestMediumLkp