import * as React from "react";
import * as ReactDOM from "react-dom";
import Config from '../../../Constants/index';
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";
import { Link, Redirect } from 'react-router-dom'
import EquipmentDeltail from "../Components/EquipmentDetail";
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import NavBar from '../../../Navigation/NavBar';
import Footer from '../../../Navigation/Footer';
import F0101T1 from "../01Pressure/F0101/F0101T1"

const editField = "inEdit";

const CalibrationResultF0101 = (props) => {

    const [certificateOfCalibrationRef, setCertificateOfCalibrationRef] = React.useState(props.match.params.entityRef);
    const [equipment, setEquipment] = React.useState('');
    const [unitOfMeasurment, setUnitOfMeasurment] = React.useState('');

    const auth = getAuth();
    const [user, loadingAuth, error] = useAuthState(auth);

    React.useEffect(() => {
        fetchCalibrationReport();
    }, []);

    const fetchCalibrationReport = () => {
        getIdToken(user).then((idToken) => {
            fetch(Config.API_URL + "CertificateOfCalibration/GetCertificateOfCalibration/?id=" + certificateOfCalibrationRef
                + "&timestamp=" + new Date().getTime(),
                { headers: { token: window.localStorage.getItem("apiToken") } })
                .then(response => response.json())
                .then(json => {
                    //setCalibrationReport(json)
                    fetchEquipment(json.equipmentRef)
                });
        });

    }

    const fetchEquipment = (equipmentId) => {
        getIdToken(user).then((idToken) => {
            fetch(Config.API_URL + "equipment/GetEquipment/?id=" + equipmentId
                + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    setEquipment(json)
                    fetchUnitOfMeasurment(json.unitMeasurmentRef)
                });
        });

    }

    const fetchUnitOfMeasurment = (unitOfMeasurmentId) => {
        getIdToken(user).then((idToken) => {
            fetch(Config.API_URL + "UnitOfMeasurment/GetUnitOfMeasurment/?id=" + unitOfMeasurmentId
                + "&timestamp=" + new Date().getTime(),
                { headers: { token: window.localStorage.getItem("apiToken") } })
                .then(response => response.json())
                .then(json => {
                    setUnitOfMeasurment(json)
                });
        });
    }



    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-12">
                    <div className="card mb-4">
                        <div class="card-header p-3 pt-2">
                            <div class="icon icon-lg icon-shape bg-gradient-info shadow text-center border-radius-xl mt-n4 float-start">
                                <i class="fas fa-flask" aria-hidden="true"></i>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 class="mb-0">Result of Certificate no: {certificateOfCalibrationRef}</h6>
                                </div>

                            </div>
                        </div>


                        <div className="card-body px-0 pt-0 pb-2">

                            <div>

                                <div className="row">
                                    <EquipmentDeltail equipment={equipment} unitOfMeasurment={unitOfMeasurment} /> 
                                </div>

                                <div>
                                    <F0101T1 equipment={equipment} unitOfMeasurment={unitOfMeasurment}
                                        title="Pressure Measurment"
                                        tblTemplateId={101}
                                        certOfCalId={certificateOfCalibrationRef} />
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >
    );
};

export default CalibrationResultF0101;





